@import "../../../styles/global";

.EventRepetition {
  &__group {
    margin-top: gap-size(1.7);
    display: flex;
    flex-wrap: wrap;

    .label {
      white-space: pre;
    }

    .input-group {
      margin-right: gap-size(0.25);
      max-width: fit-content;
      margin-bottom: 0;
    }

    .input-group:first-of-type {
      margin-left: gap-size(0.25);
    }
  }

  .RadioButton__label {
    background-color: $color-background;
  }
}
