@import "../../../styles/global";

.GeneralPricingSection {
  &__row {
    display: grid;
    gap: 0 gap-size();

    @include breakpoint-sm-up() {
      grid-template-columns: 1fr 1fr;
    }
  }
}
