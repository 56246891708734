@import "../../../styles/global";
@import "../../../styles/form";

.CatalogUpdates {
  &__selectProduct {
    @include select-styling;
    max-width: unset;
  }

  &__attributeTable {
    display: grid;
    grid-template-columns: max-content 20rem;
  }

  &__attributeControl {
    position: relative;
    padding-left: 0.125rem;
  }

  &__attributeLabel {
    background-color: $color-primary-darker;
    color: $color-text-light;
    font-weight: $font-weight-semi-bold;
    margin-bottom: gap-size(0.25);
    padding: gap-size(0.25);
  }

  &__attributeOtherGames {
    color: $color-primary-dark;
    margin-left: gap-size();
    align-self: center;
  }
}
