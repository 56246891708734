@import "../../../styles/global";

.TableComponent {
  width: 100%;
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border-spacing: 0;

  /* unset the "default" table styles from portal-style.css */
  background: none;
  box-shadow: none;
  tr {
    line-height: unset;
    background-color: $color-background;
    color: $color-text-dark;
    font-size: 15px;
    border-bottom: unset;
    border-left: unset;
  }
  thead {
    height: unset;
    line-height: unset;
    tr {
      background: $color-primary-darker;
      line-height: unset;
      font-size: unset;
      font-weight: 600;
      th {
        text-align: left;
      }
    }
  }
  // -----

  thead {
    display: none;
    @media screen and (min-width: $screen-ml-min) {
      display: table-header-group;
    }
  }

  tbody > tr {
    border: $border-width-fine solid $color-grey-mid;
  }

  tr {
    display: block;
    padding: gap-size();
    margin-bottom: gap-size();
    background-color: $color-background;
    &:not:last-child {
      margin-bottom: 0;
    }
    @media screen and (min-width: $screen-ml-min) {
      display: table-row;
      border-bottom-width: $border-width-fine;
      margin-bottom: 0;
    }
  }

  th,
  td {
    padding: gap-size() gap-size(0.5);
  }

  td {
    display: block;
    text-align: right;
    word-break: break-word;
    border-bottom: 1px dotted #ddd;
    &:last-child {
      border-bottom: none;
    }
    @media screen and (min-width: $screen-ml-min) {
      display: table-cell;
      text-align: unset;
      border-bottom: none;
    }
  }

  td:before {
    color: $color-primary-darker;
    content: attr(data-label);
    float: left;
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    @media screen and (min-width: $screen-ml-min) {
      content: "";
      display: none;
    }
  }
}
