@import "../../../styles/global";

.PanelComponent {
  background: $color-background;
  box-shadow: $shadow-light;
  padding: gap-size();
  margin-bottom: gap-size();

  &--emphasised {
    box-shadow: $shadow-standard;
  }
}
