@import "../../../../styles/global";

.ebay-settings {
  &__navigation-item {
    margin-bottom: gap-size();
  }

  &__navigation-title {
    color: $color-primary-darker;
    font-size: $font-size-mid !important;
  }

  &__navigation-description {
    margin-bottom: 0;
    font-size: $font-size-standard;
  }
}
