@import "../../../styles/global";

.ebay-shipping-service-settings {
  &__inputs {
    display: grid;
    grid-template-columns: max-content auto;
    grid-row-gap: gap-size(0.5);
    grid-column-gap: gap-size(0.5);
  }

  &__country-select {
    label {
      display: block;
    }
    input {
      margin-right: gap-size();
    }
  }

  &__field-error {
    color: $color-error;
    grid-column: 2;
    margin-top: -0.5rem;
    font-size: 0.9rem;
  }

  &__required {
    color: $color-primary-dark;
  }
}
