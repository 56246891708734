@import "../../../styles/global";

.Drawer {
  $root: &;
  position: fixed;
  transition: $transition-all;

  &--right,
  &--left {
    top: 0;
    bottom: 0;

    &#{$root}--entered {
      transform: translateX(0);
    }
  }

  &--right {
    right: 0;

    &#{$root}--entering,
    &#{$root}--exiting {
      transform: translateX(100%);
    }
  }

  &--left {
    left: 0;

    &#{$root}--entering,
    &#{$root}--exiting {
      transform: translateX(-100%);
    }
  }

  &--top,
  &--bottom {
    left: 0;
    right: 0;

    &#{$root}--entered {
      transform: translateY(0);
    }
  }

  &--top {
    top: 0;

    &#{$root}--entering,
    &#{$root}--exiting {
      transform: translateY(-100%);
    }
  }

  &--bottom {
    bottom: 0;

    &#{$root}--entering,
    &#{$root}--exiting {
      transform: translateY(100%);
    }
  }

  &__content {
    background-color: $color-background;
    height: 100%;
  }
}
