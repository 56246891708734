@import "../../../styles/global";
@import "../../../styles/form";

.TaxSettings {
  @include default-form;

  &__form {
    display: grid;
    grid-template-columns: 20rem 20rem;
    column-gap: gap-size(2);
    row-gap: gap-size(0.5);
  }
}
