@import "../../../styles/global";
@import "../../../styles/form";

.OpenTillModal {
  @include default-form;

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__leave-button {
    border: unset;
    background-color: unset;
    color: $color-text-dark;
    font-size: $font-size-mid;
  }

  &__till-select {
    @include select-styling;
    width: 100%;
    max-width: unset;
    font-size: $font-size-standard;
    margin-bottom: gap-size(2);
  }
}
