@import "../../../styles/global";

.SubSectionHeaderLayout {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  &__heading {
    display: flex;
    align-items: baseline;
  }

  &__title {
    font-size: $font-size-large !important;
    color: $color-text-dark;
    padding: gap-size(0.5) 0;
  }
}
