@import "../../../styles/global";

.CreditProcess {
  &__status {
    text-align: center;
    font-size: $font-size-huge;

    &--processing {
      color: $color-primary;
    }

    &--failed {
      color: $color-error;
    }
  }

  &__message {
    margin-top: gap-size();
    text-align: center;
  }
}
