@import "../../styles/global";

.Pos {
  &__ham-menu {
    color: $color-text-light;
    font-size: 37px;
    position: fixed;
    right: 10px;
    top: 16px;
    z-index: 1;

    &:hover,
    &:focus {
      color: $color-grey-light !important;
    }
  }
}
