@import "../../../styles/global";

.BuyLimitWarning {
  font-size: $font-size-tiny;
  margin-top: gap-size(0.5);
  padding: gap-size(0.25);
  border: $border-width-fine solid $color-orange;
  background-color: $color-orange-light;
  &__icon {
    color: $color-orange;
    padding-left: 0.1rem;
    padding-right: 0.3rem;
  }
}
