@import "../../../styles/global";

.ToggleVariantsButton {
  border: none;
  border: $border-width-fine solid transparent;
  margin-bottom: gap-size(0.25);

  &:focus {
    outline: none !important;
    border: $border-width-fine solid $color-grey;
  }

  > i {
    color: $color-primary-dark;
    margin-left: gap-size();
  }
}
