@import "../../../styles/global";

.CustomerLine {
  display: flex;
  align-items: center;
  height: 3rem;

  &:not(:last-child) {
    border-bottom: 1px solid $color-grey;
  }
  &:hover {
    margin-left: 2px;
  }
  &__searchInfo {
    display: inline-block;
    width: calc(100% - 82px);
    height: 38px;
    padding-left: gap-size(0.75);
    overflow: hidden;
    cursor: pointer;
    position: relative;
    .name,
    .email {
      letter-spacing: 0;
      color: $color-charcoal;
      font-size: $font-size-small;
      font-family: "Source Sans Pro", sans-serif;
    }
  }
  &__credit {
    color: $color-charcoal;
    font-family: "Source Sans Pro", sans-serif;
    font-size: $font-size-small;
    font-weight: 600;
    letter-spacing: 0;
    position: absolute;
    right: 21px;
  }
}
