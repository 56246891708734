@import "../../../styles/global";

.MyVariantsPricingSection {
  &__form {
    display: grid;
    gap: 0 gap-size();

    @include breakpoint-sm-up() {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__submit {
    @include breakpoint-sm-up() {
      grid-column: span 2;
    }
  }
}
