@import "../../../styles/variables";

.EventModal {
  &__subtitle {
    font-size: $font-size-mid !important;
    margin-bottom: gap-size(0.5);
    padding: 0;
  }

  &__image {
    max-width: 281px;
  }
}
