@import "./global";

@mixin table {
  table {
    color: $color-text-link-alt;
    a {
      color: $color-text-link-alt;
    }
    i {
      color: $color-primary-darker;
      &.active,
      &:hover {
        color: $color-primary-dark;
        transition: color 1s;
        text-decoration: none;
      }
    }
    .text-bold {
      color: $color-black;
    }
  }
}
