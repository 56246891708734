@import "../../../styles/form";
@import "../../../styles/global";

.NotesSettings {
  @include default-form;

  &__fields {
    list-style: none;
    max-width: fit-content;
    column-gap: gap-size();
    padding-inline-start: gap-size();
  }

  &__field {
    display: flex;
    justify-content: space-between;
  }

  .input {
    margin-bottom: gap-size();
  }

  &__newField {
    max-width: 20rem;
  }
}
