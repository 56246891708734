@import "../../../styles/global";

.DateSelect {
  display: grid;
  grid-auto-rows: auto;
  row-gap: gap-size(0.5);
  font-size: $font-size-standard;
  position: relative;
  max-width: 27rem;

  &__title {
    display: inline-block;
    font-size: $font-size-standard;
    font-weight: $font-weight-semi-bold;
  }

  &__input-group {
    display: flex;
    flex-direction: column;
  }

  &__label {
    font-size: $font-size-small;
    margin-bottom: gap-size(0.25);
    &--from {
      display: none;
    }
  }

  &__start-date,
  &__end-date {
    border: $border-width-fine solid $color-grey;
    border-radius: $border-radius-small;
    font-size: $font-size-standard;
    padding: gap-size(0.25);
  }

  .ButtonComponent {
    min-width: 3.5rem;
    padding: 0 gap-size(0.25);
  }

  @include breakpoint-xs-up() {
    grid-template-columns: 5rem 8rem 8rem auto;
    align-items: end;
    gap: gap-size(0.5);

    &__label {
      &--from {
        display: inline-block;
      }
    }
  }
}
