@import "../../../styles/global";

.ToggleSwitch {
  input[type="checkbox"] {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__switch {
    position: relative;
    height: 28px;
    input:checked + .ToggleSwitch__slider {
      background-color: $color-orange;
      &:before {
        left: calc(100% - 28px);
      }
      &:after {
        content: attr(data-truelabel);
        position: absolute;
        left: 23px;
        line-height: 29px;
        color: $color-secondary;
        text-transform: capitalize;
      }
    }

    input:disabled + .ToggleSwitch__slider {
      background-color: $color-grey-dark;
      &:after {
        color: $color-text-light;
      }
    }

    input:focus + .ToggleSwitch__slider {
      outline: solid 2px Highlight;
      outline: solid 2px -webkit-focus-ring-color;
      transition: none;
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;
    background-color: $color-primary-darker;
    border-radius: $border-radius-large;

    &:before {
      position: absolute;
      content: "";
      height: 24px;
      width: 24px;
      left: 2px;
      bottom: 2px;
      background-color: $color-background;
      border-radius: 50%;
      transition: 0.4s;
    }
    &:after {
      content: attr(data-falselabel);
      position: absolute;
      left: 39px;
      line-height: 29px;
      color: $color-text-light;
      text-transform: capitalize;
    }
  }
}
