@import "../../../styles/global";

.EbayStockChangeInfo {
  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: gap-size();
  }

  &__item {
    display: flex;
  }
  &__label {
    display: inline-block;
    min-width: 9.5rem;
    font-weight: bold;
    margin-right: gap-size();
    color: $color-primary-darker;

    &--no-min {
      min-width: unset;
    }
  }

  &__sections {
    display: flex;
    flex-wrap: wrap;
    margin-top: gap-size();
    justify-content: space-between;
  }

  &__image {
    margin-top: gap-size();
    flex-basis: 30rem;
    > img {
      width: 100%;
    }
  }

  &__error-list {
    max-height: 30vh;
    overflow-y: scroll;
    border: $border-width-fine solid $color-primary-darker;
    padding: gap-size(0.5);
    margin-bottom: gap-size();
  }

  &__error {
    margin-bottom: gap-size();
    color: $color-error;
  }
  &__error-params > * {
    text-align: left !important;
  }
}
