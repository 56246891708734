@import "../../../styles/global";

.DesktopMenu {
  width: 123px;
  height: calc(100vh - 176px);
  position: relative;
  padding-left: 2.5rem;
  text-align: center;
  top: 130px;

  @include breakpoint-xl-up {
    top: 145px;
  }

  &__link {
    text-align: center;
    color: $color-primary-dark;
    font-size: 30px;
    height: 35px;
    display: block;
    position: relative;
    margin-bottom: 1.25rem;
    transition: all 1s;
    width: 50px;

    @include breakpoint-lg-up {
      margin-bottom: 1.5rem;
    }

    &.active,
    &:hover {
      color: $color-secondary;
    }

    &--pos {
      position: fixed;
      bottom: 0;
      z-index: 9;
      left: 38px;
    }

    &--settings {
      display: none;
    }
  }
}
