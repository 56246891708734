@import "../../../../styles/global";

.NewModal {
  $root: &;

  &__container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    overflow-y: auto;
    background: $color-background;

    padding: gap-size();
    color: $color-text-dark;
    font-family: $font-family-secondary;
  }

  &__close-button {
    display: flex;
    align-items: center;
    column-gap: gap-size(0.5);
    background: none;
    border: none;
    color: $color-text-dark;
    font-size: $font-size-default;
    position: absolute;
    right: gap-size();
    top: gap-size();
    i {
      font-size: $font-size-huge;
    }
    @include hover-focus-active {
      color: $color-charcoal;
      transition: $transition-color;
    }
  }

  &__title {
    padding: gap-size() 0 gap-size(0.5) 0;
  }

  &__modal {
    position: relative;
    width: 95vw;
    max-height: 90vh;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    background-color: $color-background;

    &--small {
      max-width: 700px;
    }

    &--large {
      max-width: 1500px;
    }

    &--plain {
      border-radius: $border-radius-standard;

      #{$root}__header {
        border-bottom: $border-width-regular solid $color-grey-mid;
        padding: 0 gap-size(4) 0 gap-size(1);
      }

      #{$root}__title {
        font-size: $font-size-huge !important;
      }

      #{$root}__close-button {
        top: gap-size(1.5);
      }

      #{$root}__content {
        padding-top: gap-size(2);
        font-size: $font-size-default;
      }

      #{$root}__actions {
        height: 4.5rem;
        padding: gap-size();
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        column-gap: gap-size();
      }
    }

    &--styled {
      border-top: 8px solid $color-primary-darker;
      border-bottom: 8px solid $color-primary-darker;
      background: url(../../../../assets/img/modalWatermark.png),
        rgba(236, 245, 245, 0.9);
      background-repeat: no-repeat;
      background-position: bottom right;
      background-size: 42%;
      padding: gap-size() gap-size(2) gap-size(2) gap-size(2);

      &:before {
        content: "";
        background: $color-primary-darker;
        position: absolute;
        width: 164px;
        height: 29px;
        top: -36px;
        left: 0px;
      }
      &:after {
        content: "";
        position: absolute;
        border-top: 29px solid transparent;
        border-bottom: 0px solid transparent;
        border-left: 69px solid $color-primary-darker;
        top: -36px;
        left: 164px;
      }

      #{$root}__close-button-text {
        display: none;
        @include breakpoint-md-up {
          display: inline;
        }
      }

      #{$root}__content {
        padding: gap-size(2);
        box-shadow: $shadow-standard;
        min-height: 6rem;
      }

      #{$root}__actions {
        display: flex;
        flex-wrap: wrap;

        & > button,
        & > a {
          border-radius: 0;
          text-transform: uppercase;
          font-family: $font-family-secondary;
          font-size: $font-size-default;
          font-weight: $font-weight-normal;
          letter-spacing: 0.05rem;
          padding: gap-size();
          flex: 1;
        }
      }
    }
  }
}
