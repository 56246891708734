@import "../../../styles/global";
@import "../../../styles/form";

.ViewEvent {
  @include default-form;

  &__formSection {
    padding-top: gap-size(0.5);
    background-color: $color-grey-light;
    margin-bottom: gap-size();

    &--top {
      background-color: $color-primary-darker;
      color: $color-text-light;
      margin-bottom: 0;

      .label {
        color: $color-text-light;
        &--required {
          color: $color-text-light;
        }
      }
    }

    &--no-background {
      background-color: $color-background;
    }
  }

  &__formSectionHeader {
    letter-spacing: 0;
    color: $color-grey-dark;
    font-size: $font-size-standard;
  }

  &__helpText {
    font-size: $font-size-standard;
    color: $color-primary-dark;

    &--withPadding {
      padding-top: gap-size();
    }
  }

  &__image {
    display: block;
    max-width: 100%;
    max-height: 10rem;
  }
}
