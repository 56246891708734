@import "../../../styles/global";

.ThemeRollbacks {
  &__releasesList {
    list-style: none;
    padding-inline-start: gap-size();
    margin-right: gap-size();
  }

  &__release {
    display: flex;
    flex-wrap: wrap;
    border-bottom: $border-width-fine solid $color-grey;
    margin-top: gap-size();
  }

  &__releaseDetails {
    flex-grow: 1;
    flex-basis: 10rem;
  }

  &__releaseDate {
    color: $color-primary-darker;
  }

  &__releaseActions {
    margin-top: gap-size();
    height: gap-size(2);
  }
}
