@import "../../../styles/global";

.printed-receipt {
  min-width: unset !important;
  background-color: $color-background !important;
  font-size: 12px !important;
  color: $color-text-dark !important;
}

.receipt-options {
  font-family: $font-family-secondary;

  &__loading {
    min-height: 26rem;
  }

  &__header {
    display: flex;
    flex-direction: column;
  }

  &__title-wrap {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: gap-size(1);
  }

  &__title {
    font-size: $font-size-very-huge;
    font-weight: $font-weight-normal;
    margin: 0;
  }

  &__close {
    align-items: center;
    display: flex;
    justify-content: space-between;
    background: none;
    border: none;
    color: $color-grey-dark;
    font-size: 15px;

    span {
      line-height: 1.5;
    }

    i {
      font-size: $font-size-huge;
      margin-right: gap-size(0.3);
    }
  }

  &__select-language {
    margin: gap-size(0.5) 0 0 0;

    label {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    .label {
      font-size: $font-size-standard;
      margin-right: gap-size(1);
    }

    select {
      min-width: 50%;
      font-size: $font-size-default;
    }
  }
}

.receipt-modal {
  max-width: 460px;
  .NewModal__content {
    padding: gap-size(0.25);
  }
}
