@import "../../../styles/global";

.CookieBanner {
  position: absolute;
  z-index: 9999999999; // More than FreshChat icon!
  width: 100%;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  padding: gap-size();
  border-top: 3px solid $color-primary-dark;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;

  &__header {
    width: 100%;
  }

  &__dismiss {
    position: absolute;
    top: 0;
    right: 0;
  }

  &__actions {
    display: flex;
    column-gap: 1rem;
  }

  &__link {
    color: $color-primary-darker;
    &:hover {
      color: $color-primary-dark;
    }
  }
}
