@import "../../../styles/form";

.FilterVariantsSelect {
  @include select-styling-subtle;
  margin-bottom: 0;

  &--header {
    font-size: $font-size-default;
    border-color: $color-grey;
    height: 2.4rem;
    align-self: center;
  }
}
