@import "../../../styles/global";

.jobQueueList {
  margin-bottom: $gap-size;
  // Weird sizing difference when using table-header-group
  margin-left: -1px;
  box-shadow: $shadow-light;

  &__row {
    td {
      padding: 1.5rem 0.5rem;
    }
  }

  &__status {
    border-radius: $border-radius-large;
    font-size: $font-size-standard;
    max-width: 100px;
    padding: 0.1rem 0.2rem;
    text-align: center;
    text-transform: capitalize;

    @media screen and (max-width: $screen-ml-min) {
      margin-left: auto;
    }

    &--completed {
      background-color: $color-primary-darker;
      color: $color-white;
    }

    &--in-progress {
      background-color: $color-grey-mid;
    }

    &--queued {
      background-color: $color-orange-light;
    }

    &--error,
    &--incomplete {
      background-color: $color-red-light;
      color: $color-white;
    }
  }

  &__progress {
    color: $color-grey-dark;
    font-size: $font-size-standard;
    margin-left: auto;
    max-width: 40%;
    text-align: right;

    @media screen and (min-width: $screen-ml-min) {
      text-align: center;
      max-width: 120px;
    }
    .progress-bar {
      height: 1rem;
      border-radius: $border-radius-large;
      background-color: $color-grey-inter;
      margin-bottom: gap-size(0.2);
    }
    .progress-bar-fill {
      height: 1rem;
      background: transparent
        linear-gradient(180deg, $color-primary 0%, $color-primary-darker 100%)
        0% 0% no-repeat padding-box;
      border-radius: $border-radius-large;
    }
  }

  &__searchFilters {
    @media screen and (min-width: $screen-ml-min) {
      max-width: 300px;
    }
    em {
      font-style: normal;
      color: $color-grey-dark;
    }
  }

  &__updates {
    @media screen and (min-width: $screen-ml-min) {
      max-width: 300px;
    }
    em {
      font-style: normal;
      color: $color-grey-dark;
    }
  }
}
