@import "../../../styles/global";
@import "../../../styles/presentation";

.PaymentGatewayDeviceList {
  margin-top: gap-size();
  @include section-border;

  &__deviceList {
    padding-top: gap-size();
    display: flex;
    flex-wrap: wrap;
    column-gap: gap-size(0.5);
    row-gap: gap-size(0.5);
  }

  &__addButton {
    width: 12rem;
    min-height: 8rem;
  }
}
