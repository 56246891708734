@import "../../../styles/global";
@import "../../../styles/form";

.AdditionalInfo {
  @include default-form;

  &__fields {
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: gap-size();
  }
}
