@import "../../../styles/global";

.RadioButton {
  display: flex;
  margin-bottom: gap-size(0.5);
  padding: 0;
  text-align: center;
  border: $border-width-fine solid $color-grey-inter;
  color: $color-text-dark;
  border-radius: $border-radius-small;
  background-color: $color-grey-mid;

  &:hover {
    cursor: pointer;
    border-color: $color-primary-dark;
    background-color: $color-primary-dark;
  }

  &__input {
    display: none;
  }

  &__label {
    flex: 1;
    font-size: $font-size-standard;
    padding: gap-size(0.25) gap-size(0.5);
    border-top-right-radius: $border-radius-small;
    border-bottom-right-radius: $border-radius-small;
    user-select: none;

    &--compact {
      padding: 0;
    }
  }

  &__checkbox {
    color: $color-text-light;
    width: gap-size(2);
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 1.25rem;
    padding-top: 2px; // weird alignment fix
  }

  &--checked {
    border-color: $color-primary-dark;
    background-color: $color-primary-dark;
  }
}

.RadioButtonCompact {
  display: flex;
  gap: gap-size(0.5);
}
