@import "../../../styles/global";
@import "../../../styles/form";
@import "../../../styles/presentation";

.PaxSettings {
  max-width: 60rem;
  @include section-border;
  @include default-form;

  &__smallInputs {
    display: flex;
    flex-wrap: wrap;
    column-gap: gap-size(3);
    margin-bottom: gap-size();
  }
}
