@import "../../../styles/global";

.ItemTitle {
  position: relative;
  transition: top ease-in-out 0.5s;
  top: 0;

  &__text {
    transition: background ease-in-out 0.5s;
    background: rgba(255, 255, 255, 0);
    letter-spacing: 0;
    color: $color-grey-dark;
    font-size: 15px;
    text-align: center;
    height: 62px;
    display: block;
    margin: 7px 0;
    overflow: hidden;
    padding: 0 12px;

    &--expandable {
      &:hover {
        color: $color-grey-inter;
      }
    }
  }

  &__contractButton {
    display: block;
    text-align: center;
    transition: opacity ease-in-out 0.25s;
    opacity: 0;
    height: 0;
  }

  &__expandButton {
    background: none;
    border: none;
  }

  &--expanded {
    top: -150px;
    height: 69px;

    .ItemTitle__text {
      overflow: visible;
      background: rgba(255, 255, 255, 0.95);
      height: 160px;
    }

    .ItemTitle__contractButton {
      opacity: 1;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
