@import "./global";

@mixin button {
  width: max-content;
  line-height: 2;
  min-width: $button-min-width;
  padding: 0 gap-size();
  font-size: $font-size-small;
  font-weight: $font-weight-semi-bold;
  text-align: center;
  border: none;
  border-radius: $border-radius-small;
  color: $color-text-dark;
  background: $color-grey-light;
  cursor: pointer;

  @include hover-focus-active {
    color: $color-text-light;
    background: $color-grey-dark;
    transition: $transition-background;
    text-decoration: none;
  }

  &__primary {
    color: $color-text-light;
    background: $color-primary-darker;
    @include hover-focus-active {
      background: $color-primary-dark;
    }
  }

  &__secondary {
    color: $color-text-light;
    background: $color-blue-dark;
    @include hover-focus-active {
      background: $color-black;
    }
  }

  &--warning {
    background-color: $color-orange;
    color: $color-black;
    @include hover-focus-active {
      color: $color-text-light;
      background: $color-red-light;
    }
  }

  &__danger {
    color: $color-text-light;
    background: $color-danger;
    @include hover-focus-active {
      color: $color-text-light;
      background: $color-red;
    }
  }

  &:disabled,
  &[disabled] {
    cursor: not-allowed;
    background: $color-grey-dark;
  }

  // icon
  i {
    padding: 0 gap-size(0.5);
  }
}
