@import "../../../styles/global";

.BuylistTable {
  min-height: 12rem;

  &__loader {
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: gap-size(5);
    background-color: rgba(127, 127, 127, 0.1);
  }

  &__no-results {
    display: flex;
    justify-content: center;
    padding-top: gap-size();
  }
}
