@import "../../../styles/global";

.ReportSettings {
  &__select-products-container {
    display: flex;
    flex-direction: column;
  }
  &__selected-products-container {
    padding-left: gap-size();
  }
}
