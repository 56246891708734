@import "../../../styles/button";

.ButtonComponent {
  @include button;
  $root: &;

  display: inline-block;

  &--half-width {
    width: 50%;
  }

  &--full-width {
    width: 100%;
  }

  &--half-width,
  &--full-width {
    border-radius: 0;
    text-transform: uppercase;
    font-family: $font-family-secondary;
    font-size: $font-size-default;
    font-weight: $font-weight-normal;
    letter-spacing: 0.05rem;
    padding: gap-size();
  }

  &--compact {
    min-width: 6rem;

    &#{$root}--half-width {
      text-transform: none;
      letter-spacing: initial;
      padding: gap-size(0.25);
    }
  }
}
