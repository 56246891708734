@import "../../../styles/global";

.Tooltip {
  &__trigger {
    background: none;
    border: 0;
    margin-left: gap-size(0.5);
    display: inline-block;

    &:hover {
      cursor: pointer;
    }

    &--primary {
      color: $color-primary-darker;
    }

    &--dark {
      color: $color-text-dark;
    }

    &--light {
      color: $color-text-light;
    }
  }

  &__popup {
    font-size: $font-size-standard;
    z-index: 15;
    padding: gap-size(0.5);
    padding-right: gap-size();
    max-width: 20rem;
    border: $border-width-fine solid $color-black;
    box-shadow: $shadow-standard;
    background-color: $color-background;
    color: $color-text-dark;
    opacity: 0.95;
    transition: opacity 1s;
    cursor: initial;
    -webkit-touch-callout: text;
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
    text-align: left;

    &--big {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      overflow-y: auto;
      max-height: 70vh;
      min-width: 20rem;
      max-width: 90vh;
    }

    h3 {
      color: $color-primary-darker;
    }

    a {
      color: $color-primary-darker;
      text-decoration: underline;

      &:hover {
        color: $color-primary-dark;
      }
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    color: $color-primary-dark;
    padding: 1px 6px;
    cursor: pointer;
  }
}
