@import "../../../styles/global";

.tenderSelect {
  display: flex;
  flex-direction: column;

  &__tender {
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-mid;
    text-align: center;
    border: none;
    border-radius: $border-radius-small;
    background: $color-primary-darker;
    color: $color-text-light;
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: gap-size(1.2) gap-size();
    margin-bottom: gap-size();

    @include hover-focus-active {
      background: $color-primary-dark;
      transition: $transition-background;
    }
  }

  &__label {
    width: 10rem;

    & > i {
      padding-right: gap-size();
    }
  }
}
