@import "../../../styles/global";

.LoginPage {
  height: 100vh;
  display: grid;
  grid-template-columns: 58.3% 41.7%;
  grid-template-rows: calc(100% - 2rem) 2rem;
  align-items: center;
  justify-items: center;
  overflow-y: auto;

  background-size: 58.3% 100%;
  background-image: linear-gradient(
      to right,
      rgba(8, 15, 30, 0.9),
      rgba(8, 15, 30, 0.9)
    ),
    url(../../../assets/img/loginbg.jpg);
  background-blend-mode: hard-light;
  background-repeat: no-repeat;

  &__logo {
    max-width: 403px;
  }

  &__wrapper {
    width: 95%;
    max-width: 533px;
    font-family: "Source Sans Pro", sans-serif;

    .failed {
      color: $color-error;
      padding: 0 23px;
      text-align: center;
      i {
        padding-right: 10px;
      }
    }
    .reset {
      color: #25bb77;
      padding: 0 23px;
      text-align: center;
      i {
        padding-right: 10px;
      }
    }
    .recover {
      padding-bottom: 25px;
    }
    .title {
      margin-bottom: 0;
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 30px;
      line-height: 45px;
      text-align: center;
      color: $color-secondary;
      text-align: center;
      em {
        color: $color-primary-darker;
        font-style: normal;
      }
    }
    .wrongAccount {
      padding: 0px 23px;
      text-align: center;
      font-family: "Source Sans Pro", sans-serif;
      margin-bottom: 24px;
    }
    .errorDescription {
      margin-left: 32px;
      margin-right: 28px;
    }
    .subTitle {
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 0.01em;
      color: $color-secondary;
    }
    label {
      background: $color-white;
      border: 1px solid $color-grey-inter;
      box-sizing: border-box;
      border-radius: 5px;
      max-width: 475px;
      width: 100%;
      height: 70px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      line-height: 65px;
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: $color-secondary;
      padding-left: 37px;
      margin-bottom: 17px;
    }
    input {
      position: absolute;
      right: 0;
      outline: none;
      border: none;
      width: calc(100% - 137px);
      height: 67px;
      &::placeholder {
        font-family: "Source Sans Pro", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 31px;
        display: flex;
        align-items: center;
        color: $color-grey-inter;
      }
      &:placeholder-shown {
        height: 25px;
        margin-top: 17px;
      }
      &:-webkit-input-placeholder {
        color: transparent;
      }
      &:focus::-moz-placeholder {
        color: transparent;
      }
      &:-moz-placeholder {
        color: transparent;
      }
    }
    .link {
      text-align: center;
      padding-top: 9px;
      a {
        font-family: "Source Sans Pro", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 31px;
        color: $color-primary-darker !important;
        cursor: pointer;
      }
    }
    .signin {
      background: $color-primary-darker;
      border-radius: 5px;
      max-width: 475px;
      width: 100%;
      height: 70px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      font-family: "Source Sans Pro", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      display: block;
      align-items: center;
      text-align: center;
      color: $color-text-light;
      border: none;
    }
    .firebaseui-container {
      max-width: 475px;
      .firebaseui-card-content,
      .firebaseui-card-footer {
        padding: 0;
      }
      .firebaseui-id-idp-button {
        background: $color-secondary;
        border-radius: 5px;
        color: $color-text-light;
        max-width: 475px;
        height: 70px;
        width: 100%;
        .firebaseui-idp-text {
          font-family: "Source Sans Pro", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 18px;
          line-height: 27px;
          text-align: center;
          color: $color-text-light !important;
        }
        .firebaseui-idp-text-long {
          display: inline-block;
          position: relative;
          left: 40%;
          transform: translateX(-36%);
        }
        .firebaseui-idp-icon-wrapper {
          position: relative;
          left: 25%;
          display: inline-block;
          transform: translateX(-50%);
        }
      }
    }
  }

  &__link {
    font-size: 0.9rem;
    color: $color-text-light;
    font-family: "Source Sans Pro", sans-serif;
    background-color: unset;
    border: none;
    &:hover {
      color: $color-text-light;
    }
  }
}

@media screen and (max-width: 900px) {
  .LoginPage {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    row-gap: 5px;
    background-size: cover;

    .LoginPage__logo {
      max-width: 242px;
    }

    &__wrapper {
      .title {
        em {
          color: $color-primary-light;
        }
      }
    }

    .LoginPage__wrapper {
      margin-top: 35px;
      width: calc(100vw - 49px);
      background: rgba(255, 255, 255, 0.3);
      padding: 20px;
      border-radius: 10px;
      .link {
        margin-bottom: 0;
        a {
          color: $color-primary-light !important;
        }
      }
    }
  }
}

@media screen and (max-width: 415px) {
  .LoginPage .firebaseui-container .firebaseui-id-idp-button {
    .firebaseui-idp-icon-wrapper {
      left: 10%;
    }
    .firebaseui-idp-text-long {
      display: inline-block;
      position: relative;
      left: 35%;
      transform: translateX(-36%);
    }
  }
}
