@import "../../../styles/form";

.DeclineModal {
  @include default-form;

  &__notes-field {
    width: 100%;
    height: 6rem;
    border: $border-width-fine solid $color-primary-darker;
    resize: none;
    border-radius: $border-radius-standard;
    padding: gap-size(0.5);
  }
}
