@import "../../../../styles/global";

.ebay-orders-view {
  &__table {
    margin-bottom: gap-size();
  }

  &__container {
    border: $border-width-thick solid $color-primary-darker;
    border-top: gap-size() solid $color-primary-darker;
    padding: gap-size();
  }
}
