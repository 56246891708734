@import "../../../styles/global";

.QuickLinks {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;

  &__upNav {
    border-radius: $border-radius-small;
    color: $color-secondary;
    cursor: context-menu;
    font-size: $font-size-very-huge;
    height: 30px;
    left: 39px;
    margin-bottom: 14px;
    padding-left: 42px;
    position: fixed;
    top: 199px;
  }

  &__quickItem {
    align-items: center;
    background-color: $color-background;
    border: 0;
    border-bottom: gap-size(1.25) solid;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    height: 250px;
    margin: gap-size(0.5);
    text-align: center;
    user-select: none;
    width: 250px;
    &.disabled {
      -webkit-filter: blur(4px);
      -moz-filter: blur(4px);
      -ms-filter: blur(4px);
      -o-filter: blur(4px);
      filter: blur(4px);
      pointer-events: none;
    }
    &.blank {
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0);
    }
    &.addItem {
      display: none;

      @include breakpoint-custom-up(1366px) {
        background-color: rgba(0, 0, 0, 0);
        border-bottom-width: 2px;
        border-color: rgba(0, 0, 0, 0.061);
        border-style: dashed;
        box-shadow: 0px 3px 6px #00000029;
        color: rgba(0, 0, 0, 0.061);
        display: flex;
        .dot {
          color: rgba(0, 0, 0, 0.061);
          background-color: rgba(0, 0, 0, 0.061);
        }
        &:hover {
          .dot {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
        h2 {
          color: rgba(160, 62, 62, 0.161) !important;
        }
      }
    }
    img {
      background-color: $color-background;
      height: 45%;
      max-width: 45%;
      margin: 7% 0px 0px 0%;
      pointer-events: none;
    }
    .dot {
      border-radius: 50%;
      color: $color-text-light;
      display: block;
      height: 31%;
      margin: 18% auto 8.5% auto;
      text-align: center;
      width: 31%;
      i {
        font-size: 200%;
        position: relative;
        top: 50%;
        transform: translatey(-50%);
      }
    }
    .itemDesc {
      align-items: center;
      font-family: Poppins;
      font-style: normal;
      height: 30%;
      margin: 4.2% 0px 0px 0%;
      text-align: center;
      hr {
        border: 0;
        border-top: 2px solid rgba(0, 0, 0, 0.1);
        margin-bottom: 2px;
        margin-top: 0px;
        width: 77%;
      }
      h2 {
        color: $color-secondary !important;
        font-family: "Source Sans Pro", sans-serif;
        font-size: $font-size-mid !important;
        height: 50px;
        line-height: 21px;
        margin: 0;
        margin-bottom: gap-size(0.25);
        padding: 0;
      }
      .variantName {
        color: $color-grey-dark;
        font-size: 15px;
        font-weight: 500;
        margin: 0;
      }
      .setName {
        color: $color-grey-dark;
        font-size: 12px;
        font-weight: 500;
        margin: 0;
      }
    }
  }
}
