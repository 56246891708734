@import "../../../styles/global";

.jobQueueFilterTabs {
  color: $color-white;
  background: $color-blue-dark;
  font-weight: 600;
  line-height: 2.5rem;
  margin-top: $gap-size;

  button {
    background: none;
    border: 0;
    color: $color-text-light;
    margin: 0 $gap-size;
    display: inline-block;
    cursor: pointer;
    &.active {
      color: $color-primary;
    }
    &:hover {
      color: $color-primary-light;
    }
  }
}
