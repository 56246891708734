@import "../../../styles/global";

.miscSettings {
  &__setting {
    margin-bottom: gap-size();
  }

  &__beta {
    font-size: 0.5em;
    color: $color-primary-darker;
    vertical-align: super;
    padding-left: 0.2em;
  }
}
