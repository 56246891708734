@import "../../../styles/global";

.BuylistAddCardButton {
  padding: 0;
  margin-bottom: 0;
  min-height: 715px;

  &__button {
    color: $color-charcoal;
    width: 100%;
    height: 100%;
    min-height: 715px;
    background: 0 0;
    border: 0;
  }
}
