@import "../../../styles/global";

.NavToggleButton {
  background: none;
  border: 0;
  display: inline-block;
  color: $color-text-light;
  font-family: $font-family-secondary;
  padding: gap-size(0.125) gap-size();
  font-size: $font-size-standard;
  cursor: pointer;
  &.active,
  &:hover {
    color: rgba(10, 22, 46, 0.6);
    transition: all 1s;
    text-decoration: none;
  }
  i {
    font-size: $font-size-huge;
    position: relative;
    top: gap-size(0.25);
    right: gap-size(0.25);
  }

  // Not using the breakpoints from _variables.scss here as these
  // values need to match the pixel values used in other parts of
  // the PoS styling

  @media screen and (min-width: 1365px) and (max-width: 1528px) {
    padding: gap-size(0.125) gap-size(0.75);
  }

  @media screen and (max-width: 1365px) {
    width: 100%;
    text-align: right;
    line-height: gap-size(3);

    &:hover {
      color: $color-text-light;
    }
  }

  @media screen and (max-width: 650px) {
    padding-right: 37px !important;
  }
}
