@import "../../../styles/global";

.SideMenu {
  background: $color-secondary;
  width: 365px;
  height: 100vh;
  padding-top: 170px;
  text-align: right;
  &__title {
    text-align: right;
    color: $color-primary;
    margin: 0;
    padding: 0;
    padding-bottom: 11px;
    letter-spacing: 0;
    font-family: $font-family-secondary;
    font-size: 22px !important;
    padding-right: 26px;
  }
  &__item {
    height: 44px;
    line-height: 44px;
  }
  &__link {
    font-family: $font-family-secondary;
    font-size: 15px;
    color: $color-text-light;
    padding-right: 26px;
    text-decoration: none;
    transition: 0.3s all;
    display: block;
    &--active,
    &:hover {
      background: $color-primary-darker;
      transition: 0.3s all;
      color: $color-text-light;
      text-decoration: none;
    }
    &--active {
      &:after {
        content: "";
        position: absolute;
        transform: rotate(90deg);
        border-top: 0px solid transparent;
        border-bottom: 25px solid transparent;
        border-left: 25px solid rgba(0, 189, 188, 0.5);
      }
    }
  }

  @media screen and (max-width: 1365px) {
    display: none;
  }
}
