@import "../styles/global";

.rs-table-cell {
  display: flex;
  align-items: center;
}

.rs-table-cell-content {
  padding: 4px;
  height: auto !important;
}

.rs-table {
  font-size: 0.75em;
  .btn {
    font-size: 0.8em;
  }

  a.viewCart,
  a.viewBuylist {
    color: $color-text-link;
  }

  @media only print {
    margin-right: -4rem;
  }
}

@media only print {
  .rs-table-scrollbar {
    display: none;
  }
}

.rs-table--multi-header {
  .rs-table-row-header,
  .rs-table-row-header:hover {
    background: linear-gradient(
      0deg,
      $color-grey-light 60%,
      $color-primary-darker 60%
    );

    .rs-table-cell {
      background: linear-gradient(
        0deg,
        $color-grey-light 60%,
        $color-primary-darker 60%
      );
    }
  }

  .table-header__title {
    color: $color-white;
  }
}

.rs-table-cell-header-sort-wrapper {
  color: $color-text-light;
}

.table-header {
  .rs-table-cell {
    display: block;
  }
  .rs-table-cell-content {
    display: grid;
    row-gap: 8px;
    grid-template-areas: "title sort" "sub sub";
    grid-template-columns: auto 15px;
    grid-template-rows: 30px auto;
  }
  .rs-table-cell-header-sort-wrapper {
    grid-area: sort;
    padding: gap-size(0.25);
  }
  &__title {
    grid-area: title;
    padding: gap-size(0.167);
  }
  &__sub-header {
    align-items: center;
    cursor: default;
    display: flex;
    grid-area: sub;
    justify-content: flex-end;

    & *:not(input, select, button, i) {
      cursor: text;
    }

    input,
    select {
      border: $border-width-fine solid $color-grey-mid;
      border-radius: $border-radius-small;
      height: 24px;
      width: 100%;
    }
  }
}
