@import "../../../styles/global";
@import "../../../styles/form";

.Pagination {
  @include default-form;

  @media only print {
    display: none;
  }

  display: flex;
  justify-content: space-between;

  &__select {
    @include select-styling;
    min-width: unset;
    margin-bottom: 0;
    margin-right: gap-size(0.5);
  }

  &__pageInfo {
    display: flex;
    flex-direction: row;
    column-gap: gap-size();
  }

  label {
    display: flex;
    white-space: pre;
    align-items: center;
  }
}
