@import "../../../styles/global";
@import "../../../styles/form";

.EventDateFormatSetting {
  @include default-form;
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .input-group {
    width: unset;
    margin-left: gap-size();
    margin-bottom: 0;
  }

  .label {
    margin-bottom: 0;
  }
}
