@import "../../../styles/global";

.MultiSelect {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: gap-size();
  max-width: fit-content;

  &__option {
    min-width: 10rem;
    display: flex;
    margin-bottom: gap-size(0.5);
    padding: 0;
    text-align: center;
    border: $border-width-fine solid $color-grey-mid;
    color: $color-text-dark;
    font-size: $font-size-standard;
    border-radius: $border-radius-small;
    background-color: $color-grey-mid;

    &:hover {
      cursor: pointer;
      background-color: $color-primary-dark;
      border-color: $color-primary-dark;
    }

    &--checked {
      border-color: $color-primary-dark;
      background-color: $color-primary-dark;

      &:hover {
        border-color: $color-primary;
      }
    }
  }

  &__input {
    display: none;
  }

  &__label {
    flex: 1;
    background-color: $color-background;
    padding: gap-size(0.25) gap-size(0.5);
    border-top-right-radius: $border-radius-small;
    border-bottom-right-radius: $border-radius-small;
    user-select: none;
  }

  &__checkbox {
    color: $color-text-light;
    width: gap-size(2);
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 1.25rem;
    padding-top: 2px; // weird alignment fix
  }
}
