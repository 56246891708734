@import "../../../styles/global";
@import "../../../styles/form";

.PaymentTerminalSettings {
  @include default-form;

  & > label {
    display: flex;
    column-gap: gap-size();
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: gap-size();

    & > select {
      @include select-styling-base;
      display: inline-block;
      margin-bottom: 0;
    }
  }
  // margin-top: gap-size();
  // border: $border-width-fine solid $color-grey;
  // border-radius: $border-radius-standard;
  // padding: gap-size();
}
