@import "../../../styles/global";

.SalesBarChart {
  min-height: 25rem;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    color: $color-black;
    font-size: 18px !important;
    text-transform: uppercase;
  }

  &__period {
    color: $color-grey-dark;
  }

  &__total {
    font-size: $font-size-huge;
    color: $color-grey-dark;
  }
}
.SalesBarChart__selectInput {
  margin-bottom: gap-size();

  &:focus-within {
    outline: solid 2px Highlight;
    outline: solid 2px -webkit-focus-ring-color;
  }

  .SalesBarChart__selectInput {
    &__control {
      background-color: $color-primary-darker;
      border: 0;
      box-shadow: $shadow-light;
      color: $color-text-light;

      &:hover {
        background-color: $color-primary-dark;
      }
    }
    &__dropdown-indicator {
      color: $color-text-light;

      &:hover {
        color: $color-text-light;
      }
    }
    &__indicator-separator {
      background-color: $color-text-light;
    }
    &__menu {
      background-color: $color-white;
    }
    &__option {
      color: $color-text-dark;
      background-color: $color-white;
      &--is-focused {
        &:hover {
          background-color: $color-grey-light;
        }
      }
    }
    &__single-value,
    &__input {
      color: $color-text-light;
    }

    &__menu-notice--no-options {
      color: $color-grey-dark;
    }
  }
}
