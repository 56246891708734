@import "../../../styles/global";
@import "../../../styles/form";

.Checkout {
  @include default-form;
  position: relative;

  &__reset-button {
    border: 0;
    position: absolute;
    top: -5rem;
    right: 1rem;
    background: $color-primary-darker;
    color: $color-text-light;
    width: 6rem;
    height: 4rem;
    text-align: center;

    cursor: pointer;
    &.active,
    &:hover {
      background: $color-primary-dark;
      transition: all 1s;
    }
  }

  &__balance,
  &__tendered,
  &__quick-cash {
    font-size: $font-size-very-huge;
    display: flex;
    justify-content: space-between;
  }

  &__quick-cash {
    margin-top: gap-size(1);
  }

  &__balance {
    margin-top: gap-size(3);
  }

  &__total-tendered {
    width: 10rem;
    text-align: right;
  }

  &__tenders {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: gap-size();
    column-gap: gap-size();
    margin-top: gap-size();
  }

  &__tender {
    display: flex;
    flex-basis: 12rem;
    flex-grow: 1;
    font-size: $font-size-standard;
  }

  &__tender-input {
    width: 50%;
    text-align: center;
    border: 1px solid $color-grey-mid;
    border-right: none;
    position: relative;
  }

  &__payment-button {
    width: 50%;
    background: $color-primary-darker;
    border-top-right-radius: $border-radius-standard;
    border-bottom-right-radius: $border-radius-standard;
    border: none;
    color: $color-text-light;
    height: 3rem;
    @include hover-focus-active {
      background: $color-primary-dark;
      transition: all 1s;
      color: $color-text-light;
    }
  }

  &__change-due {
    margin-top: gap-size();
    display: flex;
    justify-content: flex-end;
  }

  &__quick-cash-button {
    font-size: $font-size-default;
    height: 2rem;
    margin-top: gap-size(0.5);
    width: 20%;
  }
}
