@import "../../../styles/global";

.AdditionalInfoForm {
  background: $color-grey-light;
  height: 20rem;
  padding: gap-size();
  margin-bottom: gap-size();

  &__delete {
    position: absolute;
    top: gap-size();
    right: gap-size(1.5);
    background: none;
    color: $color-red-light;
    border: none;

    &:hover {
      cursor: pointer;
      color: $color-red;
    }
  }

  &__add {
    background: $color-background;
    border: $border-width-fine solid $color-grey-light;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $color-grey-dark;

    &:hover {
      cursor: pointer;
    }
  }
  &__addIcon {
    color: $color-primary-darker;
    font-size: 38px;

    &:hover {
      color: $color-primary-dark;
    }
  }
}
