@import "../../../styles/global";

.receipt-component {
  &__wrapper {
    padding: gap-size(0.75) gap-size(1.5);
  }

  &__name {
    display: block;
    font-size: 1.3em;
    text-align: center;
  }

  &__store-details {
    display: block;
    font-size: 0.8em;
    text-align: center;

    &.tax-number {
      font-size: 0.8em;
      font-weight: $font-weight-bold;
      margin-bottom: gap-size(0.5);
    }
  }

  &__gift-label {
    border-top: $border-width-fine solid $color-grey-mid;
    border-bottom: $border-width-fine solid $color-grey-mid;
    display: block;
    font-size: 1.5em;
    font-weight: $font-weight-bold;
    margin: gap-size(0.75) auto;
    text-align: center;
  }

  &__transaction-details {
    display: flex;
    font-size: 0.85em;
    justify-content: space-between;

    &.smaller {
      font-size: 0.8em;
    }

    em {
      font-style: normal;
      text-align: right;
    }
  }

  &__item-count {
    display: block;
    font-size: 0.75em;
    text-align: center;
    margin: gap-size(0.35) 0 gap-size(0.3);
  }

  &__custom-message {
    border-top: $border-width-fine solid $color-grey-mid;
    font-size: 0.9em;
    margin-top: gap-size(0.5);
    padding-top: gap-size(0.5);
    text-align: center;

    .receipt-component--gift & {
      border-top: 0;
    }
  }

  hr.receipt-component__divider {
    margin: gap-size(0.75) 0 gap-size(0.1);

    &.inverse {
      margin-top: gap-size(0.1);
      margin-bottom: gap-size(0.75);
    }
  }

  table tr {
    font-family: unset;
    color: #000;
  }

  table.receipt-component__table {
    box-shadow: none;
  }

  tr.receipt-component__item-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-left: 0;
    border-bottom: $border-width-fine solid $color-grey-mid;
    height: auto !important;
  }

  td.item {
    &__qty {
      padding: 0 gap-size(0.5) !important; //required to override general rule
      font-size: 0.9em;
      font-weight: $font-weight-bold;
    }

    &__title {
      line-height: 1.1;
      padding: gap-size(0.1) gap-size(0.5);
      text-align: center;
      flex-grow: 1;
      font-size: 0.9em;
      font-weight: $font-weight-bold;
      letter-spacing: 0.5px;
    }

    &__price {
      flex-direction: column;
      align-items: flex-end;
      font-size: 0.9em;

      del,
      span,
      em {
        line-height: 1.5;
        display: block;
      }

      em {
        font-style: normal;
        font-weight: 600;
      }
    }
  }

  @media print and (max-width: 1200px) {
    padding: 4px;
    max-width: 150vw;
    color: #000 !important;
    font-size: 16px;
  }
}

.receipt-component-legacy {
  &__wrapper {
    padding: gap-size(0.75) gap-size(1.5);
  }

  &__name {
    display: block;
    font-size: 1.5em;
    text-align: center;
  }

  &__store-details {
    display: block;
    font-size: 0.9em;
    text-align: center;

    &.tax-number {
      font-size: 0.8em;
      font-weight: $font-weight-bold;
      margin-bottom: gap-size(0.5);
    }
  }

  &__gift-label {
    border-top: $border-width-fine solid $color-grey-mid;
    border-bottom: $border-width-fine solid $color-grey-mid;
    display: block;
    font-size: 1.5em;
    font-weight: $font-weight-bold;
    margin: gap-size(0.75) auto;
    text-align: center;
  }

  &__transaction-details {
    display: flex;
    font-size: 0.9em;
    justify-content: space-between;
    margin-top: gap-size(0.2);

    &.smaller {
      font-size: 0.8em;
    }

    em {
      font-style: normal;
      text-align: right;
    }
  }

  &__item-count {
    display: block;
    font-size: 0.75em;
    text-align: center;
    margin: gap-size(0.35) 0 gap-size(0.3);
  }

  &__custom-message {
    border-top: $border-width-fine solid $color-grey-mid;
    font-size: 0.9em;
    margin-top: gap-size(0.5);
    padding-top: gap-size(0.5);
    text-align: center;

    .receipt-component--gift & {
      border-top: 0;
    }
  }

  hr.receipt-component__divider {
    margin: gap-size(0.75) 0 gap-size(0.1);

    &.inverse {
      margin-top: gap-size(0.1);
      margin-bottom: gap-size(0.75);
    }
  }

  table.receipt-component__table {
    box-shadow: none;
  }

  tr.receipt-component__item-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-left: 0;
    border-bottom: $border-width-fine solid $color-grey-mid;
    height: auto !important;
  }

  td.item {
    &__qty {
      padding: 0 gap-size(0.5) !important; //required to override general rule
      font-size: 0.8em;
      font-weight: $font-weight-bold;
    }

    &__title {
      color: $color-text-dark;
      line-height: 1.1;
      padding: gap-size(0.1) gap-size(0.5);
      text-align: center;
      flex-grow: 1;
      font-size: 0.8em;
      font-weight: $font-weight-bold;
      letter-spacing: 0.5px;
    }

    &__price {
      // display: flex;
      flex-direction: column;
      align-items: flex-end;
      color: $color-text-dark;
      font-size: 0.8em;
      font-weight: $font-weight-bold;

      del,
      span,
      em {
        line-height: 1.5;
        display: block;
      }

      em {
        font-style: normal;
      }
    }
  }

  @media print and (max-width: 1200px) {
    color: #000000 !important;
    font-weight: 600 !important;
  }
}
