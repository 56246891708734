@import "../../../styles/global";
@import "../../../styles/form";

.QuickMenuEdit {
  @include default-form;

  display: grid;
  grid-template-columns: 6rem 10rem;

  row-gap: gap-size();
}
