@import "../../../styles/global";
@import "../../../styles/form";

.PriceRounding {
  @include default-form;

  .input {
    margin-left: gap-size();
    width: auto;
  }

  &__heading {
    display: flex;
    margin-bottom: $gap-size;
  }

  & .input--display {
    margin-left: gap-size(1);
  }
}
