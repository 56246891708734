@import "../../../styles/global";
@import "../../../styles/form";

.EventsFilter {
  font-size: $font-size-default;
  margin-bottom: gap-size();

  // TODO:: Remove this once .app-content section.section
  // dynamic padding has been removed
  @media screen and (max-width: 1199px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @include default-form;

  &__controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: gap-size();
  }

  &__eraSelect {
    input[type="radio"] {
      opacity: 0;
      position: absolute;

      &:focus + label {
        outline: solid $outline-width-regular Highlight;
        outline: solid $outline-width-regular -webkit-focus-ring-color;
      }
    }
    input[type="radio"] + label {
      padding: gap-size(0.25) gap-size();
      border: $border-width-fine solid $color-primary-darker;
      margin: 0;
      color: $color-grey-dark;
    }
    input[type="radio"]:checked + label {
      background-color: $color-primary-darker;
      color: $color-text-light;
    }
    input[type="radio"]:not(:checked) + label:hover {
      color: $color-text-dark;
      cursor: pointer;
    }
    @media only screen and (max-width: $screen-xs-min) {
      display: grid;
      grid-auto-columns: 100%;

      > div {
        width: 100%;

        label {
          width: 100%;
          border-top-right-radius: unset !important;
          border-top-left-radius: unset !important;
          border-bottom-right-radius: unset !important;
          border-bottom-left-radius: unset !important;
        }
      }
    }
  }

  &__eraOption {
    display: inline-block;
    margin: 0;
    padding: 0;

    &:nth-of-type(1) label {
      border-top-left-radius: $border-radius-standard;
      border-bottom-left-radius: $border-radius-standard;
    }
    &:nth-of-type(3) label {
      border-top-right-radius: $border-radius-standard;
      border-bottom-right-radius: $border-radius-standard;
    }
  }

  &__filterPanel {
    margin-top: gap-size();
    max-height: 0;
    transition: max-height 1s;
    overflow-y: hidden;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;

    &--visible {
      max-height: 38rem;
    }
  }

  &__filters {
    flex-grow: 1;
    display: grid;
    grid-auto-columns: max-content;
    align-items: baseline;
    grid-column-gap: gap-size();
    grid-row-gap: gap-size();
    padding: gap-size();
    & > *:nth-child(odd) {
      grid-column: 1;
    }
    & > *:nth-child(even) {
      grid-column: 2;
    }
    input[type="number"] {
      width: 5em;
    }

    @media only screen and (max-width: $screen-sm-min) {
      grid-auto-columns: 100%;

      & > *:nth-child(even) {
        grid-column: 1;
      }
    }
  }

  &__resetFiltersButton {
    height: fit-content;
  }

  &__ticketedInput {
    margin-left: gap-size(0.25);
  }
}
