@import "../../../styles/global";

.ActionButtonsLayout {
  display: flex;
  flex-wrap: wrap;
  column-gap: gap-size(0.5);

  & > * {
    margin-bottom: gap-size(0.5);
  }

  &--right {
    justify-content: right;
  }
}
