@import "../../../../styles/global";

.cfbMarket-apikeys-view {
  &__container {
    border: $border-width-thick solid $color-primary-darker;
    border-top: gap-size() solid $color-primary-darker;
    padding: gap-size();
  }

  &__field {
    display: block;
  }

  &__label {
    width: 6rem;
  }
}
