@import "../../../styles/global";
@import "../../../styles/form";

.Event {
  @include default-form;

  &__formSection {
    padding-top: gap-size(0.5);
    background-color: $color-grey-light;
    margin-bottom: gap-size();

    &--top {
      background-color: $color-primary-darker;
      margin-bottom: 0;

      .label {
        color: $color-text-light;
        &--required {
          color: $color-text-light;
        }
      }
      & .input--error {
        border-color: $color-white;
      }
      & .input-error {
        color: $color-white;
      }
    }

    &--no-background {
      background-color: $color-background;
    }
  }

  &__formSectionHeader {
    letter-spacing: 0;
    color: $color-grey-dark;
    font-size: $font-size-standard;
  }

  &__select {
    @include select-styling;
    width: 100%;
    max-width: unset;
    font-size: $font-size-default;
  }

  &__recurringFrequency {
    display: flex;
    white-space: nowrap;

    &:first-child {
      padding-right: gap-size();
    }

    .input {
      text-align: center;
      height: 2rem;
    }
  }

  &__helpText {
    font-size: $font-size-standard;
    color: $color-primary-darker;

    &--withPadding {
      padding-top: gap-size();
    }
  }

  .InfoPanel {
    background-color: $color-background;
  }
}
