@import "../../../styles/global";

.GamePriceFloors {
  padding-bottom: gap-size(2);

  &__header {
    padding-top: gap-size(2);
    display: block;
    background-color: $color-white-dark;

    &--sticky {
      position: sticky;
      top: 0;
      z-index: 1;
    }
  }

  &__headerContent {
    display: flex;
    justify-content: space-between;
  }

  hr {
    padding-bottom: 0;
  }

  &__rarityTable {
    box-shadow: none;
    background: unset;
    width: unset;
    margin: 0 gap-size();
    display: flex;

    tbody {
      background: unset;
      width: 100%;
    }

    tr {
      border-left: unset;
      line-height: unset;

      &:last-of-type {
        border-bottom: unset;
      }
    }
    td {
      padding: gap-size(0.5) 0;
    }

    @include breakpoint-sm-up {
      width: calc(100% - gap-size(2));
      display: table;
    }
  }

  &__toggle {
    @include hover-focus-active {
      color: $color-text-dark !important;
    }
  }
}
