@import "../../../styles/global";
@import "../../../styles/form";

.FloatModal {
  @include default-form;

  &__toggle {
    display: flex;
    flex-wrap: wrap;
    column-gap: gap-size(0.5);
  }

  &__title {
    padding: 0;
  }

  &__description {
    color: $color-grey-inter;
    text-transform: uppercase;
    font-family: "Source Sans Pro", sans-serif;
    margin-bottom: gap-size();
  }

  &__select {
    @include select-styling;
    width: 100%;
    max-width: unset;
    font-size: $font-size-default;
  }

  &__select,
  .input {
    margin-bottom: gap-size(2);
  }

  &__table {
    box-shadow: none;

    tr {
      border-left: unset;
      line-height: unset;
    }

    td:first-child {
      padding-left: gap-size();
    }

    .input {
      margin-top: gap-size(0.5);
      margin-bottom: gap-size(0.5);
      max-width: 5rem;
    }
  }

  &__actionButtons {
    width: 100%;
  }
}
