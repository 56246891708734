@import "../../../styles/global";
@import "../../../styles/form";

.PaymentGatewayRegisterDevice {
  left: 0;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 2000000;
  background-color: rgba(0, 0, 0, 0.4);

  &__modal {
    position: relative;
    background: $color-white;
    justify-content: space-around;
    height: 100%;
    margin: 10rem auto auto;
    max-height: calc(100vh - 20rem);
    max-width: calc(100vw - #{gap-size(2)});
    width: 100%;
    border: $border-width-fine solid $color-grey-dark;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 20rem;
      height: 20rem;
      opacity: 0.3;
      z-index: 0;
      background-image: url("../../../assets/img/modalWatermark.png");
      background-repeat: no-repeat;
      background-position-x: right;
    }

    @include breakpoint-sm-up {
      max-width: 40rem;
      // max-height: 20rem;
    }
  }

  &__layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__content {
    z-index: 5;
    @include default-form;
    padding: gap-size();
    overflow-y: scroll;
  }

  &__actions {
    z-index: 5;
  }

  &__guidance {
    font-size: $font-size-small;
  }

  &__pairingCode {
    font-size: $font-size-large;
    text-align: center;
    letter-spacing: 0.8rem;
  }
}
