@import "../../../styles/global";
@import "../../../styles/form";

.TillListFilters {
  &__controls {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__radioButtons {
    font-size: $font-size-standard;
    input[type="radio"] {
      opacity: 0;
      position: absolute;

      &:focus + label {
        outline: solid 2px Highlight;
        outline: solid 2px -webkit-focus-ring-color;
      }
    }
    input[type="radio"] + label {
      padding: gap-size(0.25) gap-size();
      border: $border-width-fine solid $color-primary-darker;
      margin: 0;
      color: $color-grey-dark;
    }
    input[type="radio"]:checked + label {
      background-color: $color-primary-darker;
      color: $color-text-light;
    }
    input[type="radio"]:not(:checked) + label:hover {
      color: $color-text-dark;
      cursor: pointer;
    }

    label:nth-of-type(1) {
      border-top-left-radius: $border-radius-standard;
      border-bottom-left-radius: $border-radius-standard;
    }
    label:nth-of-type(2) {
      border-top-right-radius: $border-radius-standard;
      border-bottom-right-radius: $border-radius-standard;
    }
  }

  &__filters {
    @include default-form;
    max-width: 100rem;

    margin-top: gap-size();
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: gap-size();
    align-items: flex-end;

    & > * {
      margin-bottom: gap-size(0.125);
    }
  }
}
