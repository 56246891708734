@import "../../../styles/global";

.NewMobileNavItem {
  display: block;
  color: $color-text-dark;
  font-size: 1rem;
  font-family: $font-family-secondary;
  text-decoration: none;
  cursor: pointer;

  &__link,
  &__title {
    background-color: unset;
    border: 0;
    color: $color-text-dark;
    display: block;
    padding: gap-size(0.5) 0;
    position: relative;
    text-align: left;
    text-decoration: none;
    width: 100%;

    @media (hover: hover) {
      &:hover {
        color: $color-primary-darker;
        text-decoration: none;
      }
    }

    &:focus,
    &.active {
      color: $color-primary-darker;
      text-decoration: none;
    }

    &:focus {
      outline: none;
    }
  }

  &__chevron {
    bottom: 0.5rem;
    font-size: $font-size-mid;
    position: absolute;
    right: 0;
  }

  &__icon {
    display: inline-flex;
    justify-content: center;
    margin-right: gap-size(0.5);
    width: 1.5rem;
  }

  &__wrapper {
    transition: height ease 0.2s;
    overflow: hidden;
  }
}
