// Fonts
// -------------------------------------
$font-size-tiny: 0.75rem;
$font-size-small: 0.8rem;
$font-size-standard: 0.9rem;
$font-size-default: 1rem;
$font-size-mid: 1.2rem;
$font-size-large: 1.3rem;
$font-size-huge: 1.5rem;
$font-size-very-huge: 1.7rem;

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$font-family-secondary: "Source Sans Pro", sans-serif;

// Colors
// -------------------------------------
$color-black: #212529;
$color-white: #ffffff;
$color-white-dark: #fcfcfc;
$color-grey-light: #f5f5f5;
$color-grey-mid: #e5e8e9;
$color-grey: #cccccc;
$color-grey-inter: #a3a4aa;
$color-grey-dark: #707078;
$color-charcoal: #4d4f5c;
$color-teal-accent: #dbe3e3;
$color-teal-light: #53c6c6;
$color-teal: #00bdbc;
$color-teal-dark: #05a0a0;
$color-teal-darker: #007d7d;
$color-blue-dark: #0a162e;
$color-red-light: #b55c5c;
$color-orange: #e2b561;
$color-orange-light: #f7ebd4;
$color-red: #950000;
$color-red-dark: #600;
$color-purple: #8582dc;
$color-pink-light: #f18a80;
$color-green: #55b938;

// Color mappings
$color-text-light: $color-white;
$color-text-dark: $color-black;
$color-text-link: $color-teal-darker;
$color-text-link-alt: $color-grey-dark;
$color-background: $color-white;
$color-primary-light: $color-teal-light;
$color-primary: $color-teal;
$color-primary-dark: $color-teal-dark;
$color-primary-darker: $color-teal-darker;
$color-secondary: $color-blue-dark;
$color-error: $color-red;
$color-error-background: $color-grey-mid;
$color-warning-background: $color-orange;
$color-success-background: $color-green;
$color-danger: $color-red-dark;

// Borders
// -------------------------------------
$border-width-fine: 1px;
$border-width-regular: 2px;
$border-width-thick: 3px;
$border-radius-small: 0.25rem;
$border-radius-standard: 0.5rem;
$border-radius-large: 1.75rem;
$outline-width-regular: 2px;

// Shadows
// -------------------------------------
$shadow-standard: 0 3px 6px rgba(0, 0, 0, 0.16);
$shadow-light: 0px 2px 6px #0000000a;

// Spacing
// -------------------------------------
$gap-size: 1rem;

// Transitions
// -------------------------------------
$transition-speed-slow: 500ms;
$transition-speed-default: 350ms;
$transition-speed-fast: 200ms;
$transition-easing-default: ease-out;
$transition-all: all $transition-speed-default $transition-easing-default;
$transition-background: background $transition-speed-default
  $transition-easing-default;
$transition-color: color $transition-speed-default $transition-easing-default;
// Sizes
// -------------------------------------
$button-min-width: 8rem;

// Responsiveness
// -------------------------------------

// Breakpoints
$screen-xs-min: 30rem;
$screen-sm-min: 44rem;
$screen-md-min: 54rem;
$screen-ml-min: 68rem;
$screen-lg-min: 87rem;
$screen-xl-min: 105rem;

// Overlay
// -------------------------------------
$overlay-opacity: 0.75;
$overlay-color: $color-black;
