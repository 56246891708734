@import "../../../styles/global";
@import "../../../styles/form";

.EditCartNotes {
  @include default-form;

  &__cart-notes {
    width: 100%;
  }

  &__custom-field {
    margin-top: gap-size(0.5);
    margin-left: gap-size(0.5);
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
