@import "../../../styles/global";

.EbayConditionMappingModal {
  &__table {
    border: 0;
    box-shadow: none;
    margin-top: gap-size();
    width: 32rem;
  }

  &__table-row {
    border-left: 0;

    thead & {
      border-bottom: $border-width-thick solid $color-grey-mid;
    }
  }

  &__table-header {
    background-color: $color-background;
    color: $color-text-dark;
    font-weight: $font-weight-bold;
  }

  &__table-header:nth-child(2),
  &__table-cell:nth-child(2) {
    font-style: italic;
    font-weight: $font-weight-normal;
    padding-right: 1rem;
    text-align: center;
    width: 6rem;
  }
}
