@import "../../../styles/global";

.DefaultErrorPage {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  width: 100vw;
  height: 100vh;
  background-color: $color-secondary;
  color: $color-text-light;
  display: flex;
  justify-content: center;
  align-items: center;

  &__modal {
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
    max-width: 600px;
    max-height: 600px;
    border-top: 5px solid $color-secondary;
    border-bottom: 5px solid $color-secondary;
    background-color: $color-text-light;
    position: absolute;
    box-shadow: 0px 3px 6px #00000029;
    color: $color-secondary;
    padding: 2rem;
    background-image: url(../../../assets/img/watermark.png);
    background-repeat: no-repeat;
    background-position: -200%;
    overflow-y: auto;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: baseline;
  }

  &__title {
    padding-left: 0;
  }

  &__image {
    max-width: 8rem;
    height: 100%;
  }
}
