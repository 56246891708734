@import "../../../styles/global";

.CustomerCsvUploadModal {
  &__label {
    position: relative;
  }

  &__input {
    display: block;
  }

  &__file-upload {
    align-items: center;
    background-color: $color-primary-darker;
    border: 0;
    border-radius: 5px;
    color: $color-text-light;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    position: absolute;
    transition: all 1s;
    top: 0;
    width: 9rem;
    z-index: 1;

    &:hover,
    &:focus {
      background-color: $color-primary-dark;
    }
  }
}
