@import "../../../styles/global";

.NewMobileNavItemLink {
  font-size: 1rem;

  &:last-child {
    margin-bottom: gap-size(0.25);
  }

  &__Link,
  &__Button {
    color: $color-text-dark;
    display: block;
    font-family: $font-family-secondary;
    margin-left: gap-size(1.5);
    padding: gap-size(0.5) gap-size(2) gap-size(0.5) gap-size(0.5);

    &:hover,
    &:focus,
    &.active {
      color: $color-primary-darker;
      text-decoration: none;
    }

    &:focus {
      outline: none;
    }

    &.active {
      border-left: $border-width-fine solid $color-primary-darker;
    }
  }

  &__Button {
    background-color: unset;
    border: 0;
  }
}
