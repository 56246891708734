@import "../../../styles/form";

.User {
  @include default-form;

  &__name-container {
    display: flex;
    flex-direction: column;

    @include breakpoint-lg-up {
      flex-direction: row;
      gap: gap-size();
    }
    div {
      width: 100%;
    }
  }
}
