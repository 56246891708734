@import "../../../styles/global";
@import "../../../styles/form";

.QuantitySelector {
  @include default-form;
  $this: &;
  background: $color-primary-darker;
  height: 24px;
  border-radius: 26px;

  &__minus,
  &__plus {
    align-items: flex-end;
    background: $color-background;
    border: 0;
    border-radius: 1.25rem;
    color: $color-primary-darker;
    display: inline-flex;
    height: 1.25rem;
    justify-content: center;
    line-height: 1rem;
    margin: 2px;
    vertical-align: baseline;
    width: 1.25rem;

    &:disabled,
    &[disabled] {
      background-color: $color-background !important;
    }

    i {
      position: relative;
    }
  }

  & .input {
    background: none;
    border: none;
    color: $color-text-light;
    display: inline-block;
    height: 24px;
    margin: 0;
    min-width: auto;
    outline: none;
    padding: 0;
    text-align: center;
    vertical-align: top;
    width: 30px;
    &:focus,
    &.active {
      outline: none;
    }
    &:disabled {
      color: $color-text-light;
    }
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  &--secondary {
    background: $color-orange !important;
    #{$this}__minus,
    #{$this}__plus {
      color: $color-orange !important;
    }
    .input {
      color: $color-secondary !important;
    }
  }

  &--disabled {
    background: $color-grey !important;
    color: $color-secondary !important;
    cursor: wait !important;
    pointer-events: none;
    #{$this}__minus,
    #{$this}__plus {
      color: $color-grey !important;
    }
  }
}
