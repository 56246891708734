@import "../../../styles/global";

.PosPaymentTypesSettings {
  &__title {
    font-size: $font-size-large;
    margin-bottom: gap-size(0.5);
  }

  &__description {
    margin-bottom: gap-size(1);
  }

  &__addButton {
    align-items: center;
    background-color: $color-primary-darker;
    border-radius: 0 $border-radius-standard $border-radius-standard 0;
    color: $color-white;
    display: flex;
    margin-left: gap-size(-0.25);
    padding: gap-size(0.5) gap-size(1);

    &:hover {
      background-color: $color-primary-dark;
    }
  }

  &__paymentTypeList {
    list-style: none;
    margin: gap-size() 0;
    padding: 0;
  }

  &__paymentType {
    margin-bottom: gap-size(0.5);
  }

  &__removeButton {
    padding-left: 0.5rem;
  }

  &__newPaymentMethod {
    display: flex;
    align-items: stretch;
    max-width: 25rem;
  }

  &__input {
    border: $border-width-fine solid $color-primary !important;
    margin-bottom: 0 !important;
    z-index: 1;
  }
}
