@import "../../../styles/global";

.NumberPagination {
  display: flex;
  align-items: center;
  margin-right: auto;
  column-gap: gap-size(0.5);

  button {
    min-width: 3rem;
  }
}
