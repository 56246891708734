.data-table {
  font-size: 1.1em;
  color: #4d4f5c;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px 3px #00000026;
  border: none !important;
  margin-bottom: gap-size();

  .negative {
    color: $color-red;
  }

  i.fas,
  i.far {
    font-size: 1.2em;
    &:hover {
      cursor: pointer;
    }
  }

  .table-row-clickable {
    &:hover {
      cursor: pointer;
    }
  }

  .table-container {
    width: calc(100vw - 489px);
    height: calc(100vh - 375px);

    @media only screen and (max-width: 1365px) {
      width: calc(100vw - 120px);
    }

    @media only screen and (max-width: 1199px) {
      width: calc(100vw - 45px);
    }

    @media only screen and (max-width: 768px) {
      width: calc(100vw - 50px);
    }
  }

  .filters {
    select {
      width: 98%;
      height: 28px !important;
      font-family: "Source Sans Pro", sans-serif;
      color: grey;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      padding: 5px 7px;
      border-radius: 3px;
      font-weight: normal;
      outline-width: 0;
    }

    input[type="text"] {
      width: 98%;
      height: 28px;
      font-family: "Source Sans Pro", sans-serif;
      border: 1px solid rgba(0, 0, 0, 0.1);
      background: #fff;
      padding: 5px 7px;
      font-size: inherit;
      border-radius: 3px;
      font-weight: normal;
      outline-width: 0;
    }
  }
}

.filterable-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  > div {
    overflow: hidden;
    padding: 0 0.25em;
  }
  button {
    font-size: 0.8em;
  }
}
