@import "./global";
@import "./button";

@mixin default-form {
  &__inputs {
    display: grid;
    grid-template-columns: max-content auto;
    grid-row-gap: gap-size(0.5);
    grid-column-gap: gap-size(0.5);
  }
  &__field-error {
    color: $color-error;
    grid-column: 2;
    margin-top: -0.5rem;
    font-size: 0.9rem;
  }

  &__actions {
    margin-top: gap-size();
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-column-gap: gap-size();
    line-height: 2.5;
  }

  &__required {
    color: $color-primary-darker;
  }

  &__unit-select {
    height: 95%;
  }

  .input {
    background: $color-background;
    border-radius: $border-radius-small;
    min-width: max-content;
    width: 100%;
    border: $border-width-fine solid $color-grey;
    letter-spacing: 0;
    color: $color-text-dark;
    padding-left: gap-size(0.5);
    font-family: $font-family-secondary;

    &--inline {
      width: min-content;
      margin-right: gap-size(0.5);
    }

    &--error {
      border-color: $color-red;
    }

    &:disabled {
      color: $color-grey;
    }

    &--display {
      border-color: transparent;
      display: inline-block;
      background: transparent;
      color: $color-text-dark !important;
      font-family: $font-family-secondary;
      font-size: 1em;
      width: max-content;
      position: relative;

      .input--adornment-end,
      .input--adornment-start {
        border: 0;
        padding: 0;
        margin: 0;
      }
    }

    &--adornment-field {
      padding: 0;
      border: 0px;
      &:focus {
        outline: none;
      }

      &:disabled {
        background: transparent !important;
      }
    }

    &__adornment-wrapper {
      display: flex;
      &--editing {
        justify-content: space-between;
        padding-right: gap-size(0.5);
      }
    }

    &--adornment-end {
      order: 1;
      color: $color-grey-dark;
      border-left: $border-width-fine solid $color-grey;
      padding-left: gap-size(0.5);
      margin-left: gap-size(0.5);
    }

    &--adornment-start {
      order: 0;
      color: $color-grey-dark;
      border-right: $border-width-fine solid $color-grey;
      padding-right: gap-size(0.5);
      margin-right: gap-size(0.5);
    }
  }

  .input-group {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
    margin-bottom: gap-size(1.5);

    &--inline {
      flex-wrap: nowrap;
      margin-bottom: gap-size(1.5);

      & > .input-group {
        margin-bottom: unset;
      }
    }
  }

  .input-error {
    color: $color-red;
    font-size: $font-size-standard;
    line-height: gap-size();
    margin-bottom: gap-size(-1.2);
    margin-top: gap-size(0.2);
  }

  .input__helperText {
    color: $color-grey-dark;
    font-size: $font-size-standard;
    line-height: gap-size();
    margin-bottom: gap-size(-1.2);
    margin-top: gap-size(0.2);
  }

  .label {
    letter-spacing: 0;
    color: $color-grey-dark;
    font-family: $font-family-secondary;
    margin-bottom: gap-size(0.125);

    &--required {
      color: $color-red-light;
      margin-left: gap-size(0.2);
      &:after {
        content: "\2736";
      }
    }

    &--optional {
      margin-left: gap-size(0.2);
      font-style: oblique;
    }
  }

  .submitButton {
    @include button;
    width: 100%;
    border: none;
    padding: gap-size();
    font-size: $font-size-standard;
    border-radius: 0;
  }
}

@mixin select-styling-base {
  font-family: $font-family-secondary;
  font-size: $font-size-small;
  text-align: center;
  text-align-last: center;
  min-width: 10rem;
  max-width: 206px;
  height: 2rem;
  margin-bottom: gap-size();
  display: block;
  cursor: pointer;
  background-position: right;
  padding: gap-size(0.25) gap-size();
  box-shadow: $shadow-light;
  border-radius: $border-radius-small;
  border: 1px solid $color-primary-darker;
  option {
    text-align: center;
    text-align-last: center;
  }
}

@mixin select-styling {
  @include select-styling-base;
  background: $color-primary-darker 0% 0% no-repeat padding-box;

  color: $color-text-light;

  &.active,
  &:hover {
    background: $color-primary-dark;
    transition: $transition-all;
    text-decoration: none;
  }
}

@mixin select-styling-subtle {
  @include select-styling-base;
}
