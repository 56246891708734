@import "../../../styles/global";

.UploadCsv {
  &__dropzone {
    border: $border-width-fine solid $color-primary-dark;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: gap-size(1);
    min-height: 6rem;
    margin-bottom: gap-size();

    & i {
      font-size: 2rem;
      color: $color-primary-dark;
    }

    &--success,
    &--error {
      font-size: $font-size-standard;
    }

    &--active {
      background-color: $color-grey-light;
    }
  }

  &__layout {
    display: flex;
    align-items: center;
    column-gap: gap-size(0.5);
    margin-bottom: gap-size(0.5);
    text-align: center;
  }

  &__uploadStatus {
    font-size: $font-size-small;
    text-align: center;
  }

  &__downloadLink {
    color: $color-text-link-alt;
    &:hover {
      color: $color-text-link;
    }
  }
}
