@import "../../../styles/global";
@import "../../../styles/form";

.PreviousImports {
  &__row {
    &--uncompleted {
      color: $color-primary !important;
      font-style: italic;
    }
  }
  .Paging {
    display: flex;
    justify-content: space-between;
    margin-top: gap-size(0.5);

    select {
      @include select-styling-base;
      height: 1.5rem;
      padding: 0;
    }
  }
}
