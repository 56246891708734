@import "../../../styles/global";

.Spinner {
  position: relative;
  animation: 3s ease 0s normal forwards 1 fadeIn;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    66% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  &__overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-background;
    opacity: $overlay-opacity;
    z-index: 5;
  }

  &__border {
    display: inline-block;
    width: 50px;
    height: 50px;
    vertical-align: text-bottom;
    border: 8px solid $color-text-dark;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner 0.75s linear infinite;
    @keyframes spinner {
      to {
        transform: rotate(360deg);
      }
    }
  }
}
