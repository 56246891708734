@import "../../../styles/global";
@import "../../../styles/form";

.SettingsSection {
  @include default-form;
  border: $border-width-fine solid $color-text-dark;
  margin-bottom: gap-size(1);

  &__header {
    align-items: center;
    background: $color-text-dark;
    display: flex;
    justify-content: space-between;
    padding: gap-size(0.5);
    margin-bottom: gap-size(0.5);

    h2 {
      color: $color-text-light;
      font-size: $font-size-mid !important;
      margin-bottom: 0;
      padding: 0;
    }
  }

  &__subheader {
    display: grid;
    gap: gap-size();
    grid-template-areas:
      "subtitle"
      "controls";
    padding: 0 gap-size();
    margin-bottom: gap-size(0.5);

    @include breakpoint-sm-up() {
      grid-template-areas: "subtitle controls";
    }
  }

  &__subtitle {
    grid-area: subtitle;
  }

  &__controls {
    display: flex;
    justify-content: right;
    grid-area: controls;
  }

  &__submit {
    margin-left: gap-size();
  }

  &__content {
    padding: gap-size(0.5) gap-size(1);
  }

  .row {
    justify-content: space-between;
    margin: 0;
  }
}
