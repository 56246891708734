@import "../../../styles/global";

.Till {
  display: flex;
  flex-direction: column;
  box-shadow: $shadow-standard;
  background-color: $color-background;
  height: 100%;

  &__header,
  &__details {
    padding: gap-size();
  }

  &__header {
    display: flex;
    column-gap: gap-size();
    padding-bottom: 0;
    border-bottom: $border-width-fine solid $color-grey-light;
  }

  &__details {
    flex-basis: 10rem;
    flex-grow: 1;
  }

  &__image {
    color: $color-primary-dark;
    font-size: 2rem;
  }

  &__name {
    font-size: $font-size-large !important;
    margin-bottom: 0;
  }

  &__status {
    color: $color-grey-inter;
    text-transform: uppercase;
    font-size: $font-size-standard;
  }

  &__label {
    text-transform: uppercase;
    color: $color-charcoal;
  }

  &__detail {
    color: $color-grey-inter;
    font-size: $font-size-standard;
  }

  &__actions > * {
    white-space: nowrap;
  }
}
