@import "../../../styles/global";

.CustomerSelect {
  display: inline-block;
  margin-left: 0;
  position: relative;

  &__searchIcon {
    color: $color-primary-darker;
    position: absolute;
    top: 9px;
    margin-left: gap-size(0.75);
  }
  &__addCustomer {
    padding: 0;
    position: absolute;
    right: 11px;
    top: 3px;
  }
  &__searchField {
    border: $border-width-regular solid $color-primary-darker;
    border-radius: 14px;
    height: 2rem;
    width: 299px;
    font-size: $font-size-small;
    letter-spacing: 0;
    font-family: "Source Sans Pro", sans-serif;
    color: $color-charcoal;
    padding-left: 33px;
    padding-right: 39px;
  }
  &__CustomerInfo {
    align-items: baseline;
    color: $color-charcoal;
    display: flex;
    font-family: "Source Sans Pro", sans-serif;
    font-size: $font-size-small;
    gap: gap-size();
    letter-spacing: 0;
    position: fixed;
    right: 20px;
    text-align: right;
    top: 94px;
    z-index: 10;
  }
  &__CustomerInfoRow {
    display: flex;
    align-self: end;
    line-height: 1rem;
  }
  &__AutocompleteItems {
    display: block;
    position: absolute;
    z-index: 9999;
    background: rgba(255, 255, 255, 0.9);
    right: 0;
    box-shadow: 0px 2px 10px #000000b8;
    border: 1px solid rgba(10, 22, 46, 0.4);
    border-top: none;
    top: 58px;
    padding: 10px 20px;
    width: 300px;

    &--hidden {
      display: none;
    }
  }

  @media screen and (min-width: 1365px) and (max-width: 1635px) {
    &__searchField {
      width: 190px;
    }
  }

  @media screen and (max-width: 650px) {
    & {
      &__CustomerInfo {
        display: none !important;
      }
      &__AutocompleteItems {
        top: 32px;
      }
    }
  }

  @media screen and (min-width: 1366px) {
    & {
      top: -1px;

      &__CustomerInfo {
        flex-direction: column;
        gap: 0;
      }
    }
  }
}
