@import "../../../styles/global";
@import "../../../styles/form";

.TillSettings {
  @include default-form;

  &__tillSelect {
    margin-top: gap-size();

    display: flex;
    column-gap: gap-size();
    align-items: center;
  }

  select {
    @include select-styling;
  }
}
