@import "../../../styles/global";

.IconButton {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 0;
  background: none;
  width: max-content;
  padding: 0 gap-size();
  font-size: $font-size-mid;
  color: $color-primary-darker;
  cursor: pointer;

  @include hover-focus-active {
    color: $color-primary-dark;
    transition: $transition-background;
    text-decoration: none;
  }

  &--small {
    font-size: $font-size-standard;
  }

  &--secondary {
    color: $color-text-dark;
    @include hover-focus-active {
      color: $color-text-light;
    }
  }

  &--light {
    color: $color-white-dark;
    @include hover-focus-active {
      color: $color-white;
    }
  }

  &--danger {
    color: $color-danger;
    @include hover-focus-active {
      color: $color-red;
    }
  }

  &--tooltip {
    padding: 0 gap-size(0.25);
    font-size: $font-size-small;
  }
}
