@import "../../../styles/global";
@import "../../../styles/form";

.AddTill {
  @include default-form;

  .field {
    margin-bottom: gap-size();
  }
}
