@import "../../../styles/global";
@import "../../../styles/form";

.product-edit-form {
  @include default-form;
  max-width: 80rem;

  &__product-details {
    display: flex;
    flex-wrap: wrap;
    column-gap: gap-size();

    &--left {
      flex-basis: 60%;
      flex-grow: 1;
    }

    &--right {
      flex-basis: 20rem;
      flex-grow: 1;
    }
  }
}
