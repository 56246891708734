@import "../../../styles/global";
@import "../../../styles/form";

.EventImageUpload {
  @include default-form;

  &__container {
    background-color: $color-background;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__wrapper {
    width: 7rem;
    height: 7rem;
    border: $border-width-fine solid $color-grey-inter;
    border-radius: 6px;
    margin: gap-size();
    display: flex;
    justify-content: center;
    align-items: middle;
  }

  &__image {
    max-width: 100%;
    max-height: 100%;
  }

  &__fileSelect {
    width: 100%;
  }

  &__input {
    display: none;
  }

  &__error {
    color: $color-error;
    font-size: 0.9rem;
    padding: gap-size(2) gap-size();
    height: 8rem;
  }
}
