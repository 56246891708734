@import "../../../styles/global";

.ProductLineVariantItem {
  // 1200 px
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 1rem;

  @include breakpoint-ml-up {
    flex-direction: row;
    column-gap: gap-size();
  }

  &__variantTitle {
    color: $color-text-dark;
    font-size: $font-size-default;
    font-family: $font-family-secondary;

    @include breakpoint-ml-up {
      flex-basis: 6rem;
      align-self: center;
      font-size: $font-size-standard;
    }

    @include breakpoint-xl-up {
      flex-basis: 10rem;
    }
  }

  &__settings {
    flex: 1;
    display: flex;
    flex-wrap: wrap;

    .QuantitySelector {
      margin: 1rem 0;

      .input {
        width: 50px;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    column-gap: gap-size();
    margin-bottom: gap-size();

    @include breakpoint-ml-up {
      flex-direction: column;
      justify-content: flex-start;
      row-gap: gap-size();

      .ButtonComponent__primary {
        margin-right: 2.2rem;
      }
    }
  }
}
