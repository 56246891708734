@import "../../../styles/global";

.SupportMenu {
  &__container {
    position: relative;
    z-index: 10000;
  }
  &__modal {
    position: absolute;
    top: 100%;
    right: 0;
    background: $color-background;
    padding: gap-size(1);
    border-radius: $border-radius-standard;
    border: $border-width-fine solid $color-grey-mid;
    width: max-content;
    box-shadow: $shadow-standard;
    opacity: 0;
    pointer-events: none;
    transition: opacity $transition-speed-default ease-in-out;
    display: flex;
    flex-direction: column;
    > *:not(:last-child) {
      margin-bottom: gap-size(1);
    }
  }
  &__modal--open {
    opacity: 1;
    pointer-events: auto;
  }
  &__modal-item {
    display: flex;
    align-items: center;
    color: $color-teal-dark;
    cursor: pointer;
    &:hover {
      color: $color-primary-darker;
      text-decoration: none;
    }
  }
  &__icon {
    padding-right: gap-size(1);
    width: 30px;
    text-align: center;
  }
  &__icon-secondary {
    margin-left: gap-size();
    font-size: $font-size-small;
  }
}
