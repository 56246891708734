@import "../../../styles/global";

.multiTenderCheckout {
  &__balance {
    display: flex;
    justify-content: space-between;
    font-size: $font-size-mid;
    margin-right: gap-size();

    &--changeDue {
      font-weight: $font-weight-semi-bold;
    }
  }

  &__tenders {
    margin-top: gap-size();
  }

  &__tender {
    display: flex;
    font-size: $font-size-mid;
  }

  &__tenderName {
    flex: 1;
  }

  &__tenderRemove {
    color: $color-primary-dark;
    margin-left: gap-size(0.5);
    margin-right: gap-size(-1.25);
    border: 0;
    background: none;
  }

  &__loader-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($color: $overlay-color, $alpha: $overlay-opacity);
  }
}
