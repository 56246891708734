@import "../../../styles/global";

.Navbar {
  align-items: center;
  background: $color-secondary;
  background: url(../../../assets/img/watermarkMenu.png), $color-secondary;
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-position-x: 297px;
  background-position: right;
  background-position-y: -26px;
  display: flex;
  flex-wrap: wrap;
  height: 5.5rem;
  justify-content: space-between;
  padding: 0.5rem 1rem;

  &__posMenu {
    position: absolute;
    right: 148px;
    background: $color-primary-darker;
    border-radius: 14px;
    height: 2rem;
    padding-left: 0;

    &--kiosk {
      padding-top: 1px;
    }
  }

  &__mobileMenu {
    display: inline-block;

    &--kiosk {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      & > * {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }
  }

  @media screen and (max-width: 1365px) {
    &__mobileMenu {
      text-align: right;
      position: fixed;
      top: 84px;
      display: none;
      right: 0;
      width: 100vw;
      height: calc(100vh - 84px);
      z-index: 9999999;
      background: $color-secondary;
      max-width: 281px;
    }
  }

  @media screen and (max-width: 1700px) {
    &__posMenu {
      padding-left: 0;
      right: 20px;
    }
  }

  @media screen and (max-width: 1365px) {
    &__posMenu {
      right: 75px;
    }
  }

  @media screen and (min-width: 1856px) {
    &__posMenu {
      padding-left: 38px;
    }
  }

  @media screen and (max-width: 650px) {
    &__posMenu {
      background: none;
      display: flex;
      justify-content: center;
      position: fixed !important;
      top: 113px;
      right: 0;
      margin: 0 auto;
      width: 100%;
      z-index: 9;
    }
    &__mobileMenu {
      right: 0;
    }
  }
}
