@import "../../../styles/global";
@import "../../../styles/form";

.product-tags {
  @include default-form;
  display: flex;
  margin-bottom: gap-size();

  .input {
    margin-right: gap-size(0.5);
  }
  &__button {
    min-width: auto;
  }
  &__tag-container {
    display: flex;
    gap: gap-size(0.5);
    flex-wrap: wrap;
  }
  &__tag-entry {
    padding: gap-size(0.25) gap-size(0.5);
    border: $border-width-fine solid $color-grey;
    border-radius: $border-radius-small;
    font-size: $font-size-small;
    cursor: pointer;
    &:hover {
      background: $color-grey-dark;
      color: $color-text-light;
    }

    & .IconButton {
      padding: 0;
      font-size: $font-size-standard;
    }
  }
}
