@import "../../../styles/global";

.StoreLogo {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__upload-button {
    font-size: $font-size-default;
    height: 2.5em;
    font-weight: 400;
  }

  input[type="file"] {
    display: none;
    &:before {
      display: none !important;
    }
  }

  &__description {
    font-size: $font-size-small;
  }

  &__file-error {
    color: $color-error;
  }

  &__image {
    width: 10rem;
    height: 10rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include breakpoint-md-up {
      margin-right: 2.5rem;
    }
  }
}
