@import "../../../styles/global";

.SearchBar {
  margin-bottom: gap-size();

  @include breakpoint-custom-up(1366px) {
    height: 65px;
  }

  &__suggestBox {
    background: $color-background;
    border: 1px solid $color-grey-mid;
    border-radius: 5px;
    box-shadow: 0px 2px 6px #0000000a;
    color: $color-grey-dark;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 15px;
    left: 0px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 40px;
    width: 100%;
    z-index: 9999;

    @include breakpoint-custom-up(651px) {
      top: 50px;
      left: 65px;
      width: calc(100vw - 335px);
    }

    @include breakpoint-custom-up(1366px) {
      left: 75px;
      overflow: auto;
      top: 65px;
      width: calc(calc(100vw - 625px) - 30%);
    }

    .suggestionItem {
      padding-left: 25px;
      height: 40px;
      display: block;
      line-height: 40px;
      cursor: pointer;
      &:hover {
        color: $color-secondary;
      }
      &:nth-child(even) {
        background: $color-grey-light;
      }
    }
  }

  &__suggestToggle {
    background: $color-primary-darker;
    border-radius: 27px;
    color: $color-text-light;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 13px;
    height: 28px;
    left: calc(100vw - 205px);
    line-height: 28px;
    padding-left: 16px;
    position: absolute;
    top: 5px;
    width: 55px;
    z-index: 9;

    @include breakpoint-custom-up(651px) {
      left: calc(100vw - 404px);
      top: 20px;
      width: 136px;
    }

    @include breakpoint-custom-up(1366px) {
      left: calc(100vw - 533px - 30%);
      position: fixed;
      top: 150px;
    }

    .predictive {
      vertical-align: top;
      display: none;
      @include breakpoint-custom-up(651px) {
        display: inline;
      }
    }

    .switch {
      height: 24px;
      position: relative;
      left: -14px;
      width: 52px;
      top: 2px;

      @include breakpoint-custom-up(651px) {
        left: 11px;
      }

      .switchCheckbox {
        opacity: 0;
        width: 0;
        height: 0;
      }
      .switchCheckbox:focus + .slider {
        box-shadow: 0 0 1px #2196f3;
        outline: solid 2px Highlight;
        outline: solid 2px -webkit-focus-ring-color;
        transition: none;
      }
      .switchCheckbox:checked + .slider {
        background-color: #59bb6f;
      }
      .switchCheckbox:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }

      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $color-grey-dark;
        -webkit-transition: 0.4s;
        transition: 0.4s;
        border-radius: 34px;

        &:before {
          border-radius: 50%;
          position: absolute;
          content: "";
          height: 24px;
          width: 24px;
          left: 0px;
          bottom: 0px;
          background-color: $color-white;
          -webkit-transition: 0.4s;
          transition: 0.4s;
        }
      }
    }
  }

  &__input {
    border: 2px solid $color-primary-dark;
    border-radius: 14px;
    color: $color-charcoal;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 13px;
    height: 28px;
    margin-left: 0px;
    padding-left: 23px;
    position: relative;
    top: -15px;
    width: calc(100vw - 173px);

    @include breakpoint-custom-up(651px) {
      margin-left: 45px;
      position: static;
      width: calc(100vw - 357px);
    }

    @include breakpoint-custom-up(1366px) {
      left: 92px;
      margin-left: auto;
      padding-left: 15px;
      position: fixed;
      top: 150px;
      width: calc(calc(100vw - 533px) - 30%);
    }
  }
}
