@import "../../../styles/form";
@import "../../../styles/global";

.EbayStockChangeFilters {
  @include default-form;

  padding: gap-size();
  background-color: $color-grey-light;

  &__category > * {
    display: block;
  }
}
