@import "../../../styles/global";

.product-edit-form {
  .product-variants {
    &__row {
      &--deleted {
        display: none;
        pointer-events: none;
      }
    }

    td {
      padding: gap-size(0.5) gap-size(0.25);
    }

    .input {
      min-width: unset;
    }

    .input-group {
      margin-bottom: 0;
    }

    &__row {
      padding: 0.5rem 0 0.75rem;
    }

    &__weight {
      display: flex;
      width: 100%;
      @media screen and (min-width: $screen-ml-min) {
        max-width: 10rem;
      }

      label {
        display: none;
      }
    }

    &__weight-unit {
      min-width: 3rem !important;
      height: 100%;
    }

    &__price {
      @media screen and (min-width: $screen-ml-min) {
        max-width: 10rem;
      }
    }

    &__add {
      margin-top: gap-size();
    }
  }
}
