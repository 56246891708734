@import "../../../../styles/global";

.StockChangeInfoView {
  &__results {
    margin: 1rem 0;
    > .data-table {
      overflow-y: hidden;
    }
    > table {
      margin-bottom: 1rem;
    }
  }

  &__actions {
    display: flex;
    gap: gap-size(0.5);

    & .ButtonComponent {
      font-size: $font-size-tiny;
      padding: 0 gap-size(0.5);
    }
  }

  &__no-results {
    display: flex;
    justify-content: center;
    padding-top: gap-size();
  }

  .table-container {
    width: 100%;
  }
}
