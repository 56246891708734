@import "../../../styles/global";

.GridLayout {
  display: grid;
  grid-template-columns: repeat(auto-fill, 331px);
  column-gap: gap-size();
  row-gap: gap-size();
  align-items: center;
  justify-content: center;
  margin-top: gap-size(2);

  @include breakpoint-ml-up {
    grid-template-columns: repeat(auto-fill, 386px);
  }
}
