@import "../../../styles/global";

.MxMerchantTerminalInstructions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  z-index: 5;

  &__content {
    padding: gap-size();
  }

  &__detail {
    font-weight: $font-weight-bold;
  }

  ol {
    margin-top: gap-size();
  }

  li {
    margin-bottom: gap-size();
  }
}
