@import "../../../../styles/global";

$event-card-header-height: 217px;

.EventCard {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;

  &__header {
    max-width: 100%;
    flex-basis: $event-card-header-height;
    position: relative;
    overflow: hidden;
    img {
      max-width: 100%;
      width: 100%;
    }
  }

  &__ticketIcon {
    background: rgba($color-white, 0.8);
    width: 80px;
    height: 80px;
    border-radius: 100%;
    position: absolute;
    overflow: hidden;
    right: gap-size(0.5);
    bottom: gap-size(0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 90%;
    }
  }

  &__contents {
    flex-grow: 1;
  }

  &__title {
    letter-spacing: 0;
    color: $color-secondary;
    font-size: $font-size-huge;
    width: 100%;
    display: block;
    margin: gap-size();
    line-height: 1.25;
  }

  &__types {
    letter-spacing: 0;
    color: $color-text-dark;
    text-transform: uppercase;
    font-size: $font-size-default;
    font-family: $font-family-secondary;
    em {
      display: block;
      font-style: normal;
      text-decoration: unset;
      color: $color-grey-dark;
    }
  }

  &__info {
    color: $color-primary-darker;
    font-family: $font-family-secondary;
    a,
    button {
      background: none;
      border: 0;
      color: $color-primary-darker;
      padding: 0;
      text-decoration: none;
      cursor: pointer;
      &.active,
      &:hover {
        color: $color-primary-dark !important;
        transition: all 1s;
      }
    }
    i {
      color: $color-grey-dark;
      padding-right: gap-size(0.5);
    }
  }
  &__participants {
    letter-spacing: 0;
    color: $color-grey-dark;
    text-transform: uppercase;
    font-size: $font-size-standard;
    font-family: $font-family-secondary;
    text-align: center;

    a {
      color: $color-primary-darker;
      cursor: pointer;
      text-decoration: none;
      font-weight: $font-weight-semi-bold;
      &.active,
      &:hover {
        color: $color-primary-dark;
        transition: all 0.5s;
      }
    }
    em {
      font-style: normal;
    }
  }
}
