@import "../styles/global";
@import "../styles/form";

// Was events.scss but all this stuff is in use by buylist rules
.newEvent {
  .btn1 {
    width: max-content;
    padding: 0 22px;
    min-width: 160px;
    float: right;
    position: relative;
    bottom: 29px;
  }
  .conditionSelector {
    margin-top: 1rem;
    button {
      background: $color-grey-dark;
      border: 0;
      display: inline-block;
      margin: 0 7px;
      box-shadow: 0px 2px 6px #0000000a;
      border-radius: 5px;
      text-align: center;
      font-family: "Source Sans Pro", sans-serif;
      color: $color-text-light;
      cursor: pointer;
      &.active {
        background: $color-primary-darker;
      }
    }
  }
  .revised {
    p.subHeading {
      color: $color-grey-dark;
      margin-bottom: 1px;
      margin-top: 10px;
      font-family: "Source Sans Pro", sans-serif;
      border-top: 1px solid $color-grey-mid;
      padding-top: 4px;
      margin-top: 11px;
    }
  }
  .card-content.content .variants input {
    margin-bottom: 28px;
  }
  .rulesWrapper {
    margin: 21px;
    margin-bottom: 40px;
    .rarityWrapper {
      background: $color-grey-light;
      width: 100%;
      padding: 17px;
      p.subHeading.centered {
        color: $color-grey-dark;
        font-family: "Source Sans Pro", sans-serif;
      }
    }
    .block {
      p.title {
        color: $color-text-dark;
        margin-bottom: 1px;
        margin-top: 10px;
        font-family: "Source Sans Pro", sans-serif;
      }
      p.help {
        color: $color-grey-dark;
        font-size: 13px;
        font-family: "Source Sans Pro", sans-serif;
      }
      input {
        border: 1px solid $color-grey-mid;
        border-radius: 5px;
        width: 100%;
        padding-left: 16px;
      }
    }
    label.checkbox {
      color: $color-grey-dark;
      font-size: 15px;
      .checkmark {
        top: -1px;
      }
    }
    .rulesBlock {
      display: none;
      &.active {
        display: block;
      }
    }
  }
}

.filters,
.newEvent {
  &.update {
    margin-bottom: 50px;
    .EventBtn {
      margin-top: -37px;
    }
  }
  .field {
    label.label {
      color: $color-grey-dark;
      font-size: 15px;
      margin-top: 9px;
      font-family: "Source Sans Pro", sans-serif;
    }
  }
  .topcontent {
    background: $color-primary-darker;
    padding: 15px 0px;
    span.icon.is-small.is-left {
      position: absolute;
      left: 23px;
      top: 37px;
      color: $color-primary-darker;
    }
    label {
      letter-spacing: 0;
      color: $color-text-light;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 15px;
    }
    input {
      border: 1px solid $color-grey-mid;
      border-radius: 5px;
      width: 100%;
      padding-left: 15px;
      color: $color-grey-dark;
      font-family: "Source Sans Pro", sans-serif;
      &:focus {
        outline: none;
      }
    }
  }
}
