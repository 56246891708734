@import "../../../styles/global";

.FilterTabButton {
  background: none;
  border: 0;
  color: $color-text-light;
  cursor: pointer;
  padding: 0 gap-size(0.5);
  margin-right: gap-size(1.5);

  i {
    position: relative;
    top: 0.3rem;
    font-weight: 900;
    padding-left: gap-size(0.25);
  }

  &.active {
    color: $color-primary-light;
    i {
      top: -0.2rem;
    }
  }

  &:hover {
    color: $color-primary;
    text-decoration: none;
  }
}
