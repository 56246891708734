@import "../../../styles/global";

.InfoPanel {
  padding: gap-size();
  background-color: $color-grey-light;
  border: $border-width-fine solid $color-primary-darker;
  border-radius: $border-radius-standard;
  display: flex;
  align-items: flex-start;

  a {
    color: $color-primary-darker;
    text-decoration: underline;
    transition: $transition-color;

    &:hover {
      color: $color-primary-dark;
    }
  }

  i {
    color: $color-primary-darker;
    margin-right: gap-size(0.5);
    margin-top: gap-size(0.3);
  }

  pre {
    display: inline;
  }

  &--withTitle {
    flex-direction: column;
    align-items: inherit;
  }

  &__title {
    font-weight: $font-weight-bold;
  }
}
