@import "../../../styles/global";
@import "../../../styles/form";

.CSVExportHistory {
  &__table {
    td,
    th {
      vertical-align: middle;
    }
  }

  .Paging {
    display: flex;
    justify-content: space-between;
    margin-top: gap-size(0.5);

    select {
      @include select-styling-base;
      height: 1.5rem;
      padding: 0;
    }
  }
}
