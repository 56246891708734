@import "../../../styles/global";

.FailedToSyncProduct {
  box-shadow: $shadow-standard;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__content {
    flex: 1;
    padding: gap-size();
    display: flex;
    flex-direction: column;
    column-gap: gap-size();

    @include breakpoint-sm-up {
      flex-direction: row;
    }
  }

  &__image {
    flex: 1;

    img {
      max-width: 100%;
    }
  }

  &__product-details {
    flex: 1;

    & > div {
      margin-bottom: gap-size();
    }
  }
}
