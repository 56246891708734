@import "../../../styles/global";
@import "../../../styles/form";

.game-price-floor {
  @include default-form;

  display: flex;
  width: 100%;
  flex-direction: column;

  @include breakpoint-sm-up {
    display: table-row;
  }

  &__rarity {
    color: $color-text-dark;
    width: 15rem;

    label {
      font-weight: $font-weight-bold;
      margin-bottom: unset;
    }
  }

  &__price {
    width: 13rem;
    height: 4.9rem;
  }

  &__spacing {
    padding: 0;
  }

  .input {
    display: flex;

    &__adornment-field {
      padding: 0;
      border: 0px;
      width: 50px;
      &:focus {
        outline: none;
      }
    }
  }

  @include breakpoint-sm-up {
    .input-group {
      margin-bottom: gap-size(0.5);
    }
  }

  .label {
    color: $color-text-dark;
  }
}
