@import "../../../styles/global";

.GlobalPriceSettingsSection {
  &__form {
    display: grid;
    gap: gap-size(0.5) gap-size();

    @include breakpoint-sm-up() {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__submit {
    @include breakpoint-sm-up() {
      grid-column: span 2;
    }
  }
}
