@import "../../../styles/global";

.StoreDetailsSettingsContainer {
  &__form {
    display: grid;
    gap: 0 gap-size(1);
    @include breakpoint-sm-up() {
      grid-template-columns: 1fr 1fr;
    }
    @include breakpoint-md-up() {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  &__field {
    &--large {
      @include breakpoint-md-up() {
        grid-column: span 2;
      }
    }
  }

  &__submit {
    @include breakpoint-sm-up() {
      grid-column: span 2;
    }
    @include breakpoint-md-up() {
      grid-column: span 4;
    }
  }
}
