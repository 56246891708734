.SellBuyToggle {
  position: fixed;
  top: 150px;
  right: 581px;

  @media only screen and (max-width: 1365px) {
    position: absolute;
    top: 20px;
    right: 105px;
  }

  @media only screen and (max-width: 650px) {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
