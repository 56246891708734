@import "../../../styles/global";

.Themes {
  font-size: $font-size-standard;
  max-width: 60rem;

  &__title {
    color: $color-primary-darker;
    font-size: $font-size-huge !important;
    padding: 0;
  }
}
