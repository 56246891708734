@import "../../../styles/global";

.ReadOnlySettingField {
  display: flex;
  flex-direction: column;

  &__input {
    border-color: transparent;
    display: inline-block;
    background: transparent;
    color: $color-text-dark !important;
    font-family: $font-family-secondary;
    font-size: 1em;
    position: relative;
  }
}
