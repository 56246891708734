@import "../../../styles/global";

.OrderDetails {
  &__innerLink {
    color: $color-primary-darker;
    &:hover {
      color: $color-primary-dark;
    }
  }

  &__productImage {
    max-width: 4rem;
    max-height: 5rem;
  }
}
