@import "../../../styles/global";
@import "../../../styles/form";

.MxMerchantTerminalOptions {
  &__optionList {
    display: flex;
    flex-wrap: wrap;
    column-gap: gap-size(2);
  }

  &__providerSelect {
    @include select-styling-base;
  }
}
