@import "../../../styles/global";

.ProductPricingInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__content {
    display: flex;
    flex-direction: column;
    gap: $gap-size;
  }
  &__content-top-row {
    display: flex;
    justify-content: space-between;
    align-self: flex-end;
    width: 100%;
  }
  &__content-bottom-row {
    display: flex;
    flex-direction: column;
  }
}
