@import "../../../styles/global";

.MainMobileMenu {
  right: 4rem;
  position: fixed;
  top: 12px;
  z-index: 1;

  &__checkbox {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    cursor: pointer;
    width: 60px;
    height: 60px;
    opacity: 0;

    &:checked {
      + .MainMobileMenu__hamburger > div {
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg);

        &:before {
          top: 0;
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
        }

        &:after {
          top: 0;
          -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
          opacity: 0;
        }
      }

      ~ .MainMobileMenu__menu {
        pointer-events: auto;
        visibility: visible;

        > div {
          -webkit-transform: scale(1);
          transform: scale(1);
          transition-duration: 0.75s;

          > div {
            opacity: 1;
            transition: opacity 0.4s ease 0.4s;
          }
        }
      }
    }

    &:checked:hover + .MainMobileMenu__hamburger > div {
      -webkit-transform: rotate(225deg);
      transform: rotate(225deg);
    }
  }

  &__hamburger {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 60px;
    height: 60px;
    padding: 0.5em 1em;
    border-radius: 0 0.12em 0.12em 0;
    cursor: pointer;
    transition: box-shadow 0.4s ease;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    > div {
      position: relative;
      flex: none;
      width: 100%;
      height: 4px;
      background: $color-white;
      transition: all 0.4s ease;
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        content: "";
        position: absolute;
        z-index: 1;
        top: -10px;
        left: 0;
        width: 100%;
        height: 4px;
        background: inherit;
        transition: all 0.4s ease;
      }

      &:after {
        content: "";
        position: absolute;
        z-index: 1;
        top: -10px;
        left: 0;
        width: 100%;
        height: 4px;
        background: inherit;
        transition: all 0.4s ease;
        top: 10px;
      }
    }
  }

  &__menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    visibility: hidden;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    outline: 1px solid transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 80px;
    > div {
      width: 300vw;
      height: 300vw;
      color: $color-text-light;
      background: rgba(0, 131, 131, 0.99);
      border-radius: 50%;
      transition: all 0.4s ease;
      flex: none;
      -webkit-transform: scale(0);
      transform: scale(0);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        text-align: center;
        max-width: 100vw;
        max-height: 91vh;
        opacity: 0;
        transition: opacity 0.4s ease;
        overflow-y: auto;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        width: 100%;
        > ul {
          list-style: none;
          padding: 0 1em;
          text-align: left;
          margin: 0;
          display: block;
          & > *:first-child {
            margin-top: gap-size(5);
          }
          > li {
            > a {
              position: relative;
              display: inline;
              cursor: pointer;
              transition: color 0.4s ease;
              color: $color-text-light;

              &:hover {
                color: $color-grey-mid;

                &:after {
                  width: 100%;
                }
              }

              &:after {
                content: "";
                position: absolute;
                z-index: 1;
                bottom: -0.15em;
                left: 0;
                width: 0;
                height: 2px;
                background: $color-grey-mid;
                transition: width 0.4s ease;
              }
            }
          }
        }
      }
    }
  }

  &__footer {
    display: flex;
    padding: 0 gap-size();
    margin: gap-size(2) 0 gap-size(2) gap-size(-2);
    text-align: left;
  }

  &__footer-item {
    background: none;
    border: 0;
    color: $color-text-light;
    font-size: $font-size-mid;
    margin: 0 gap-size();

    > i {
      margin-right: gap-size(0.5);
    }
  }
}
