@import "../../../styles/global";

.Cart {
  &__buyButton {
    @include breakpoint-custom-up(1366px) {
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.4);
      bottom: 36px;
      height: 60px;
      position: fixed;
      right: 20px;
      width: 483px;
    }
  }
}
