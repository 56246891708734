@import "../../../styles/global";
@import "../../../styles/form";

.ThemeSelect {
  margin-top: gap-size(-1);
  margin-bottom: gap-size();

  &__select {
    @include select-styling;
    margin-top: gap-size();
    margin-bottom: 0;
  }
}
