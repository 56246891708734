.ViewIntegrationSettingModal {
  display: grid;
  grid-row-gap: 1em;
  max-width: 50em;
  margin: auto;

  .label {
    font-weight: bold;
    grid-column: 1;
  }

  .value {
    grid-column: 2;
  }
}
