@import "../../../styles/global";

.CatalogComponent {
  margin-top: gap-size(2);
  padding: gap-size();
  max-width: 50rem;
  box-shadow: $shadow-standard;

  &__header {
    margin-bottom: gap-size();
    font-size: $font-size-mid;
    font-weight: $font-weight-semi-bold;
  }

  &__logo {
    max-width: 4rem;
    margin-right: gap-size();
  }

  &__catalogs {
    display: flex;
    flex-wrap: wrap;
    column-gap: gap-size(2);
  }
}
