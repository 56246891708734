@import "../../../styles/global";

.MobileNavItem {
  &__header {
    display: block;
    padding-left: 2px;
    color: $color-text-light;
    font-size: 28px;
    border-bottom: 1px solid $color-text-light;
    margin-bottom: gap-size(0.5);
    margin-top: gap-size();
    font-family: $font-family-secondary;
    text-decoration: none;
    position: relative;

    &:hover {
      color: $color-text-light;
    }

    & .MobileNavItem__chevron {
      bottom: 0.5rem;
      font-size: $font-size-mid;
      position: absolute;
      right: 0;
    }

    & i {
      margin-right: gap-size();
    }
  }

  &__wrapper {
    transition: height ease 0.2s;
    overflow: hidden;
  }

  &__subItem {
    padding-left: 34px;
    font-size: 22px;
    padding-top: 10px;

    &:last-child {
      margin-bottom: gap-size(1);
    }
  }

  &__subItemLink {
    color: $color-text-light;
    font-family: $font-family-secondary;
    border-left: 2px solid $color-text-light;
    padding-left: 11px;

    &:hover {
      color: $color-text-light;
    }
  }
}
