@import "../../../styles/global";

.NewMobileMenu {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 4rem);
  width: min(375px, 100vw);

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  &__hamburguer {
    color: $color-white;
    position: absolute;
    top: gap-size(0.75);
    right: gap-size(0.5);
    width: 4rem;
    height: 4rem;
    padding: gap-size() gap-size() gap-size(0.5);
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover,
    &:focus {
      color: $color-grey-light !important;
    }
  }

  &__topBar {
    background-color: $color-black;
    flex-shrink: 0;
    height: 5.5rem;
    position: relative;
  }

  &__close {
    position: absolute;
    top: gap-size(0.5);
    right: 0;
  }

  &__storeName {
    border-bottom: $border-width-fine solid $color-teal-accent;
    font-size: $font-size-mid;
    font-weight: $font-weight-semi-bold;
    margin-top: gap-size(1.5);
    padding: gap-size(0.5) gap-size() gap-size();
  }

  &__scrollTrack {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto;
  }

  &__menuWrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  &__mainMenu,
  &__secondaryMenu {
    padding: gap-size(0.5) gap-size();
    margin: 0 gap-size(0.5);
  }

  &__secondaryMenu {
    border-top: $border-width-fine solid $color-teal-accent;
    margin-top: gap-size();
  }
}
