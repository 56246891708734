@import "../../../styles/global.scss";

.ShopifySubscriptionAlert {
  &__header {
    display: flex;
    justify-content: flex-end;
  }

  &__title {
    font-size: $font-size-mid !important;
    @include breakpoint-md-up {
      font-size: $font-size-very-huge !important;
    }
  }

  &__content {
    padding: 0 gap-size() gap-size(2) gap-size();
    text-align: center;
  }

  &__dismissButton {
    padding-right: 0;
  }

  &__button {
    margin-bottom: gap-size();
  }
}
