@import "../../../styles//global";

.BuylistItem {
  $root: &;
  margin-top: gap-size();
  position: relative;
  padding-top: 0;

  /* copied from old buylist.scss */
  &__overstock {
    position: absolute;
    z-index: 1;
    transform: rotate(45deg);
    top: -55px;
    left: -55px;
    height: 110px;
    width: 110px;
    background-color: $color-red;
    color: $color-text-light;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    p {
      transform: rotate(-90deg);
      margin: 0 -20px;
    }
  }

  &__removeButton {
    background: none;
    border: 0;
    float: right;
    margin-bottom: gap-size(-1);
    margin-top: gap-size(0.5);
    cursor: pointer;
    color: $color-red-light;
    &:hover {
      color: $color-red;
      transition: all 1s;
    }
  }

  &__details {
    color: $color-grey-dark;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    text-align: center;
  }

  /* copied from old buylist.scss */
  &__image {
    width: 214px;
    height: 361px;
    margin: 0 auto;
    img {
      max-width: 214px;
      max-height: 361px;
      margin: 0 auto;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__variants,
  &__variantsHeading,
  &__totals {
    font-family: "Source Sans Pro", sans-serif;
    color: $color-charcoal;
  }

  &__variantsHeading {
    text-decoration: underline;
  }

  &__variants {
    padding: 0 gap-size();
    display: grid;
    grid-template-columns: 50% 20% 30%;

    &--error {
      #{$root}__quantityInput {
        background-color: rgba($color-red-light, 0.2);
        border-color: $color-red;
        color: $color-text-dark;
      }
    }
  }

  &__quantityInput,
  &__priceInput {
    color: $color-charcoal;
    border: $border-width-fine solid $color-grey;
    font-size: $font-size-standard;
  }

  &__quantityInput {
    width: 3em;
  }

  &__priceInput {
    width: 4em;
  }

  &__totals {
    display: flex;
    justify-content: space-between;
  }
}
