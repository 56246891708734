@import "../../../styles/global";

.ebay-shipping-service-details {
  padding: gap-size();
  margin-top: gap-size(0.25);
  background-color: $color-grey-mid;

  &__title {
    font-weight: $font-weight-bold;
    text-decoration: underline;
  }

  &__details {
    display: grid;
    grid-template-columns: max-content auto;
  }

  &__label {
    margin-right: gap-size(0.5);
    font-weight: $font-weight-bold;
  }

  &__summary {
    margin-top: gap-size(0.5);
    display: grid;
    grid-template-columns: repeat(auto-fill, 12rem);

    & .fa-check-circle::before {
      color: $color-primary-dark;
    }
  }
}
