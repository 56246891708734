@import "../../../styles/global";
@import "../../../styles/form";

.ReleaseTrainSelect {
  &__controls {
    display: flex;
  }

  &__select {
    @include select-styling-base;
    margin-bottom: 0;
    margin-right: gap-size();
  }
}
