@import "../styles/global";
@import "./integrations.scss";
// @import "./reports.scss"; Moved to bottom of this file
@import "./buylist-rules.scss";
@import "./data-table.scss";
@import "./pos-settings.scss";
@import "./buylist.scss";

body {
  background-color: $color-grey-light !important;
  overflow-y: hidden;
  overscroll-behavior-y: contain;
}

.userActions {
  cursor: pointer;
}

.show {
  display: block !important;
}

.centered {
  text-align: center;
}

.align-col-bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media only screen and (max-width: 992px) {
    justify-content: normal;
  }
}

//Scrollbars
::-webkit-scrollbar {
  width: 5px;
}

.inline {
  display: inline-block !important;
}

/* Freshworks Popup */
div#freshworks-frame-wrapper {
  left: unset !important;
  right: 30px !important;
  bottom: 14px !important;
}

.freshwidget-container {
  z-index: 999999 !important;
}
.freshwidget-button {
  display: none !important;
  opacity: 0;
}

#freshwidget-frame {
  height: 90vh !important;
  max-height: 650px;
}

@media only screen and (max-width: 768px) {
  #freshwidget-frame {
    top: 100px !important;
    left: calc(50% - 281px);
  }
}
@media only screen and (max-width: 767px) {
  #freshwidget-frame {
    left: 0;
    top: 100px !important;
    border: 1px solid $color-black;
  }
}

//Toasts
.Toastify__toast {
  border-radius: 5px;

  &--info {
    color: $color-text-light;
    background: $color-primary-darker !important;
    .Toastify__close-button {
      color: $color-text-light;
    }
  }

  &--warning {
    color: $color-secondary !important;
    background: $color-warning-background !important;
    .Toastify__close-button {
      color: $color-secondary;
    }
  }

  &--error {
    color: $color-text-light;
    background: $color-error !important;
    .Toastify__close-button {
      color: $color-text-light;
    }
  }

  &--success {
    color: $color-text-light;
    background: $color-success-background !important;
    .Toastify__close-button {
      color: $color-text-light;
    }
  }
}

.Toastify__toast-container--bottom-left {
  bottom: 3em !important;
}

.has-icons-left input {
  padding-left: 30px !important;
}
span.icon.is-small.is-left.blue {
  position: absolute;
  left: 23px;
  top: 37px;
  color: $color-text-light;
}

//WYSIWYG
.control.editor {
  height: 113px;
  margin-bottom: 51px;
  .ql-toolbar.ql-snow {
    font-family: "Source Sans Pro", sans-serif;
    padding: 3px 8px;
    background: $color-grey-light;
    color: $color-grey-dark !important;
  }
}

/* Track */
::-webkit-scrollbar-track {
  background: $color-grey-light;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $color-primary-darker;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $color-secondary;
}

button:disabled,
button[disabled] {
  cursor: not-allowed;
  background: $color-grey-dark !important;
}

// contextMenus
.react-contextmenu {
  background-color: $color-white;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: $color-text-dark;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;

  &.react-contextmenu--visible {
    opacity: 1;
    pointer-events: auto;
    z-index: 9999;
  }
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: $color-text-dark;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
  &.react-contextmenu-item--active,
  &.react-contextmenu-item--selected {
    color: $color-text-light;
    background-color: $color-primary-darker;
    border-color: $color-primary-darker;
    text-decoration: none;
  }
  &.react-contextmenu-item--disabled {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
    color: $color-grey-dark;
    &:hover {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.15);
      color: $color-grey-dark;
    }
  }
}
.react-contextmenu-item--divider {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  cursor: inherit;
  margin-bottom: 3px;
  padding: 2px 0;
  &:hover {
    background-color: transparent;
    border-color: rgba(0, 0, 0, 0.15);
  }
}
.react-contextmenu-item.react-contextmenu-submenu {
  padding: 0;
  > .react-contextmenu-item:after {
    content: "▶";
    display: inline-block;
    position: absolute;
    right: 7px;
  }
}
.example-multiple-targets::after {
  content: attr(data-count);
  display: block;
}

///////////// Generic Styling/////////////////
//checkboxes
.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  line-height: normal;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  em {
    font-style: normal;
    padding-left: 15px;
    color: $color-secondary;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark {
      background-color: $color-grey-dark;
      border: none;
    }
    &:checked ~ .checkmark:after {
      opacity: 1;
    }
  }
  .checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid $color-grey-mid;
    border-radius: 5px;

    &:after {
      content: "";
      position: absolute;
      opacity: 0;
      left: 9px;
      top: 7px;
      width: 5px;
      height: 10px;
      border: solid $color-white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  &:hover input ~ .checkmark {
    border: 3px solid $color-grey-mid;
    &:after {
      content: "";
      opacity: 1;
      left: 7px;
      top: 5px;
      width: 5px;
      height: 10px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  & input:focus ~ .checkmark {
    outline: auto 2px Highlight;
    outline: auto 2px -webkit-focus-ring-color;
  }
}

h4 {
  letter-spacing: 0;
  color: $color-charcoal;
  font-size: 13px !important;
  margin-bottom: 0;
  font-family: "Source Sans Pro", sans-serif;
  line-height: 0px !important;
}
h3 {
  font-family: "Source Sans Pro", sans-serif;
  text-align: left;
  letter-spacing: 0;
  color: $color-charcoal;
  font-size: 18px !important;
}
h2 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 28px !important;
  letter-spacing: 0;
  color: $color-secondary;
  padding-bottom: 21px;
  padding-top: 15px;
}
h1 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 32px !important;
  letter-spacing: 0;
  color: $color-secondary;
  padding-bottom: 21px;
  padding-top: 15px;
}
.btn-primary {
  background: $color-primary-darker;
  border-color: $color-primary-darker;
  &:not(:disabled):not(.disabled):active,
  &:hover {
    background: $color-primary-dark;
    border-color: $color-primary-dark;
  }
}
.btn1 {
  background: $color-primary-darker;
  color: $color-text-light;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  width: 100%;
  display: block;
  text-align: center;
  border: none;
  border-radius: 5px;
  height: 26px;
  line-height: 26px;
  cursor: pointer;
  transition: all 1s;
  i {
    padding-left: 2px;
  }
  &.active,
  &:hover {
    background: $color-primary-dark;
    color: $color-text-light;
    transition: all 1s;
    text-decoration: none;
  }
  &.decline {
    margin-right: 15px;
    background: $color-red-dark;
    &:hover {
      background: $color-red;
    }
  }
  &.alternative {
    display: inline-block;
    color: $color-text-dark;
    margin-bottom: 0 !important;
    background: $color-orange;
    &:hover {
      background: #bb8e39;
    }
  }
  &.cancel {
    margin-right: 15px;
    background: $color-grey-dark;
    &:hover {
      background: $color-grey-inter;
    }
  }
}

.plainButton {
  border: none;
  background: unset;
  color: $color-primary-darker;
  &:hover {
    color: $color-primary-dark;
    transition: all 0.5s;
  }
}

button.buttonWide,
.button.buttonWide {
  background: $color-primary-darker;
  color: $color-text-light;
  height: 60px;
  display: block;
  line-height: 60px;
  text-align: center;
  margin: 35px 0px;
  i {
    padding-right: 5px;
  }
  &.active,
  &:hover {
    background: $color-primary-dark;
    transition: all 1s;
    text-decoration: none;
  }
  &.decline {
    background: $color-red-dark;
    &:hover {
      background: $color-red;
    }
  }
}

.btn2 {
  background: $color-charcoal;
  color: $color-text-light;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 15px;
  width: 130px;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 22px;
  text-align: center;
  border-radius: 5px;
  height: 26px;
  line-height: 26px;
  cursor: pointer;
  transition: all 1s;
  position: relative;
  top: 4px;
  i {
    padding-left: 2px;
  }
  &.active {
    background: $color-primary-darker;
  }
  &:hover {
    background: $color-primary-dark;
    transition: all 1s;
    color: $color-text-light;
    text-decoration: none;
  }
}

.text-bold {
  font-weight: 600;
}
.hidden,
.hideItem {
  display: none !important;
}

.watermark {
  position: fixed;
  bottom: -302px;
  right: -182px;
  z-index: -1;
  pointer-events: none;
}

.watermarkPos1 {
  position: fixed;
  bottom: -270px;
  right: -182px;
  z-index: -1;
  pointer-events: none;
}

.watermarkPos2 {
  position: fixed;
  top: -382px;
  left: -661px;
  z-index: -1;
  transform: rotate(211deg);
  pointer-events: none;
  img {
    width: 200%;
  }
}

//Custom File Uploader
label.file-upload {
  background: $color-primary-darker;
  color: $color-text-light;
  box-shadow: 0px 2px 6px #0000000a;
  border-radius: 5px;
  position: absolute;
  z-index: 9;
  margin-top: 3px;
  transition: all 1s;
  cursor: pointer;
  &.active,
  &:hover {
    background: $color-primary-dark;
    transition: all 1s;
    color: $color-text-light;
    text-decoration: none;
  }
  i {
    padding: 0 10px;
  }
}
input[type="file"] {
  height: 29px;
  padding-left: 54px;
  &:before {
    content: "";
    background: $color-background;
    position: absolute;
    width: 90px;
    height: 31px;
  }
  &:focus {
    outline: none;
  }
}
///

//modal
.modal {
  align-items: center;
  &.is-active,
  &.display-block {
    background: rgba(10, 22, 46, 0.8);
    z-index: 999999;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  form {
    margin-bottom: 0;
  }
  .modal-card {
    border-top: 8px solid $color-primary-darker;
    border-bottom: 8px solid $color-primary-darker;
    background: url(../assets/img/modalWatermark.png), rgba(236, 245, 245, 0.9);
    margin: 0 auto;
    position: relative;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 42%;
    width: 50vw;
    max-width: 700px;
    max-height: 87vh;
    padding: 45px;
    .modal-card-head {
      .modal-card-title {
        color: $color-secondary;
        font-size: 28px !important;
        padding-top: 0;
        padding-bottom: 1rem;
        &--desktop {
          @media screen and (max-width: 651px) {
            display: none;
          }
        }
        &--mobile {
          @media screen and (min-width: 650px) {
            display: none;
          }
        }
      }
      button.delete {
        align-items: center;
        display: flex;
        height: 25px;
        position: absolute;
        top: 52px;
        right: 45px;
        background: none;
        border: none;
        color: $color-text-dark;
        font-size: 15px;
        &.active,
        &:hover {
          color: $color-primary-dark;
          transition: all 1s;
        }
        i {
          font-size: 25px;
          position: absolute;
          left: -15px;
        }
        i.fal.fa-door-open {
          left: -30px;
        }
      }
    }
    .modal-card-body {
      max-height: 65vh;
      overflow-y: auto;
      background: $color-background;
      box-shadow: 0px 2px 10px #00000026;
      padding: 40px 30px;
      color: $color-charcoal;
      font-family: "Source Sans Pro", sans-serif;
      .additionalInput {
        float: right;
      }
      /* Below is for QuickLinkEditMenu */
      &.color {
        min-height: 358px;
        p {
          color: $color-charcoal;
          font-family: "Source Sans Pro", sans-serif;
          font-weight: 600;
        }
        label {
          display: block;
        }
        input[type="color"] {
          margin: 0 0.5rem;
        }
        input[type="text"] {
          border: 1px solid $color-grey-mid;
          border-radius: 5px;
          color: $color-charcoal;
          font-family: "Source Sans Pro", sans-serif;
          margin: 0 0.5rem;
          padding-left: 15px;
          width: 300px;
          // &:focus {
          //   outline: none;
          // }
        }
      }
      /* Unused? */
      .sketch-picker {
        position: absolute;
        right: 75px;
        top: 131px;
      }

      .refresh {
        border: 0;
        position: absolute;
        top: 103px;
        right: 87px;
        background: $color-primary-darker;
        color: $color-text-light;
        width: 96px;
        height: 64px;
        text-align: center;
        line-height: 88px;
        cursor: pointer;
        &.active,
        &:hover {
          background: $color-primary-dark;
          transition: all 1s;
        }
      }
      p {
        color: $color-charcoal;
        font-family: "Source Sans Pro", sans-serif;
      }
      pre {
        color: $color-charcoal;
        font-family: "Source Sans Pro", sans-serif;
        text-align: center;
      }
      .wrapper {
        padding: 8px;
        overflow-x: hidden;
        &.reviewInputs {
          .checkbox {
            font-size: 19px;
            .checkmark {
              top: 2px;
            }
          }
          textarea {
            width: 100%;
            height: 106px;
            border: 1px solid $color-primary-darker;
            resize: none;
            border-radius: 5px;
            padding: 5px;
          }
          textarea#approveReason {
            margin-bottom: 10px;
          }
          /* Unused? */
          .conditionListing {
            line-height: 48px;
          }
          table {
            span {
              display: block;
              margin-bottom: 6px;
              &:last-child {
                margin-bottom: 0px;
              }
            }
          }
          .btn1 {
            position: relative;
            top: 11px;
          }
          p.subHeading {
            color: $color-grey-inter;
            font-size: 13px;
            margin: 0;
            margin-bottom: 10px;
          }
          input[type="text"],
          input[type="number"] {
            border: 1px solid $color-grey-mid;
            border-radius: 5px;
            font-family: "Source Sans Pro", sans-serif;
            width: 100%;
            border: 1px solid $color-grey-mid;
            color: $color-charcoal;
            padding-left: 15px;
            // &:focus {
            //   outline: none;
            // }
          }
          select {
            background: $color-primary-darker 0% 0% no-repeat padding-box;
            box-shadow: 0px 2px 6px #0000000a;
            border-radius: 5px;
            border: 1px solid $color-primary-darker;
            color: $color-text-light;
            font-family: "Source Sans Pro", sans-serif;
            font-size: 15px;
            text-align: center;
            text-align-last: center;
            width: 100%;
            max-width: 206px;
            height: 26px;
            margin-bottom: 15px;
            display: block;
            cursor: pointer;
            background-position: right;
            padding: 2px 20px;
            line-height: 18px;
            option {
              text-align: center;
              text-align-last: center;
            }
            &.active,
            &:hover {
              background: $color-primary-dark;
              transition: all 1s;
              color: $color-text-light;
              text-decoration: none;
            }
          }
        }
        label.label {
          color: $color-charcoal;
          display: block;
          margin-bottom: 4px;
        }
        .variant {
          margin-bottom: 18px;
        }
        table {
          margin-bottom: 0;
          .info {
            text-align: center;
          }
          thead {
            border-top: 2px solid $color-primary-darker;
            tr {
              line-height: 15px;
              text-align: center;
            }
          }
          tbody {
            tr {
              line-height: 12px;
            }
          }
        }
      }
      .title {
        letter-spacing: 0;
        color: $color-charcoal;
        font-size: 15px;
        font-weight: 600;
        font-family: "Source Sans Pro", sans-serif;
      }
      .desc {
        letter-spacing: 0;
        color: $color-grey-inter;
        font-size: 15px;
        font-family: "Source Sans Pro", sans-serif;
      }
    }
    .modal-card-foot {
      a.modalBtn {
        background: $color-primary-darker;
        width: 100% !important;
        display: block;
        text-align: center;
        line-height: 60px;
      }
      button.modalBtn,
      a.modalBtn {
        height: 60px;
        width: 50%;
        border: none;
        color: $color-text-light;
        font-size: 15px;
        font-family: "Source Sans Pro", sans-serif;
        text-transform: uppercase;
        transition: all 0.5s;
        i {
          font-size: 22px;
          position: relative;
          top: 3px;
          padding: 0 5px;
        }
        &.cancel {
          background: $color-secondary;
          color: $color-text-light;
          &.active,
          &:hover {
            background: $color-grey-dark;
            text-decoration: none;
          }
        }
        &.action {
          background: $color-primary-darker;
          &.active,
          &:hover {
            background: $color-primary-dark;
            color: $color-text-light;
            text-decoration: none;
          }
        }
        &.secondary-action {
          background: none;
          color: $color-primary-darker;
          display: block;
          margin: 0 auto;
          width: auto;
          &.active,
          &:hover {
            color: $color-primary-dark;
          }
        }
        &.caution {
          background: $color-red;
          &.active,
          &:hover {
            background: $color-red-dark;
            color: $color-text-light;
            text-decoration: none;
          }
        }
        &.full {
          width: 100%;
        }
      }
    }
    &.modal-card-large {
      width: 80vw;
      max-width: 1500px;
      max-height: 87vh;
      padding: 45px;
    }

    &:before {
      content: "";
      background: $color-primary-darker;
      position: absolute;
      width: 164px;
      height: 29px;
      top: -36px;
      left: 0px;
    }
    &:after {
      content: "";
      position: absolute;
      border-top: 29px solid transparent;
      border-bottom: 0px solid transparent;
      border-left: 69px solid $color-primary-darker;
      top: -36px;
      left: 164px;
    }
  }
}

//tables
table {
  width: 100%;
  background: $color-background;
  box-shadow: 0px 2px 6px #00000059;
  tr {
    line-height: 62px;
    font-family: "Source Sans Pro", sans-serif;
    color: $color-charcoal;
    font-size: 15px;
    border-bottom: 1px solid $color-grey-mid;
    border-left: 3px solid $color-primary-darker;
  }
  td {
    &.actions a {
      color: $color-grey-inter;
      padding: 0 3px;
    }
    &.blue {
      border-left: 3px solid $color-purple;
      padding-right: 29px;
    }
    &.red {
      border-left: 3px solid $color-pink-light;
      padding-right: 29px;
    }
  }
  thead {
    background: $color-primary-darker;
    height: 38px;
    line-height: 38px;
    a.tableLink {
      letter-spacing: 0;
      color: $color-text-light;
      font-weight: 600;
      font-family: "Source Sans Pro", sans-serif;
      text-align: right;
      width: 100%;
      display: block;
      padding-right: 23px;
      text-decoration: none;
      &.active,
      &:focus,
      &:hover {
        color: $color-text-light;
        transition: all 1s;
        text-decoration: none;
      }
      i {
        padding-left: 5px;
        font-size: 20px;
        position: relative;
        top: 2px;
      }
    }
    tr {
      line-height: 38px;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 15px;
      font-weight: 600;
      color: $color-text-light !important;
    }
  }
}
.bottom-pagination {
  top: 19px;
  position: relative;
  padding-bottom: 22px;
  button.paging-nav {
    background: $color-charcoal;
    border: none;
    height: 26px;
    line-height: 26px;
    width: 26px;
    margin: 0 2px;
    color: $color-text-light;
    border-radius: 5px;
  }
}
//Pagination
.top-pagination {
  top: 19px;
  float: right;
  position: relative;
  button.paging-nav {
    background: $color-charcoal;
    border: none;
    height: 26px;
    line-height: 26px;
    width: 26px;
    margin: 0 2px;
    color: $color-text-light;
    border-radius: 5px;
    &.large {
      width: 75px;
      font-size: 15px;
    }
  }
}

.brand {
  padding-left: 20px;
  padding-top: 16px;
  width: 202px;
  position: relative;
  z-index: 10000;
  pointer-events: none;
  img {
    width: 100%;
  }
}

.topCnrBg {
  position: absolute;
  z-index: 10000;
  background: $color-background;
  width: 504px;
  height: 155px;
  box-shadow: 1px 1px 5px $color-teal-accent;
  transform: rotate(-18deg);
  left: -34px;
  top: -74px;
  pointer-events: none;
}

.company {
  position: absolute;
  transform: rotate(-18deg);
  top: 62px;
  left: 72px;
  width: 319px;
  text-align: center;
  font-family: "Source Sans Pro", sans-serif;
  color: $color-primary-darker;
  z-index: 10000;
  font-size: 13px;
  pointer-events: none;
}
// App Sidebar
.app-sidebar {
  width: 244px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9998;
  .POSangle {
    position: fixed;
    z-index: 3;
    background: rgba(255, 255, 255, 0.7);
    width: 504px;
    height: 155px;
    transform: rotate(45deg);
    left: -271px;
    bottom: 0;
    z-index: 1;
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
  }
  .topCnrBgLight {
    border-top: 0px solid transparent;
    border-bottom: 135px solid transparent;
    border-left: 1000px solid $color-primary;
    position: absolute;
    left: 25px;
    display: block;
    top: -7px;
    z-index: 2;
  }
  .leftBgBlur {
    background: $color-grey-light 0% 0% no-repeat padding-box;
    opacity: 0.95;
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
    height: 3000px;
    position: absolute;
    right: 267px;
    top: 10px;
    width: 1000px;
    z-index: -3;
    transform: rotate(9deg);
  }
  .leftBgAngle {
    background: $color-primary;
    height: 3000px;
    position: absolute;
    right: -94px;
    top: 151px;
    width: 1000px;
    z-index: -4;
    transform: rotate(-12deg);
  }
}

// App TopNav
nav#main-nav {
  height: 5.5rem;
  left: 0;
  top: 0;
  width: 100vw;
  background: url(../assets/img/watermarkMenu.png), $color-secondary;
  z-index: 999;
  display: block;
  position: unset;
  background-repeat: no-repeat;
  background-position-x: 202;
  background-position: right;
  position: absolute;
  &.mobile {
    z-index: 9999;
  }
  .userSettings {
    position: fixed;
    top: 55px;
    right: 108px;
    width: 184px;
    color: $color-primary;
    opacity: 1;
    transition: all 1s;
    height: 62px;
    .menu {
      position: absolute;
      right: 17px;
      color: $color-text-light;
      span {
        cursor: pointer;
        font-family: "Source Sans Pro", sans-serif;
        i {
          padding-right: 4px;
        }
      }
    }
    &.hidden {
      opacity: 1;
      z-index: 1;
      transition: all 1s;
      right: -590px;
    }
  }
  .pull-right {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    height: 5.5rem;
    a.settingLink {
      position: absolute;
      right: 15px;
      top: 50%;
      z-index: 9;
      color: $color-text-light;
      font-size: 23px;
      text-shadow: 1px 0px 0px $color-primary;
      transform: translateY(-118%);
    }
    .avatar {
      width: 42px;
      height: 42px;
      border-radius: 39px;
      overflow: hidden;
      border: 2px solid $color-primary;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 57px;
      img {
        width: 100%;
      }
    }
    .user {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 112px;
      color: $color-primary;
      font-size: 13px;
      text-align: right;
      width: 260px;
      user-select: none;
      .customer {
        .fa-user-headset,
        .fa-cogs,
        .fa-sign-out-alt {
          margin-right: 13px;
          color: $color-text-light;
          font-size: 16px;
        }
        p,
        .supportlink {
          display: inline-block;
          margin: 0;
          cursor: pointer;
        }
        i {
          padding-left: 8px;
          display: inline-block;
        }
      }
    }
  }
}

// App Body
main#binderpos-app {
  position: fixed;
  left: 180px;
  top: 5.5rem;
  width: calc(100vw - 180px);
  height: calc(100vh - 84px);
  z-index: 9998;
  overflow-x: hidden;
  overflow-y: auto;
  &.hasSubMenu {
    left: 368px;
    width: calc(100vw - 368px);
  }
  &:before {
    content: "";
    background: $color-secondary;
    position: absolute;
    width: 164px;
    height: 29px;
    top: 46px;
    left: 22px;
  }
  &:after {
    content: "";
    position: absolute;
    border-top: 29px solid transparent;
    border-bottom: 0px solid transparent;
    border-left: 69px solid $color-secondary;
    top: 46px;
    left: 186px;
  }
  .app-content {
    margin-right: 22px;
    border-top: 5px solid $color-secondary;
    border-bottom: 5px solid $color-secondary;
    background: rgba(255, 255, 255, 0.7) 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    margin-left: 22px;
    margin-top: 75px;
    margin-bottom: 22px;
    section.section {
      padding: 11px 38px;
      .app-header {
        .btn1 {
          width: max-content;
          padding: 0 22px;
          min-width: 160px;
          float: right;
          position: relative;
          top: 4px;
          &.mobile {
            width: max-content;
            padding: 0 14px;
            min-width: unset;
            float: right;
            position: relative;
            top: 4px;
            i {
              line-height: 28px;
            }
          }
        }
        &.with-buttons {
          padding-top: 15px;
          padding-bottom: 21px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          h2 {
            padding-top: unset;
            padding-bottom: unset;
          }
          .btn1 {
            float: unset;
            top: unset;
            display: inline-block;
            margin-left: unset;
            margin-right: unset;
          }
          .buttons {
            padding-left: 15px;
            button {
              margin-bottom: 0.3em;
            }
            .btn1.cancel,
            .btn1.decline {
              margin-right: unset;
            }
          }
          .spaced-buttons {
            & > * {
              margin-left: 1rem;
            }
          }
          @media only screen and (max-width: 1199px) {
            padding-right: 20px;
          }
        }
      }
      .search {
        float: right;
        position: relative;
        top: 17px;
        margin-right: 21px;
        margin-left: 21px;
        i {
          color: $color-primary;
          position: absolute;
          left: 11px;
          margin-top: 9px;
        }
        input#buylistSearch {
          border: 1px solid $color-primary;
          border-radius: 14px;
          padding: 5px 0px 5px 36px;
          font-size: 13px;
          color: $color-charcoal;
          font-family: "Source Sans Pro", sans-serif;
          // &:focus {
          //   outline: none;
          // }
        }
      }
    }
  }
}

img.failedImg {
  width: 100%;
}

//Tiled Flex Panels
.tiledPanels {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.panelWrapper {
  max-width: 386px;
  display: inline-flex;
  overflow: hidden;
  margin: 15px;
  box-shadow: 0px 2px 10px #00000026;
  button.failedEdit {
    border: 0;
    display: inline-block;
    width: 50%;
    background: $color-primary-darker;
    color: $color-text-light;
    text-align: center;
    height: 39px;
    line-height: 39px;
    margin-top: 10px;
    cursor: pointer;
    &.active,
    &:hover {
      background: $color-primary-dark;
      transition: all 1s;
      color: $color-text-light;
      text-decoration: none;
    }
  }
  button.failRemove {
    border: 0;
    display: inline-block;
    width: 50%;
    background: $color-secondary;
    color: $color-text-light;
    text-align: center;
    height: 39px;
    line-height: 39px;
    margin-top: 10px;
    cursor: pointer;
  }
  .cardInfo {
    span {
      display: block;
      em {
        font-style: normal;
        display: inline-block;
      }
    }
  }
  span.childPaused {
    text-align: center;
    width: 100%;
    height: 125px;
    color: $color-grey;
    cursor: pointer;
  }
  .panel {
    p.participants {
      letter-spacing: 0;
      color: $color-grey-dark;
      text-transform: uppercase;
      font-size: 13px;
      font-family: "Source Sans Pro", sans-serif;
      text-align: center;
      margin-bottom: 4px;
      height: 22px;
      line-height: 22px;
      a {
        color: $color-primary-darker;
        cursor: pointer;
        text-decoration: none;
        font-weight: 600;
        &.active,
        &:hover {
          color: $color-primary-dark;
          transition: all 0.5s;
        }
      }
      em {
        font-style: normal;
      }
    }
    button.editBtn,
    a.editBtn {
      background: $color-primary-darker;
      border: 0;
      height: 60px;
      display: block;
      color: $color-text-light;
      cursor: pointer;
      text-align: center;
      line-height: 60px;
      text-decoration: none;
      text-transform: uppercase;
      font-family: "Source Sans Pro", sans-serif;
      width: 100%;
      &.half {
        width: 50%;
        display: inline-block !important;
        cursor: pointer;
      }
      &.active,
      &:hover {
        background: $color-primary-dark;
        transition: all 1s;
      }
    }
    p.types {
      letter-spacing: 0;
      color: $color-text-dark;
      text-transform: uppercase;
      font-size: 15px;
      font-family: "Source Sans Pro", sans-serif;
      em {
        display: block;
        font-style: normal;
        text-decoration: unset;
        color: $color-grey-dark;
      }
    }
    p.info {
      color: $color-primary-darker;
      font-family: "Source Sans Pro", sans-serif;
      a,
      button {
        background: none;
        border: 0;
        color: $color-primary-darker;
        padding: 0;
        text-decoration: none;
        cursor: pointer;
        &.active,
        &:hover {
          color: $color-primary-dark !important;
          transition: all 1s;
        }
      }
      i {
        color: $color-grey-dark;
        padding-right: 8px;
      }
    }
  }
}

//Cart Reviews
// TODO remove when CartView is modernised
table.cartViewTenders {
  margin-top: 24px;
  margin-bottom: 24px;
}
.cartViewTop {
  .avatar {
    width: 42px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    border-radius: 25px;
    color: $color-text-light;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 22px;
    display: inline-block;
    font-weight: 300;
    text-transform: uppercase;
    background: $color-pink-light;
  }
}
.cartviewBottom {
  margin-bottom: 20px;
  img.cart-view-image {
    max-width: 53px;
    max-height: 79px;
    margin-left: 19px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
  table tr {
    line-height: 28px;
  }
  .cartViewTotals {
    .cartViewTitle {
      color: $color-charcoal;
      em {
        font-style: normal;
        font-weight: 600;
        float: right;
        padding-right: 15px;
      }
    }
  }
}

//Google Address
.pac-container {
  background: $color-background;
  position: relative;
  z-index: 9999999999;
  .pac-icon {
    color: $color-primary !important;
  }
  .pac-item-selected {
    background: $color-primary;
  }
  .pac-item {
    color: $color-charcoal;
    cursor: pointer;
    &:hover {
      background: $color-primary;
    }
  }
}

//Customers & Users

.buylistDetailsPanels {
  p.reason {
    display: inline-block;
    padding-left: 16px;
    margin: 0;
  }
  .storeCredit {
    border-left: 2px solid $color-primary;
    margin-bottom: 23px;
    tr {
      border-left: none;
      line-height: 16px;
    }
  }
  input#adjustPrice {
    border: 1px solid $color-grey-mid;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 5px;
  }
  textarea {
    border: 1px solid $color-grey-mid;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 5px;
  }
  select {
    background: $color-primary-darker 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 5px;
    border: 1px solid $color-primary-darker;
    color: $color-text-light;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 15px;
    text-align: center;
    text-align-last: center;
    width: 100%;
    max-width: 206px;
    height: 26px;
    margin-bottom: 15px;
    display: block;
    cursor: pointer;
    background-position: right;
    padding: 2px 20px;
    line-height: 18px;
    option {
      text-align: center;
      text-align-last: center;
    }
    &.active,
    &:hover {
      background: $color-primary-dark;
      transition: all 1s;
      color: $color-text-light;
      text-decoration: none;
    }
  }
  input#customerSearch {
    border: 1px solid $color-primary;
    border-radius: 14px;
    padding: 5px 0px 5px 36px;
    font-size: 13px;
    color: $color-charcoal;
    font-family: "Source Sans Pro", sans-serif;
    width: 268px;
    &:focus {
      outline: none;
    }
  }
  a.btn1 {
    margin: 0 7px;
    color: $color-text-light !important;
    i {
      padding-right: 5px;
    }
  }
}

.buylistRules {
  margin-bottom: 30px;
  .userActions {
    color: $color-primary-darker !important;
    font-size: 18px;
    margin: 0 9px;
    cursor: pointer;
    &.active,
    &:hover {
      color: $color-primary-dark !important;
      transition: all 1s;
    }
  }
  .checkbox {
    margin-top: -16px;
    margin-left: 14px;
    padding-left: 19px;
  }
  .multipleSelect {
    background: none;
    border: 0;
    color: $color-text-light !important;
    cursor: pointer;
    text-align: left;
    width: 100%;
    .checkbox {
      margin-top: -12px;
    }
    &:hover,
    &:focus,
    &.active {
      color: $color-secondary !important;
      transition: all 1s;
      text-decoration: none;
    }
    span.text {
      position: relative;
      left: 61px;
      i {
        padding-left: 5px;
      }
    }
  }
  .deleteButton {
    padding-right: 0;
  }
}

.table-controls {
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-left: 1em;
  .paging {
    text-align: right;
  }
}

//pagination
.paging-nav-body {
  text-align: right;
  button {
    background: $color-charcoal;
    box-shadow: 0px 2px 6px #0000000a;
    border-radius: 5px;
    border: none;
    color: $color-text-light;
    font-size: 15px;
    font-family: "Source Sans Pro", sans-serif;
    margin: 0 5px;
    padding: 0 20px;
  }
  .page-button {
    background-color: $color-primary-darker;

    &:hover {
      background-color: $color-primary-dark;
    }
  }
}

//Till Screen
.modal.till {
  input[type="text"] {
    border: 1px solid $color-grey-mid;
    border-radius: 5px;
    font-family: "Source Sans Pro", sans-serif;
    width: 100%;
    border: 1px solid $color-grey-mid;
    color: $color-charcoal;
    padding-left: 15px;
    right: 314px;
    margin-bottom: 15px;
    // &:focus {
    //   outline: none;
    // }
  }
  textarea#tillDescription {
    border: 1px solid $color-grey-mid;
    border-radius: 5px;
    font-family: "Source Sans Pro", sans-serif;
    width: 100%;
    height: 150px;
    color: $color-charcoal;
    padding-left: 15px;
    right: 314px;
    margin-bottom: 15px;
    // &:focus {
    //   outline: none;
    // }
  }
}
p.tillInfo {
  color: $color-grey-dark;
  margin-top: -24px;
}

//Products
#inventoryManagement {
  .resultsPanel {
    .col-xl-1,
    .col-xl-2 {
      padding-right: 3px;
      padding-left: 3px;
      // max-height: 58px;
    }
    .form-control {
      padding: 0;
      text-align: center;
      margin: 10px 0px;
      color: $color-grey-dark;
    }
    .form-control::-webkit-outer-spin-button,
    .form-control::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
    .lineItem {
      box-shadow: 0px 2px 10px #00000026;
      width: 100%;
      margin-bottom: 22px;
      .FilterVariantsSelect {
        float: right;
        background-color: $color-background;
        border: 1px solid $color-primary-dark;
        border-radius: 7px;
        padding: 0 1rem;
        min-width: 10rem;
        &:hover,
        &:focus,
        &:active {
          outline-color: $color-primary;
        }
        & > i {
          color: $color-primary-dark;
        }
      }
      .variantsInfo {
        padding: 23px;
        .variantLine {
          border-bottom: 1px solid $color-grey;
          margin-bottom: 12px;
          .row-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
          }
          .price {
            line-height: 58px;
            color: $color-grey-dark;
          }
          .input-group {
            width: 7em;
          }
          .currency-input {
            > input {
              width: 4.5em;
              margin-left: 10px;
              margin-right: 10px;
            }
          }
          .percentage-input {
            > input {
              width: 2.5em;
            }
          }
          .quantity-input {
            > input {
              width: 3.5em;
              margin-left: 10px;
              margin-right: 10px;
            }
          }
          .variantTitle {
            height: max-content;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
            color: $color-charcoal;
            font-size: 15px;
            font-family: "Source Sans Pro", sans-serif;
            @media only screen and (max-width: 1199px) {
              padding-left: 1em;
              padding-bottom: 0.2em;
            }
          }
          .grouping {
            display: flex;
            flex-direction: row;
          }
          .variantInfo {
            width: 2.5em;
            .fas {
              top: 1em;
              display: inline-block;
              padding: 0.4em;
              margin-top: 0.6em;
              margin-left: 2px;
              font-size: 1.2em;
              color: $color-primary;
              width: 1.2em;
              &:hover {
                color: $color-text-dark;
                cursor: pointer;
              }
            }
          }
          .text-small {
            .currency-input {
              width: 4.5em;
              margin-left: 10px;
            }
            .percentage-input {
              width: 2.5em;
              margin-right: 10px;
            }
            input {
              width: calc(50% - 14px);
              display: inline-block;
            }
            span {
              display: inline-block;
              margin: 0 6px;
              color: $color-grey-dark;
            }
          }
          .actions {
            display: flex;
            align-items: baseline;
            margin-bottom: gap-size(0.5);
          }
        }

        .variant-input-header {
          background: $color-primary-darker;
          color: $color-text-light;
          font-family: "Source Sans Pro", sans-serif;
          font-size: 13px;
          text-align: center;
          margin: 0 auto;
          width: 100%;
          @media only screen and (min-width: 1750px) {
            display: none;
          }
        }

        .variantSelectTitles {
          background: $color-primary-darker;
          color: $color-text-light;
          font-family: "Source Sans Pro", sans-serif;
          font-size: 13px;
          line-height: 30px;
          height: 30px;
          padding-left: 15px;
          @media only screen and (min-width: 1750px) {
            margin-left: -12px;
          }
          @media only screen and (max-width: 1749px) {
            display: none;
          }
          .input-group {
            width: 8.4em;
          }
          .variantInfo {
            width: 2em;
          }
          .currency-input {
            width: 4.6em;
          }
          .quantity-input {
            width: 5.4em;
          }
          .multi-input {
            width: 12.5em;
          }
          .configurable-fields div {
            display: inline-block;
            text-align: center;
          }
          span {
            margin: auto;
          }
        }
      }
      .productHeader {
        background: $color-grey-light;
        padding: 15px 0;
        .imgWrapper {
          width: 50px;
          height: 69px;
          display: inline-block;
          img {
            max-width: 50px;
            max-height: 69px;
            margin: 0 auto;
            position: relative;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        .productTitle {
          display: inline-block;
          width: calc(100% - 164px);
          vertical-align: top;
          padding-left: 10px;
          color: $color-charcoal;
          span {
            display: block;
          }
        }
        .productData {
          text-align: right;
          color: $color-secondary;
          font-size: 28px;
        }
      }
    }
  }
  button.batchUpdate {
    background: $color-primary-darker;
    height: 66px;
    width: 296px;
    margin-top: 25px;
    margin-bottom: 17px;
    &.active,
    &:hover {
      background: $color-primary-dark;
      transition: all 1s;
      color: $color-text-light;
      text-decoration: none;
    }
  }
  .sort {
    width: 100%;
    .sortWrapper {
      width: calc(100% - 64px);
      display: inline-block;
      margin-left: 8px;
      text-align: center;
      div {
        background: $color-primary;
        color: $color-text-light;
        border-radius: 5px;
        text-align: center;
      }
    }
    label.sort {
      letter-spacing: 0;
      color: $color-charcoal;
      display: inline-block;
      width: max-content;
    }
  }
  .searchCount {
    color: $color-primary;
    text-transform: uppercase;
  }

  .filtersWrapper3 {
    background: $color-grey-light;
    margin: 19px;
    margin-top: 0;
    .applyFilters {
      padding: 0;
      button {
        height: 66px;
        background: $color-primary-darker;
        width: 100%;
        border: none;
        color: $color-text-light;
        text-transform: uppercase;
        &.active,
        &:hover {
          background: $color-primary-dark;
          transition: all 1s;
          color: $color-text-light;
          text-decoration: none;
        }
      }
    }
    label.label {
      line-height: 17px;
      color: $color-charcoal;
      font-family: "Source Sans Pro", sans-serif;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
    .select2 {
      margin-left: 15px;
      border: 2px solid $color-primary-dark;
      border-radius: 44px;
      color: $color-charcoal;
      font-size: 13px;
      margin-top: 12px;
      .icon {
        position: absolute;
        color: $color-primary-dark;
        top: 27px;
        margin-left: 15px;
      }
      .css-yk16xz-control {
        border-radius: 44px;
        padding-left: 35px;
        &:focus {
          outline: none;
        }
      }
    }
  }
  #savedFilters {
    box-shadow: 0px 2px 10px #00000026;
    padding: 24px;
    .savedFilters {
      background: $color-background 0% 0% no-repeat padding-box;
      box-shadow: 0px 2px 10px #00000026;
      max-height: 536px;
      overflow: auto;
      overflow-x: hidden;
      margin-bottom: 17px;
      .savedActions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        i.fal.fa-trash {
          color: #c37979;
          cursor: pointer;
        }
      }
      .savedName {
        letter-spacing: 0;
        color: $color-charcoal;
        line-height: 30px;
        font-family: "Source Sans Pro", sans-serif;
        padding-left: 30px;
      }
      .savedQueries {
        color: $color-grey-dark;
        line-height: 30px;
        font-family: "Source Sans Pro", sans-serif;
      }
      .filterBar {
        background: $color-primary-darker;
        height: 38px;
        line-height: 38px;
        color: $color-text-light;
        padding: 0 15px;
        font-family: "Source Sans Pro", sans-serif;
        font-weight: 600;
        margin-bottom: 11px;
      }
    }
  }
  #modifyFilters {
    box-shadow: 0px 2px 10px #00000026;
    padding: 19px;
    margin-bottom: 17px;
    .modifyFilters {
      background: $color-grey-light;
      padding: 11px 0px;
      label.checkbox {
        letter-spacing: 0;
        color: $color-charcoal;
        font-size: 15px;
      }
      .checkbox .checkmark {
        top: -1px;
      }
    }
  }
  #filters {
    box-shadow: 0px 2px 10px #00000026;
    padding-bottom: 4px;
    margin-bottom: 17px;
    .topcontent {
      background: $color-primary-darker;
      padding: 18px 0px;
      padding-bottom: 23px;
      .select2 {
        color: $color-charcoal;
        font-size: 13px;
        .css-yk16xz-control {
          border-radius: 44px;
          padding-left: 17px;
          div {
            padding-left: 17px;
            border-radius: 0px;
            color: $color-charcoal;
            font-family: "Source Sans Pro", sans-serif;
          }
        }
        .icon {
          position: absolute;
          left: 29px;
          top: 42px;
          color: $color-primary-dark;
        }
      }
    }
    .filtersWrapper,
    .filtersWrapper2 {
      background: $color-grey-light;
      margin: 19px;
      padding: 15px;
      margin-bottom: 0;
      .saveFilterWrapper {
        text-align: right;
        margin-top: 36px;
        input#saveFilter {
          width: 300px;
          display: inline-block;
          height: 26px;
        }
      }
      label.label {
        letter-spacing: 0;
        color: $color-charcoal;
        margin-bottom: 3px;
        display: block;
      }
      input[type="text"],
      input[type="number"] {
        border: 1px solid $color-grey-mid;
        height: 38px;
        width: 100%;
        color: $color-charcoal;
        padding-left: 15px;
      }
      .select2 {
        input[type="text"] {
          height: unset;
          color: $color-charcoal;
        }
        .css-yk16xz-control {
          border-radius: 0px;
          padding-left: 15px;
        }
      }
    }
    .filtersWrapper2 {
      background: $color-background;
      border: 1px solid $color-grey-mid;
    }
  }
  .filterTabs {
    display: flex;
    background: $color-secondary;
    color: $color-text-light;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    padding-left: 7px;
    padding-top: gap-size(0.5);
    padding-right: 7px;

    .ActionButtonsLayout {
      flex-grow: 1;
      & button:last-child {
        margin-left: auto;
      }
    }
  }
}

//Info Input Pages
.infoInputPages {
  button.buttonWide {
    width: 100%;
    border: none;
  }
  label.label {
    letter-spacing: 0;
    color: $color-charcoal;
    font-family: "Source Sans Pro", sans-serif;
    margin-bottom: 2px;
    width: 100%;
  }
  input {
    background: $color-background 0% 0% no-repeat padding-box;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #00000026;
    letter-spacing: 0;
    color: $color-charcoal;
    padding-left: 10px;
    font-family: "Source Sans Pro", sans-serif;
    margin-bottom: 20px;
  }
  textarea#notes {
    width: 100%;
    border: 1px solid #00000026;
    padding-left: 10px;
    height: 178px;
    &:focus {
      outline: none;
    }
  }
}

// POS
#binderpos-pos {
  margin: 0;
  padding: 0;
  top: 0;
  width: 100%;
  position: fixed;
  z-index: 9999;
  height: 100vh;
  left: 0;
  background: $color-grey-light;
  .modal {
    .closeTitle {
      color: $color-secondary;
      font-size: 28px;
      font-family: "Source Sans Pro", sans-serif;
    }
    h5.title-lrg {
      letter-spacing: 0;
      color: $color-secondary;
      font-weight: 300;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 28px;
      margin-bottom: 41px;
    }
    span.openDate {
      color: $color-grey-inter;
      text-transform: uppercase;
      font-size: 15px;
      margin-bottom: 16px;
      display: block;
      font-family: "Source Sans Pro", sans-serif;
    }
    .wrapper.floatAdj {
      input.OpeningAmount {
        color: $color-charcoal;
      }
      .checkbox {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 15px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-family: "Source Sans Pro", sans-serif;
        color: $color-charcoal;
        .checkmark {
          top: -2px;
        }
      }
      input[type="textbox"] {
        display: block;
        width: 100%;
        padding-left: 15px;
        border: 1px solid $color-grey-mid;
        height: 36px;
        margin-bottom: 35px;
      }
      select.tillDropdown {
        background: $color-primary-darker 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 6px #0000000a;
        border-radius: 5px;
        border: 1px solid $color-primary-darker;
        color: $color-text-light;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 15px;
        text-align: center;
        text-align-last: center;
        width: 100%;
        height: 35px;
        display: block;
        margin: 0 auto;
        margin-bottom: 40px;
        cursor: pointer;
        background-position: right;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        padding: 4px 20px;

        &:hover {
          background-color: $color-primary-dark;
          border-color: $color-primary-dark;
        }
      }
    }
    table {
      box-shadow: none;
      thead {
        color: $color-text-light;
        font-size: 15px;
        font-family: "Source Sans Pro", sans-serif;
        height: 36px;
        th {
          text-align: center;
          &:first-child {
            padding-left: 10px;
            text-align: left;
          }
        }
      }
      tbody {
        tr {
          border-left: none;
          height: 45px;
          line-height: 45px;
          td {
            text-align: center;
            &:first-child {
              padding-left: 10px;
              font-weight: 600;
              color: $color-charcoal;
              text-align: left;
            }
            &.counted {
              width: 198px;
              input {
                height: 28px;
                padding-left: 15px;
                width: 150px;
                position: relative;
                border: 1px solid $color-grey-mid;
              }
            }
          }
        }
      }
    }
  }
  .app-content {
    height: 100vh;
    section.section {
      height: 100vh;
      .App {
        height: 100vh;
        .body {
          .loader {
            position: fixed;
          }
          .product-search {
            background: rgba(255, 255, 255, 0.93);
            box-shadow: 0px 3px 6px #00000029;
            width: calc(100vw - 425px);
            position: absolute;
            top: 110px;
            border-top: 8px solid $color-secondary;
            left: 20px;
            height: calc(100vh - 110px);
            padding-right: 99px;
            .results-grid {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: center;
              padding-bottom: 1rem;
              position: absolute;
              top: 138px;
              max-height: calc(100vh - 256px);
              width: calc(100vw - 523px);
              overflow: auto;
            }
          }
          .cart {
            position: fixed;
            right: 20px;
            background: $color-background 0% 0% no-repeat padding-box;
            box-shadow: 0px 2px 10px #00000052;
            height: calc(100vh - 414px);
            top: 162px;
            z-index: 99;
            width: 483px;

            .cart-items {
              overflow-y: auto;
              padding: 20px 22px;
              height: 100%;
            }

            &.disable {
              overflow-x: hidden;
              overflow-y: hidden;
            }
            .cartItem {
              position: relative;
              min-height: 80px;
              margin-bottom: 13px;
              border-bottom: 4px solid;
              padding-bottom: 12px;
              button.remove {
                border: 0;
                position: absolute;
                right: 0;
                margin-top: -13px;
                color: $color-secondary;
                font-size: 11px;
                cursor: pointer;
                background: $color-pink-light;
                padding: 0 5px;
                &:hover {
                  background: $color-red;
                  color: $color-text-light;
                  transition: all 0.3s;
                }
              }
              .kiosk-remove-wrapper {
                position: relative;
                width: 8rem;
                display: inline-block;
                top: 1rem;
              }
              .cartBadge {
                position: absolute;
                left: 36px;
                margin-top: -7px;
              }
              &.buy {
                border-color: $color-primary-dark;
              }
              &.sell {
                border-color: $color-orange;
              }
              &.disabled {
                .remove {
                  background: $color-grey;
                }
              }
              &.disabled * {
                cursor: wait !important;
                pointer-events: none;
              }
              img.img {
                max-width: 50px;
                max-height: 69px;
                display: inline-block;
                vertical-align: top;
              }
              .itemWrapper {
                display: inline-block;
                width: calc(100% - 50px);
                padding-left: 15px;
                letter-spacing: 0;
                color: $color-grey-dark;
                font-family: "Source Sans Pro", sans-serif;
                font-size: 15px;
                &--custom {
                  display: inline-flex;
                  .data {
                    flex-grow: 1;
                  }
                }
                .discounts {
                  position: relative;
                  top: 10px;
                  left: -65px;
                  .discountWrapper {
                    background: $color-primary-darker;
                    border: 2px solid $color-primary-darker;
                    border-radius: 14px;
                    height: 26px;
                    width: 108px;
                    display: inline-block;
                    vertical-align: top;
                    position: relative;
                    top: -3px;
                    left: 9px;
                    button {
                      background: $color-background;
                      height: 22px;
                      width: 22px;
                      border-radius: 19px;
                      display: inline-block;
                      color: $color-primary-darker;
                      line-height: 14px;
                      font-weight: 900;
                      vertical-align: top;
                      border: none;
                      padding: 0;
                      cursor: pointer;
                      &.notActive {
                        color: $color-grey-inter;
                      }
                    }
                    input {
                      display: inline-block;
                      font-family: "Source Sans Pro", sans-serif;
                      width: 60px;
                      background: none;
                      border: none;
                      color: $color-text-light;
                      text-align: center;
                    }
                  }
                }
                .data {
                  form {
                    margin-top: 8px;
                    input {
                      width: 286px;
                      padding-left: 15px;
                      border: none;
                      color: $color-charcoal;
                      &:focus {
                        font-family: "Source Sans Pro", sans-serif;
                      }
                      &::-webkit-input-placeholder {
                        color: $color-grey-dark;
                        font-family: "Source Sans Pro", sans-serif;
                      }
                      &:-ms-input-placeholder {
                        color: $color-grey-dark;
                        font-family: "Source Sans Pro", sans-serif;
                      }
                      &:placeholder {
                        color: $color-grey-dark;
                        font-family: "Source Sans Pro", sans-serif;
                      }
                    }
                  }
                }
                button.customAdd {
                  background: none;
                  border: none;
                  color: $color-primary-dark;
                  font-size: 32px;
                  float: right;
                  top: 14px;
                  &.active,
                  &:hover {
                    color: $color-secondary;
                    transition: all 1s;
                    text-decoration: none;
                  }
                }
                .actions {
                  display: flex;
                  button.lineDiscount {
                    border: 0;
                    position: absolute;
                    right: 129px;
                    width: calc(100% - 280px);
                    display: block;
                    color: $color-grey-dark;
                    cursor: pointer;
                    font-size: 12px;
                    margin-bottom: 0;
                    text-align: left;
                    &:hover {
                      color: $color-charcoal;
                    }
                  }
                  .greybox {
                    width: 124px;
                    background: $color-grey-light;
                    display: inline-block;
                    position: absolute;
                    right: 0px;
                    color: $color-charcoal;
                    font-size: 13px;
                    font-family: "Source Sans Pro", sans-serif;
                    height: 20px;
                    &:hover {
                      background: $color-grey-mid;
                    }
                    button.remove {
                      border: 0;
                      position: absolute;
                      right: 22px;
                      margin-top: -13px;
                      color: $color-secondary;
                      font-size: 11px;
                      cursor: pointer;
                      background: $color-pink-light;
                      padding: 0 5px;
                      &.active,
                      &:hover {
                        background: $color-red;
                        color: $color-text-light;
                        transition: all 1s;
                        text-decoration: none;
                      }
                    }
                    button.tax {
                      background: none;
                      border: 0;
                      padding: 0;
                      text-align: right;
                      width: 100%;
                      cursor: pointer;
                      &.active,
                      &:hover {
                        color: $color-secondary;
                        transition: all 1s;
                        text-decoration: none;
                      }
                    }
                  }
                }
                .data {
                  .titles {
                    display: inline-block;
                    width: 258px;
                    vertical-align: top;
                    min-height: 44px;
                    overflow: hidden;
                    position: relative;
                    top: -4px;
                  }
                  .inputs {
                    display: inline-block;
                    width: 111px;
                    font-size: 12px;
                    margin-top: 4px;
                    .kioskItemPrice,
                    input[type="number"] {
                      width: 100%;
                      text-align: right;
                      border: none;
                      color: $color-charcoal;
                      font-family: "Source Sans Pro", sans-serif;
                      &::-webkit-inner-spin-button,
                      &::-webkit-outer-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                      }
                      // &:focus {
                      //   outline: none;
                      // }
                    }
                  }
                }
                small {
                  position: relative;
                  top: -2px;
                }
              }
            }
            .cartTotals {
              background: $color-grey-light;
              bottom: 95px;
              position: fixed;
              height: 157px;
              width: 483px;
              box-shadow: 0px 5px 10px #00000066;
              padding: 19px;
              right: 20px;
              em {
                position: absolute;
                right: 20px;
                text-align: right;
                font-weight: 600;
                font-style: normal;
              }
              .discounts {
                letter-spacing: 0;
                color: $color-charcoal;
                text-transform: uppercase;
                font-family: "Source Sans Pro", sans-serif;
                font-size: 15px;
                .discountWrapper {
                  background: $color-primary-darker;
                  border: 2px solid $color-primary-darker;
                  border-radius: 14px;
                  height: 26px;
                  width: 108px;
                  display: inline-block;
                  vertical-align: top;
                  position: relative;
                  top: -3px;
                  left: 9px;
                  input {
                    display: inline-block;
                    font-family: "Source Sans Pro", sans-serif;
                    width: 60px;
                    background: none;
                    border: none;
                    color: $color-text-light;
                    text-align: center;
                    // &:focus {
                    //   outline: none;
                    // }
                  }
                  button {
                    background: $color-background;
                    height: 22px;
                    width: 22px;
                    border-radius: 19px;
                    display: inline-block;
                    color: $color-primary-darker;
                    line-height: 14px;
                    font-weight: 900;
                    vertical-align: top;
                    border: none;
                    padding: 0;
                    cursor: pointer;
                    &.notActive {
                      color: $color-grey-inter;
                    }
                  }
                }
              }
              .subTotal {
                display: block;
                letter-spacing: 0;
                color: $color-charcoal;
                text-transform: uppercase;
                font-family: "Source Sans Pro", sans-serif;
                font-size: 15px;
              }
              .tax {
                display: block;
                letter-spacing: 0;
                color: $color-charcoal;
                text-transform: uppercase;
                font-family: "Source Sans Pro", sans-serif;
                font-size: 15px;
                border-bottom: 1px solid $color-grey-inter;
                padding-bottom: 9px;
                margin-bottom: 9px;
                span.taxNegated {
                  text-transform: initial;
                  font-size: 12px;
                  position: relative;
                  left: 10px;
                  top: -1px;
                  color: $color-grey-inter;
                }
              }
              .total {
                display: block;
                font-size: 30px;
                font-family: "Source Sans Pro", sans-serif;
                color: $color-secondary;
              }
            }
          }
          .cartNumber {
            position: fixed;
            right: 20px;
            text-align: right;
            top: 132px;
            letter-spacing: 0;
            color: $color-charcoal;
            font-size: 13px;
            font-family: "Source Sans Pro", sans-serif;
            .fa-print {
              margin-right: 10px;
            }
            .IconButton {
              font-size: $font-size-small;
              padding: 0 0.25rem;
            }
          }
          .customItem {
            border: 0;
            background: none;
            position: fixed;
            right: 277px;
            text-align: left;
            top: 132px;
            color: $color-charcoal;
            font-size: 13px;
            font-family: "Source Sans Pro", sans-serif;
            cursor: pointer;
          }
          .cartNote {
            position: fixed;
            right: 171px;
            text-align: left;
            top: 132px;
            color: $color-charcoal;
            font-size: 13px;
            font-family: "Source Sans Pro", sans-serif;
            cursor: pointer;
            border: none;
            padding: 0;
            background: none;
          }
        }
      }
    }
  }
}

// POS Result Grid Items
.cardpos {
  width: 250px;
  max-height: 500px;
  background: $color-background 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 10px #00000026;
  margin: 15px 10px;
  &.stockLowStock {
    span.stock {
      background: $color-charcoal 0% 0% no-repeat padding-box;
    }
  }
  &.stockOutOfStock {
    span.stock {
      background: $color-red-light 0% 0% no-repeat padding-box;
    }
  }
  &.stockAvailable {
    span.stock {
      //background: #7aa761 0% 0% no-repeat padding-box;
      background: $color-charcoal 0% 0% no-repeat padding-box;
    }
  }
  .cartBadge {
    height: 32px;
    position: relative;
    margin-left: 230px;
    margin-top: -15px;
    margin-bottom: -16px;
  }
  span.stock {
    border-radius: 14px;
    height: 21px;
    color: $color-text-light;
    font-family: "Source Sans Pro", sans-serif;
    padding: 0 15px;
    margin: 4px auto;
    display: block;
    width: max-content;
    line-height: 21px;
  }
  .cardpos-container {
    width: 184px;
    height: 229px;
    margin: 0 auto;
    img {
      max-width: 184px;
      max-height: 229px;
      margin: 0 auto;
      display: block;
    }
  }
  .price {
    background: $color-secondary;
    width: 50%;
    display: inline-block;
    color: $color-text-light;
    font-family: "Source Sans Pro", sans-serif;
    line-height: 2;
    text-align: center;
    padding: gap-size(0.25);
  }
  .no-stock {
    background: $color-secondary;
    width: 50%;
    display: inline-block;
    color: $color-text-light;
    font-family: "Source Sans Pro", sans-serif;
    line-height: 2;
    text-align: center;
    padding: gap-size(0.25);
  }
  .productDetails {
    margin-bottom: gap-size(0.25);
    span.title {
      letter-spacing: 0;
      color: $color-grey-inter;
      font-size: 15px;
      text-align: center;
      height: 62px;
      display: block;
      margin: 7px 0;
      overflow: hidden;
      padding: 0 12px;
    }
    span.sellPrice {
      font-size: 0.8rem;
      text-align: center;
      margin: auto;
      display: block;
      margin-top: -0.4rem;
      color: #0a162e99;
      transition: ease-in-out height 0.5s;
      &--hidden {
        height: 0;
      }
      &--visible {
        height: 1.2rem;
      }
    }
    span.variant {
      width: calc(100% - 12px);
      display: block;
      margin: 0 auto;
      position: relative;
      select {
        background: $color-primary-darker 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 6px #0000000a;
        border-radius: 5px;
        border: 1px solid $color-primary-darker;
        color: $color-text-light;
        font-family: "Source Sans Pro", sans-serif;
        font-size: 15px;
        text-align: center;
        text-align-last: center;
        width: 100%;
        height: 26px;
        display: block;
        margin: 0 auto;
        cursor: pointer;
        background-position: right;
        padding: 2px 20px;
        option {
          text-align: center;
          text-align-last: center;
        }
        &.active,
        &:hover {
          background: $color-primary-dark;
          transition: all 1s;
          color: $color-text-light;
          text-decoration: none;
        }
      }
    }
  }
}

//Settings Pages
a.viewCart {
  color: $color-primary-darker;
  &:hover {
    color: $color-primary-dark;
    transition: all 1s;
    text-decoration: none;
  }
}

// Mobile POS
.App.mobile {
  display: none;
  .modal {
    top: -95px;
    height: calc(100% + 95px);
    .modal-card {
      width: 90vw;
    }
  }
  .react-swipeable-view-container {
    width: 100vw;
    position: fixed;
    // height: calc(100vh - 96px);
    height: calc(100% - 96px);
    .bodyPanel {
      width: 100vw;
      .wrapper {
        margin: 22px;
        background: rgba(255, 255, 255, 0.8);
        padding: 20px;
        border-top: 4px solid $color-secondary;
        position: relative;
        .results-grid {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: center;
          max-height: calc(100vh - 220px);
          overflow: auto;
          .cardpos {
            .productDetails {
              span.variant {
                select {
                  pointer-events: all;
                }
              }
            }
          }
        }
      }
    }
    .cartPanel {
      height: calc(100% - 45px);
      display: flex;
      flex-direction: column;

      // -
      margin: 22px;
      background: rgba(255, 255, 255, 0.8);
      padding: 20px;
      border-top: 8px solid $color-secondary;

      .modal.display-block {
        left: 100vw;
      }

      // --------

      .cartNote {
        border: none;
        background: none;
      }
      .cart {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
      }
      .cart-header {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        gap: gap-size(2.5);
        .cartNumber {
          flex-grow: 1;
          display: flex;
          justify-content: flex-end;
        }
        .customItem {
          border: 0;
          background: none;
        }
      }
      .cart-items {
        flex: 1 1 auto;
        overflow-y: auto;
        height: 0px;
        padding-top: 13px;
      }
      .cartTotals {
        width: 100%;
        padding-top: 5px;
        background: rgba(255, 255, 255, 0.8);
        margin: 0 auto;
        em {
          text-align: right;
          font-weight: 600;
          font-style: normal;
          float: right;
        }
        .discounts {
          letter-spacing: 0;
          color: $color-charcoal;
          text-transform: uppercase;
          font-family: "Source Sans Pro", sans-serif;
          font-size: 15px;
          .discountWrapper {
            background: $color-primary-darker;
            border: 2px solid $color-primary-darker;
            border-radius: 14px;
            height: 26px;
            width: 108px;
            display: inline-block;
            vertical-align: top;
            position: relative;
            top: -3px;
            left: 9px;
            input {
              display: inline-block;
              font-family: "Source Sans Pro", sans-serif;
              width: 60px;
              background: none;
              border: none;
              color: $color-text-light;
              text-align: center;
              &:focus {
                outline: none;
              }
            }
            button {
              background: $color-background;
              height: 22px;
              width: 22px;
              border-radius: 19px;
              display: inline-block;
              color: $color-primary-darker;
              line-height: 14px;
              font-weight: 900;
              vertical-align: top;
              border: none;
              padding: 0;
              cursor: pointer;
              &.notActive {
                color: $color-grey-inter;
              }
            }
          }
        }
        .subTotal {
          display: block;
          letter-spacing: 0;
          color: $color-charcoal;
          text-transform: uppercase;
          font-family: "Source Sans Pro", sans-serif;
          font-size: 15px;
        }
        .tax {
          display: block;
          letter-spacing: 0;
          color: $color-charcoal;
          text-transform: uppercase;
          font-family: "Source Sans Pro", sans-serif;
          font-size: 15px;
          border-bottom: 1px solid $color-grey-inter;
          padding-bottom: 9px;
          margin-bottom: 9px;
          span.taxNegated {
            text-transform: initial;
            font-size: 12px;
            position: relative;
            left: 10px;
            top: -1px;
            color: $color-grey-inter;
          }
        }
        .total {
          display: block;
          font-size: 30px;
          font-family: "Source Sans Pro", sans-serif;
          color: $color-secondary;
        }
      }

      .cart {
        overflow-y: hidden;
        // overflow: auto;
        height: calc(100vh - 383px);
        margin-top: 9px;
        padding-top: 14px;
        .cartItem {
          min-height: 80px;
          margin-bottom: 13px;
          border-bottom: 3px solid;
          padding-bottom: 12px;

          &:last-of-type {
            border-bottom: none;
            margin-bottom: 5px;
            padding-bottom: 0;
          }
          button.remove {
            border: 0;
            position: absolute;
            right: 0;
            margin-top: -15px;
            color: $color-secondary;
            font-size: 11px;
            cursor: pointer;
            background: $color-pink-light;
            padding: 0 5px;
            &:hover {
              background: $color-red;
              color: $color-text-light;
              transition: all 0.3s;
            }
          }
          .kiosk-remove-wrapper {
            position: relative;
            width: 8rem;
            display: inline-block;
            top: 1rem;
          }
          button.customAdd {
            top: 19px;
            padding-right: 17px;
          }
          .cartBadge {
            position: relative;
            margin-top: -7px;
            margin-left: -10px;
            vertical-align: top;
          }
          &.buy {
            border-color: $color-primary-dark;
          }
          &.sell {
            border-color: $color-orange;
          }
          img.img {
            max-width: 50px;
            max-height: 69px;
            display: inline-block;
            vertical-align: top;
          }
          .itemWrapper {
            display: inline-block;
            width: calc(100% - 64px);
            padding-left: 15px;
            letter-spacing: 0;
            color: $color-grey-dark;
            font-family: "Source Sans Pro", sans-serif;
            font-size: 15px;
            position: relative;
            top: 2px;
            &--custom {
              display: inline-flex;
              .data {
                flex-grow: 1;
              }
            }
            .discounts {
              position: relative;
              top: 10px;
              left: -65px;
              .discountWrapper {
                background: $color-primary-darker;
                border: 2px solid $color-primary-darker;
                border-radius: 14px;
                height: 26px;
                width: 108px;
                display: inline-block;
                vertical-align: top;
                position: relative;
                top: -3px;
                left: 9px;
                input {
                  display: inline-block;
                  font-family: "Source Sans Pro", sans-serif;
                  width: 60px;
                  background: none;
                  border: none;
                  color: $color-text-light;
                  text-align: center;
                  &:focus {
                    outline: none;
                  }
                }
                button {
                  background: $color-background;
                  height: 22px;
                  width: 22px;
                  border-radius: 19px;
                  display: inline-block;
                  color: $color-primary-darker;
                  line-height: 14px;
                  font-weight: 900;
                  vertical-align: top;
                  border: none;
                  padding: 0;
                  cursor: pointer;
                  &.notActive {
                    color: $color-grey-inter;
                  }
                }
              }
            }
            .data {
              form {
                margin-top: 8px;
                input {
                  width: 286px;
                  padding-left: 6px;
                  border: none;
                  outline: none;
                  color: $color-charcoal;
                  &:focus {
                    font-family: "Source Sans Pro", sans-serif;
                  }
                  &::-webkit-input-placeholder {
                    color: $color-grey-dark;
                    font-family: "Source Sans Pro", sans-serif;
                  }
                  &:-ms-input-placeholder {
                    color: $color-grey-dark;
                    font-family: "Source Sans Pro", sans-serif;
                  }
                  &:placeholder {
                    color: $color-grey-dark;
                    font-family: "Source Sans Pro", sans-serif;
                  }
                }
              }
            }
            button.customAdd {
              background: none;
              border: none;
              color: $color-primary;
              font-size: 32px;
              float: right;
              top: 14px;
              &.active,
              &:hover {
                color: $color-secondary;
                transition: all 1s;
                text-decoration: none;
              }
            }
            .actions {
              display: flex;
              position: relative;
              top: 5px;
              button.lineDiscount {
                border: 0;
                position: absolute;
                right: 149px;
                width: calc(100% - 252px);
                display: block;
                color: $color-grey-dark;
                cursor: pointer;
                font-size: 12px;
                margin-bottom: 0;
                text-align: left;
                &:hover {
                  color: $color-charcoal;
                }
              }
              .greybox {
                width: 124px;
                background: $color-grey-light;
                display: inline-block;
                position: absolute;
                right: -2px;
                color: $color-charcoal;
                font-size: 13px;
                font-family: "Source Sans Pro", sans-serif;
                height: 20px;
                &:hover {
                  background: $color-grey-mid;
                }
                button.tax {
                  background: none;
                  border: 0;
                  padding: 0;
                  text-align: right;
                  width: 100%;
                  cursor: pointer;
                  &.active,
                  &:hover {
                    color: $color-secondary;
                    transition: all 1s;
                    text-decoration: none;
                  }
                }
              }
            }
            .data {
              .titles {
                display: inline-block;
                width: calc(100% - 128px);
                vertical-align: top;
                min-height: 44px;
                overflow: hidden;
              }
              .inputs {
                display: inline-block;
                width: 111px;
                position: absolute;
                right: 0;
                font-size: 12px;
                margin-top: 6px;
                .kioskItemPrice,
                input[type="number"] {
                  width: 100%;
                  text-align: right;
                  border: none;
                  color: $color-charcoal;
                  font-family: "Source Sans Pro", sans-serif;
                  &::-webkit-inner-spin-button,
                  &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                  }
                  &:focus {
                    outline: none;
                  }
                }
              }
            }
            small {
              position: relative;
              top: -2px;
            }
          }
        }
      }

      // --------
      .wrapper {
        margin: 22px;
        background: rgba(255, 255, 255, 0.8);
        padding: 20px;
        border-top: 8px solid $color-secondary;
      }
    }
  }
}

@media screen and (max-width: 1366px) {
  table {
    thead tr {
      line-height: 22px;
      font-size: 15px;
      border: none;
    }
    td {
      line-height: 24px;
    }
  }
  #inventoryManagement {
    #filters {
      .filtersWrapper label.label,
      .filtersWrapper2 label.label {
        margin-bottom: 13px;
        height: 39px;
      }
    }
    .filtersWrapper3 label.label {
      line-height: 29px;
    }
  }
}
@media screen and (max-width: 1365px) {
  .app-sidebar {
    display: none;
  }
  .topCnrBg {
    height: 120px;
    z-index: 999999;
  }
  .brand {
    width: 119px !important;
    z-index: 999999;
  }
  .company {
    display: none;
  }
  main#binderpos-app {
    left: 0 !important;
    width: 100vw !important;
    &:before,
    &:after {
      display: none;
    }
    .app-content {
      margin-top: 20px;
    }
    .panelWrapper {
      max-width: 331px;
      .panel {
        p.types em {
          display: block;
          font-style: normal;
          text-decoration: unset;
          color: $color-grey-inter;
          font-size: 12px;
        }
        p.info {
          color: $color-primary;
          font-family: "Source Sans Pro", sans-serif;
          text-transform: uppercase;
          font-size: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  main#binderpos-app .app-content section.section {
    padding: 10px 0px;
    .topcontent {
      label {
        margin-top: 12px;
      }
      .select2 .icon {
        top: 55px !important;
      }
    }
    .filtersWrapper {
      label {
        margin-bottom: 0 !important;
      }
      input#barcode,
      input#title {
        margin-bottom: 21px;
      }
    }
    .filtersWrapper2 label {
      margin-bottom: 0 !important;
      height: 23px !important;
      margin-top: 14px;
    }
  }
  #inventoryManagement
    #filters
    .filtersWrapper
    .saveFilterWrapper
    input#saveFilter,
  #inventoryManagement
    #filters
    .filtersWrapper2
    .saveFilterWrapper
    input#saveFilter {
    width: calc(100% - 207px);
  }
  .filtersWrapper3 .select2 {
    margin-bottom: 16px;
  }
  .applyFilters button {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  h2 {
    padding-left: 20px;
    padding-right: 20px;
  }
  #inventoryManagement .resultsPanel .lineItem .variantsInfo {
    padding: 23px 5px;
  }
  .paging-nav-body {
    padding: 13px 0px;
  }

  .mb-3,
  .my-3 {
    margin-bottom: 0 !important;
  }
  p.hidden.buyLabel {
    display: block !important;
    margin: 0;
    text-align: center;
    font-size: 12px;
    margin-top: 7px;
  }
}

@media screen and (max-width: 767px) {
  .modal .modal-card {
    width: 90vw;
    padding: 16px;
    .modal-card-head button.delete {
      top: 28px;
      right: 18px;
    }
  }
}

.notActivePanel {
  pointer-events: none;
  label {
    color: $color-grey-inter !important;
  }
}

.eventInformation {
  .control {
    select {
      width: 100%;
    }
  }
}

//POS Media Queries
@media screen and (max-width: 1700px) {
  .app-sidebar .topCnrBgLight {
    top: -20px;
  }
  .topCnrBg {
    left: -119px;
    pointer-events: none;
    position: fixed;
  }
  .brand {
    padding-top: 10px;
    pointer-events: none;
    width: 163px;
  }
  .company {
    left: -13px;
    pointer-events: none;
  }
}
@media screen and (max-width: 1490px) {
  .app-sidebar .topCnrBgLight {
    top: -45px;
  }
  .topCnrBg {
    height: 125px;
    z-index: 10000;
  }
  .brand {
    width: 119px !important;
    z-index: 10000;
  }
  .company {
    left: -60px !important;
    top: 47px;
    font-size: 12px;
    z-index: 10000;
  }
}

@media screen and (max-width: 1365px) {
  .customer_list,
  .pendingBuylist,
  .completedBuylist,
  .approvedBuylist {
    width: 100%;
  }
  .App.mobile .react-swipeable-view-container .cartPanel .wrapper {
    .cart {
      height: calc(100vh - 431px);
    }
  }
  .modal {
    .modal-card {
      width: 97vw !important;
      padding: 10px !important;
      &:after {
        left: 163px;
      }
      .modal-card-head button.delete {
        top: 19px;
      }
      .modal-card-body {
        .refresh {
          top: 113px;
          right: 87px;
          height: 40px;
          line-height: 41px;
        }
      }
    }
  }
  .App {
    &.mobile {
      display: block !important;
    }
    &.desktop {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  table td {
    padding-left: 27px;
    padding-right: 28px;
  }

  main#binderpos-app {
    &:before,
    &:after {
      top: 7px;
    }
    .app-content {
      margin-top: 35px;
    }
  }

  .control.editor {
    height: 113px;
    margin-bottom: 96px;
  }

  .control {
    margin-bottom: 29px;
  }
}

.bulkupdate-input {
  padding-bottom: 20px;
}

@media screen and (max-width: 650px) {
  h2 {
    padding-left: 13px;
  }

  main#binderpos-app .app-content section.section .search {
    left: 0;
    margin-right: 10px;
    margin-left: 10px;
    input {
      border: 1px solid #cff3f2 !important;
      border-radius: 0px !important;
      width: 100%;
      height: 35px;
    }
  }

  main#binderpos-app .app-content {
    margin-right: 10px;
    margin-left: 10px;
    section.section {
      padding: 11px 10px;
    }
  }
  .outer-menu .menu > div > div {
    max-height: 110vh;
    ul {
      max-height: 103vh;
    }
  }
  nav#main-nav .pull-right {
    .avatar {
      display: none;
    }
    .user {
      right: 62px;
    }
  }
  /* Unused? */
  .modal .modal-card .modal-card-body .refresh {
    top: 111px;
    right: 30px;
    height: 32px;
    line-height: 32px;
  }
  .tenderWrapper {
    .tenderBtn {
      margin-right: 3px;
    }
    span.balance {
      font-size: 22px;
      em {
        right: 48px;
      }
    }
    span.tenderTitle {
      font-size: 22px;
      span.tender {
        width: 98px;
        font-size: 22px;
        right: 46px;
        input {
          width: 98px;
          font-size: 22px;
        }
      }
    }
  }

  .App.mobile .react-swipeable-view-container {
    .bodyPanel {
      margin-top: 56px;
    }
    .cartPanel {
      margin-top: 56px;
      height: calc(100% - 79px);
      .wrapper {
        .itemWrapper .data form input {
          width: calc(100% - 107px) !important;
          background: transparent;
        }
        .customItem {
          top: 83px;
        }
        .cartNote {
          top: 83px;
        }
      }
    }
  }
}

@media screen and (max-width: 530px) {
  .App.mobile .react-swipeable-view-container .cartPanel .wrapper {
    .cartNote {
      right: calc(-100vw + 215px);
    }
  }
}

@media screen and (max-width: 490px) {
  .App.mobile .react-swipeable-view-container .cartPanel .wrapper {
    .cartNote {
      display: none;
    }
  }
}

@media screen and (max-width: 415px) {
  .App.mobile .react-swipeable-view-container .cartPanel {
    margin-left: 1px;
    margin-right: 1px;
    padding: 5px;
    .cart {
      margin-bottom: 9px;
      height: calc(100vh - 400px);
    }
    .customItem {
      top: 66px;
      right: calc(-100vw + 18px);
    }
    .cartTotals {
      .tax {
        padding-bottom: 4px;
        margin-bottom: 3px;
      }
      .total {
        display: block;
        font-size: 30px;
        font-family: "Source Sans Pro", sans-serif;
        color: $color-secondary;
        line-height: 32px;
      }
    }
  }
}

@media screen and (max-width: 768px) and (max-height: 1024px) {
  main#binderpos-app .app-content section.section .search {
    position: absolute;
    top: 0px;
    right: 1px;
  }
}

//Custom Boostrap
@media (min-width: 1200px) {
  .col-xl-5col {
    -ms-flex: 0 0 20% !important;
    flex: 0 0 20% !important;
    max-width: 20% !important;
  }
}

// Printing
.buylistPrint {
  &__details {
    display: none;
  }
  &__cardImage {
    width: 40px;
    padding-left: 10px;

    @media print and (max-width: 8cm) {
      display: none;
    }
  }
  table {
    width: calc(100vw - 80px);
  }

  thead {
    background: $color-background;
    height: 58px;
    line-height: 58px;
  }
  tr {
    line-height: 15px;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 12px;
    font-weight: 600;
    height: 65px;
    color: $color-text-dark !important;
    border-left: none;
  }

  @media print and (max-width: 8cm) {
    &__details {
      display: block;
    }
    table {
      width: 140vw;
    }
    thead,
    tr {
      height: unset;
      font-size: 14px;
    }

    &__card {
      display: inline;
      font-weight: 600;
      &--secondary::before {
        content: " [";
      }
      &--secondary::after {
        content: "]";
      }
    }
    &__header {
      padding-right: 1em;
    }

    &__header--sell,
    &__header--secondary,
    &__sellPrice {
      display: none;
    }
  }
}

//Misc
.btn1 {
  width: max-content;
  padding: 0 22px;
  min-width: 160px;
  float: right;
  position: relative;
  top: 4px;
  margin-right: 4px;
  margin-left: 4px;
}
.rfip {
  margin: 0px;

  .rfipbtn--bluegrey {
    border: 1px solid $color-grey-mid;
  }
}
input.rfipsearch__input {
  border-radius: unset;
  width: 100%;
  border: 0 none;
  border-bottom: 1px solid #78909c;
  padding: unset;
  position: unset;
  right: unset;
}
.rfipbtn--bluegrey .rfipbtn__button {
  border: 0 none transparent;
  border-left: 1px solid $color-primary-darker;
  background-color: $color-primary-darker;
  color: $color-text-light;
}
.rfipdropdown--bluegrey {
  border: 1px solid $color-grey-mid;
}

@media print and (max-width: 1200px) {
  .modal .modal-card .modal-card-body .wrapper {
    overflow: unset;
    max-height: unset;
    padding: unset;
    overflow-x: unset;
  }
}

.modal-error {
  ul {
    list-style-type: none;
    padding-inline-start: 0;
  }
}

@-moz-document url-prefix() {
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.paging-wrapper {
  margin-top: -1rem;
  margin-bottom: 0;
}

// Fix SunEditor disabled button state being overwritten
.se-btn {
  &:disabled {
    background: inherit !important;
    filter: initial;
  }
}
.sun-editor .se-container {
  height: unset;
}

// Stop toasts getting hidden by stupid z-index modals
.Toastify__toast-container {
  z-index: 9999999 !important;
}

@import "../../node_modules/suneditor/dist/css/suneditor.min.css";
@import "./reports.scss";
