@import "../../../styles/global";

.CookieSettings {
  &__link {
    color: $color-text-link;

    &:hover {
      color: $color-text-link;
    }
  }

  &__table {
    margin: 0;
    box-shadow: none;

    display: block;
    thead,
    tbody,
    tr,
    td {
      display: block;
    }

    thead {
      background: unset;
    }

    th {
      display: none;
    }

    tr {
      display: flex;
      flex-direction: column;
      color: $color-text-dark !important;
      background-color: $color-background;
      border: none;
      border-bottom: $border-width-fine solid $color-primary-dark;
    }

    @include breakpoint-md-up {
      display: table;
      border: $border-width-fine solid $color-primary-dark;

      tr {
        line-height: 22px;
        border: $border-width-fine solid $color-primary-dark;
        display: table-row;
      }
      th,
      td,
      tbody,
      thead {
        border: $border-width-fine solid $color-primary-dark;
      }

      thead {
        display: table-header-group;
      }

      tbody {
        display: table-row-group;
      }

      th,
      td {
        padding: gap-size(0.5) gap-size(1) 0 gap-size(1);
        display: table-cell;
      }
    }
  }

  &__options {
    order: 2;
  }

  &__category {
    order: 0;
    font-weight: $font-weight-semi-bold;
  }

  &__label {
    display: flex;
    flex-basis: 1.5rem;
    @include breakpoint-md-up {
      justify-content: space-between;
    }
  }

  &__input {
    filter: grayscale(1);
    padding-right: gap-size(0.5) !important;
  }

  section {
    padding: gap-size() !important;
  }
}
