@import "../../../styles/global";
@import "../../../styles/form";

.PaymentTerminal {
  @include default-form;

  & > label {
    display: flex;
    column-gap: gap-size();
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: gap-size();

    & > select {
      @include select-styling-base;
      display: inline-block;
      margin-bottom: 0;
    }
  }

  &__settings {
    border-top: $border-width-fine solid $color-grey;
    padding-top: gap-size();
  }
}
