@import "../../../styles/global";

.PaymentGatewayDevice {
  padding: gap-size();
  position: relative;
  width: 12rem;
  min-height: 8rem;
  box-shadow: $shadow-standard;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background: url(../../../assets/img/payterminal.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: center;
  }

  &--selected {
    border: $border-width-fine solid $color-primary;
  }

  &__status {
    font-size: $font-size-small;
    text-align: center;
    z-index: 2;
  }

  &__selectedText {
    margin-bottom: 0;
    color: $color-primary;
    z-index: 2;
  }

  &__select {
    z-index: 2;
  }

  &__delete {
    font-size: $font-size-small;
    color: $color-red;
    background: none;
    border: none;
    position: absolute;
    top: 0.4rem;
    right: 0.2rem;

    &:hover {
      color: $color-red-dark;
    }
  }
}
