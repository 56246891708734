@import "../../../styles/global";

.ShopifyAuth {
  &__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: gap-size(2);
    background-color: $color-background;
    border-radius: $border-radius-small;
    box-shadow: $shadow-standard;
  }

  &__anchorBadge {
    color: $color-text-light !important;
    font-size: 12px;
    font-weight: $font-weight-bold;
    padding: gap-size(0.25) gap-size(0.5);
    border-radius: $border-radius-small;
    background-color: $color-blue-dark;
  }

  &__error {
    margin-bottom: gap-size();
    color: $color-error;
  }

  &__errorHeader {
    font-weight: $font-weight-bold;
  }

  &__linkButton {
    padding: gap-size(0.25) gap-size(0.5);
    font-size: $font-size-default;
    font-weight: $font-weight-normal;
  }
}
