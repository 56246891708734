@import "../../../styles/global";

.Users {
  // Horrible fudge to keep table aligned with border on avatar column
  tr:first-of-type {
    border-left: 3px solid $color-primary-darker !important;
  }

  &__row {
    &--enabled {
      & > td:first-of-type {
        border-left: 3px solid $color-purple;
      }
    }
    &--disabled {
      & > td:first-of-type {
        border-left: 3px solid $color-pink-light;
      }
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }
}
