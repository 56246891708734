// Spacing
// -------------------------------------
@function gap-size($multiplier: 1) {
  // use a multiple of the default gap size for consistancy.
  @return ($gap-size * $multiplier);
}

// Pseudo class group mixins
// -------------------------------------

// Shortcut for hover, focus and active states
@mixin hover-focus-active {
  &:hover:not(:disabled),
  &:focus:not(:disabled),
  &:active:not(:disabled) {
    @content;
  }
}

// Shortcut for focus and active states
@mixin focus-active {
  &:focus,
  &:active {
    @content;
  }
}

// Responsiveness
// -------------------------------------

// Media Queries
@mixin breakpoint-custom-up($min) {
  @media all and (min-width: $min) {
    @content;
  }
}

@mixin breakpoint-xs-only {
  @media all and (max-width: $screen-xs-min) {
    @content;
  }
}

@mixin breakpoint-xs-up {
  @include breakpoint-custom-up($screen-xs-min) {
    @content;
  }
}

@mixin breakpoint-sm-up {
  @include breakpoint-custom-up($screen-sm-min) {
    @content;
  }
}

@mixin breakpoint-md-up {
  @include breakpoint-custom-up($screen-md-min) {
    @content;
  }
}

@mixin breakpoint-ml-up {
  @include breakpoint-custom-up($screen-ml-min) {
    @content;
  }
}

@mixin breakpoint-lg-up {
  @include breakpoint-custom-up($screen-lg-min) {
    @content;
  }
}

@mixin breakpoint-xl-up {
  @include breakpoint-custom-up($screen-xl-min) {
    @content;
  }
}
