@import "../../../styles/global";
@import "../../../styles/table";
@import "../../../styles/button";

.CustomerList {
  @include table;

  &__search {
    border: $border-width-fine solid $color-primary-dark;
    border-radius: $border-radius-large;
    margin-bottom: 1rem;
    padding: 5px 0px 5px 36px;
    font-size: 13px;
    color: $color-text-dark;
    font-family: $font-family-secondary;
    width: 268px;
    &:focus {
      outline: none;
    }
  }

  &__searchIcon {
    color: $color-primary-dark;
    position: relative;
    left: 28px;
    top: 2px;
  }

  &__row {
    &--cash {
      & > td:first-of-type {
        border-left: 3px solid $color-pink-light;
      }
    }

    &--credit {
      & > td:first-of-type {
        border-left: 3px solid $color-purple;
      }
    }
  }

  &__editDetails,
  &__creditHistory {
    @include breakpoint-ml-up {
      text-align: center !important;
    }
  }
}
