@import "../../../styles/global";

.StoreReceiptsSettingsContainer {
  margin-top: gap-size(1);

  &__section {
    border: $border-width-fine solid $color-text-dark;
    border-radius: $border-radius-small;
    margin-bottom: gap-size(1.25);
    position: relative;
    padding: gap-size(1) gap-size(0.5) gap-size(0.5);

    h3 {
      display: inline-block;
      background: $color-white;
      font-size: $font-size-mid !important; // override unwise use of important on an unscoped element selector
      color: $color-text-dark;
      position: absolute;
      left: gap-size(0.5);
      padding: $border-width-fine $border-width-thick;
      top: -0.7rem;
      height: 1.45rem;
      line-height: 1 !important;
    }
  }

  &__sub-section {
    display: grid;
    gap: gap-size(0.5) gap-size(1);
    margin-bottom: gap-size(0.5);
    border-bottom: $border-width-fine solid $color-grey;
    @include breakpoint-sm-up() {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__blurb {
    font-size: $font-size-standard;
    margin-bottom: gap-size(0.5);
  }

  &__action {
    display: flex;
    margin: gap-size(0.75) 0 gap-size(0.5) auto;
  }

  &__form-group {
    display: grid;
    gap: 0 gap-size(1);
    @include breakpoint-sm-up() {
      grid-template-columns: 1fr 1fr;
    }
    @include breakpoint-md-up() {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  &__input {
    &--large {
      @include breakpoint-sm-up() {
        grid-column: span 2;
      }
    }
  }
}
