@import "../styles/global";

.integrations-list {
  $text-color: $color-secondary;

  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .card {
    width: 30em;
    margin-right: 1em;
    margin-bottom: 1em;
    color: $text-color;
    transition: box-shadow 0.5s;

    &:hover {
      box-shadow: 2px 2px $color-grey;
      text-decoration: none;
    }

    .card-img-top {
      height: 12em;
      padding: 2em;
    }

    img {
      width: unset;
      margin: auto;
    }

    .card-body {
      min-height: 5em;
      background-color: $color-white;
    }
  }
}

.integration-settings-list {
  .no-settings {
    padding-left: 1em;
  }

  .userActions {
    color: $color-primary-darker !important;
    font-size: 18px;
    margin: 0 9px;
    cursor: pointer;
    &.active,
    &:hover {
      color: $color-primary-dark !important;
      transition: all 1s;
    }
  }
  .checkbox {
    margin-top: -16px;
    margin-left: 14px;
    padding-left: 19px;
  }
  .multipleSelect {
    cursor: pointer;
    .checkbox {
      margin-top: -12px;
    }
    &.active {
      color: $color-secondary;
      transition: all 1s;
      text-decoration: none;
    }
    span.text {
      position: relative;
      left: 61px;
      .icon-button {
        color: $color-grey-light;
        padding-left: 5px;

        &:hover {
          color: $color-secondary;
          transition: all 1s;
        }
      }
    }
  }

  .top-pagination {
    top: unset;
    right: 1em;

    > span {
      margin-right: 1em;
    }
  }

  @media only screen and (max-width: 1366px) {
    table thead tr,
    table td {
      line-height: unset;
    }
  }
}

.integration-tab-navigation {
  display: flex;
  list-style: none;
  margin: 0;
  margin-top: 0.5rem;
  padding: 0;

  @media only screen and (max-width: 1000px) {
    flex-wrap: wrap;
  }

  li {
    height: 46px;
    white-space: nowrap;
    a {
      span {
        font-size: 0.9em;
        background-color: $color-secondary;
        color: $color-grey-light;
        padding: 15px;
        display: inline-block;
        height: 46px;
        font-weight: 600;
        position: relative;

        @media only screen and (min-width: 1000px) {
          top: -15px;
        }
      }
      @media only screen and (min-width: 1000px) {
        &:after {
          content: " ";
          display: inline-block;
          border-top: 46px solid transparent;
          border-bottom: 0px solid transparent;
          border-left: 69px solid $color-secondary;
          height: 46px;
        }
      }
      &.selected {
        span {
          background-color: $color-primary-darker;
        }
        &:after {
          border-left: 69px solid $color-primary-darker;
        }
      }
    }
  }
}

.integration-settings {
  margin-bottom: 3em;

  label.checkbox {
    margin-top: 2.1em;

    .checkmark {
      margin-top: -0.4em;
    }
  }

  .inactive {
    pointer-events: none;
    opacity: 0.4;
  }

  .fieldBlock {
    margin-top: 1em;
    justify-content: end;

    .block {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      height: 100%;
      min-height: 6em;

      p.title,
      p.help {
        letter-spacing: 0;
        color: $color-grey-dark;
        font-family: "Source Sans Pro", sans-serif;
        margin-bottom: 0;
        padding-left: 3px;
        letter-spacing: 0;
        font-family: "Source Sans Pro", sans-serif;
        margin-bottom: 0;
        padding-left: 3px;
      }
      p.title {
        font-size: 16px;
        display: flex;
        align-items: flex-end;
      }

      p.help {
        font-size: 12px;
      }

      .input.with-symbol {
        width: calc(100% - 1.5em);
        display: inline-block;
      }
    }

    @media only screen and (max-width: 992px) {
      margin-top: 1.5em;

      .block {
        min-height: 3em;
      }
    }
  }

  .duplicate-error {
    color: $color-error;
  }

  .update-warning {
    font-size: 0.9em;
    color: $color-primary-darker;
    height: 1em;
    margin-bottom: -1em;
    padding-left: 2px;
  }
}

.view-integration-settings {
  display: grid;
  grid-row-gap: 1em;
  max-width: 50em;
  margin: auto;

  .label {
    font-weight: bold;
    grid-column: 1;
  }

  .value {
    grid-column: 2;
  }
}

.integration-advanced-settings {
  .select2 {
    i.far {
      color: $color-primary-darker;
      padding-right: 1em;
    }
  }

  // Remove padding left
  &#inventoryManagement #filters .topcontent .select2 .css-yk16xz-control div {
    padding-left: 0 !important;
  }

  .align-col-bottom {
    margin-bottom: 0.5em;
  }
  .filtersWrapper2 label {
    font-size: 0.9em;
  }
}

.integration-advanced-results-panel,
.integration-review-variants {
  .col-xl-1,
  .col-xl-2 {
    padding-right: 3px;
    padding-left: 3px;
    max-height: 58px;
  }
  input {
    padding: 0;
    text-align: center;
    margin: 10px 0px;
    color: $color-grey-dark;
  }
  .line-item {
    box-shadow: 0px 2px 10px #00000026;
    width: 100%;
    margin-bottom: 22px;
    padding-left: 0;
    padding-right: 0;
    .btn1 {
      margin: 15px 0px;
      min-width: 100%;
      top: 1px;
    }
    .product-header {
      background: $color-grey-light;
      padding: 15px 0;
      .imgWrapper {
        width: 50px;
        height: 69px;
        display: inline-block;
        img {
          max-width: 50px;
          max-height: 69px;
          margin: 0 auto;
          position: relative;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .productTitle {
        display: inline-block;
        width: calc(100% - 164px);
        vertical-align: top;
        padding-left: 10px;
        color: $color-grey-dark;
        span {
          display: block;
        }
      }
      .productData {
        text-align: right;
        color: $color-secondary;
        font-size: 28px;
      }
    }
  }
}

.integration-variants-info {
  padding: 23px;
  margin-bottom: gap-size(1);
  .variant-line {
    // height: 58px;
    border-bottom: 1px solid $color-grey-light;
    .center-input {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 10px 0;
      .checkmark {
        margin-left: 5px;
      }
    }
    .price {
      line-height: 58px;
      color: $color-grey-dark;
    }
    .variantTitle {
      height: max-content;
      position: relative;
      // top: 50%;
      // transform: translateY(-50%);
      margin-top: 1em;
      color: $color-grey-dark;
      font-size: 15px;
      font-family: "Source Sans Pro", sans-serif;
    }
    .inventory {
      color: $color-grey-dark;
      font-size: 13px;
      font-family: "Source Sans Pro", sans-serif;
    }
    .text-small {
      input {
        width: calc(50% - 14px);
        display: inline-block;
      }
      span {
        display: inline-block;
        margin: 0 6px;
        color: $color-grey-dark;
      }
    }
    .form-control {
      display: inline-block;
      width: 80%;

      &.currency {
        margin-left: 0.5em;
        width: calc(80% - 1.5em);
      }
      &.percentage {
        margin-right: 0.5em;
        margin-left: 0.7em;
        width: calc(80% - 2.3em);
      }
    }
    .btn1 {
      display: inline-block;
      float: none;
      min-width: unset;
      width: calc(37% - 1em);
      margin-left: 1%;
      padding: 0;
    }
    .btn2 {
      top: 1em;
      display: inline-block;
      float: none;
      min-width: unset;
      width: 100%;
      margin-left: 1%;
      margin-right: 1rem;
    }
    .btn--info {
      align-self: center;
      color: $color-text-light;
      min-width: unset;
      margin-left: 0.5rem;
      background: $color-charcoal;
      border-radius: 4rem;
      height: 1.8rem;

      &:hover {
        background: $color-grey-dark;
      }
    }
  }

  /* Removes odd 15px borders on left and right */
  & .line-item {
    .container-fluid {
      padding-left: 0;
      padding-right: 0;
    }
    .row {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .md-only {
    display: none;
  }

  @media only screen and (max-width: 992px) {
    .variant-select-titles {
      display: none;
    }

    .md-only {
      display: inline-block;
      width: 65%;
    }

    .variant-line {
      height: unset;
      .variantTitle {
        font-size: 1.2em;
        font-weight: bold;
        color: $color-primary-darker;
        padding-top: 0.5em;
      }
      .inventory {
        font-size: 15px;
        position: relative;
        top: -1.3rem;
        left: 70%;
      }
      .row {
        height: unset;
      }
      .center-input {
        align-items: unset;
        .checkmark {
          margin-left: 1.2em;
          top: -5px;
        }
      }
      .checkbox {
        display: inline-block;
      }
      .form-control {
        width: calc(25% - 2.7em);
        margin-left: 1.7em;
        margin-right: 1.7em;
        &.percentage {
          margin-left: 2.1em;
          margin-right: 1em;
          width: calc(25% - 3.4em);
        }
        &.currency {
          margin-left: 1em;
          width: calc(25% - 2.7em);
        }
      }
    }
    .centered {
      text-align: left;
    }
  }

  .variant-select-titles {
    background: $color-primary-darker;
    color: $color-text-light;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 13px;
    text-align: center;
    line-height: 30px;
  }
}

.integration-review-variants {
  .integration-variants-info {
    border: 3px solid $color-primary-darker;
    border-top: 1em solid $color-primary-darker;
  }
  .variant-select-titles {
    margin-bottom: 0.5em;
  }
}

.integration-game-error {
  display: inline-block;
  margin-bottom: -1.5rem;
  color: $color-text-light;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 13px;
}

.integration-game-select-error > div {
  border: 1px solid $color-red;
}
