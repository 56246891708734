@import "../../../styles/global";

.CustomerAvatar {
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  text-align: center;
  border-radius: 50%;
  color: $color-text-light;
  font-family: $font-family-secondary;
  font-size: $font-size-mid;
  display: inline-block;
  font-weight: $font-weight-light;
  text-transform: uppercase;
  background: $color-primary-darker;
  &.red {
    background: $color-pink-light;
  }
  &.blue {
    background: $color-purple;
  }
}
