@import "../../../styles/global";

.Overlay {
  position: relative;
  transition: all $transition-speed-fast $transition-easing-default;
  z-index: 10000;

  &--entering,
  &--exiting {
    opacity: 0;
  }
  &--entered {
    opacity: 1;
  }

  &__background {
    background-color: rgba($color: $overlay-color, $alpha: $overlay-opacity);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
