@import "../../../styles/global";

.variant-settings-form {
  &__reminder {
    background-color: $color-grey-mid;
    margin-right: gap-size(2);
    padding: gap-size(1);
  }

  &__actions {
    gap: gap-size(0.5);
    margin-bottom: gap-size(0.5);
  }
}
