@import "../../../styles/global";
@import "../../../styles/form";

.UploadCsvHeadersModal {
  margin-top: gap-size(-1);

  h3.UploadCsvHeadersModal__heading {
    font-weight: $font-weight-bold;
    font-size: $font-size-large !important;
    margin-bottom: gap-size();
  }

  &__select {
    @include select-styling-base;
    display: inline-block;
    margin-left: gap-size();
  }

  &__headers {
    margin-top: gap-size(2);
    margin-left: gap-size(2);
    display: grid;
    grid-template-columns: 15rem 15rem 15rem;

    &--title {
      font-weight: $font-weight-bold;
      text-decoration: underline;
    }

    &--invalid {
      color: $color-error !important; // stupid default rules
    }

    &--informational {
      font-weight: $font-weight-light;
      font-style: italic;
    }

    &--required,
    &--success {
      color: $color-primary-dark !important;
    }
  }
}
