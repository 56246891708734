.buylist-item-input {
  color: #666;
  font-size: 0.9em;
  input {
    border: 1px solid #ddd;
    color: #666;
  }
  &.quantity-label {
    padding-right: 0.25em;
  }
  .quantity {
    width: 3em;
  }
  .buy-price {
    width: 4em;
  }
}
button.printBuylist,
button.buylistView {
  background: none;
  border: none;
  color: $color-primary-darker;
  margin-right: 20px;
  cursor: pointer;
  &:hover {
    color: $color-primary-dark;
    transition: all 1s;
  }
}
.buylistList {
  margin-bottom: 18px;
  button.listAddCard {
    background: none;
    border: 0;
    cursor: pointer;
    &:hover {
      color: grey;
      transition: all 1s;
    }
    i {
      padding-right: 5px;
    }
  }
  img {
    width: 50px;
    padding-left: 10px;
  }
  i {
    padding-left: 5px;
    padding-right: 5px;
    cursor: pointer;
    &:hover {
      color: grey;
      transition: all 1s;
    }
  }
  .error {
    .buylist-item-input {
      &.quantity-label {
        color: #e00;
      }
      .quantity {
        background-color: rgba(255, 0, 0, 0.2);
        border-color: #e00;
        color: $color-text-dark;
      }
    }
  }
}
.buylist-controls {
  display: flex;
  flex-wrap: wrap-reverse;
}
.sort {
  color: $color-grey-dark;
  white-space: nowrap;
  select {
    color: $color-grey-dark;
    border-color: $color-primary-dark;
    border-radius: 3px;
  }
}
.sort-direction {
  background: unset;
  border: unset;
  color: $color-primary-dark;
  &:hover {
    color: $color-grey-dark;
  }
}
.viewChange {
  display: flex;
  @media only screen and (min-width: 767px) {
    justify-content: flex-end;
  }
  @media only screen and (max-width: 450px) {
    flex-wrap: wrap;
  }
  .buylistView,
  button {
    white-space: nowrap;
  }

  .buylistView {
    color: $color-primary-darker;
    cursor: pointer;
    i {
      padding-right: 5px;
    }
    &:hover {
      color: $color-primary-dark;
      transition: all 1s;
    }
    &.active {
      color: $color-grey-dark;
    }
    i.far.fa-trash-alt {
      color: #bd6a6a;
      &:hover {
        color: #ab4747;
        transition: all 1s;
      }
    }
  }
}
.actions.buylistReview {
  display: flex;
  justify-content: flex-end;
  @media only screen and (max-width: 1199px) {
    padding-right: 20px;
  }
  .buttons {
    display: flex;
    flex-wrap: wrap;
  }
  .btn1 {
    position: unset;
    width: max-content;
    min-width: 120px;
    padding: 0 15px;
    display: inline-block;
    margin-left: unset;
    margin-right: unset;
    margin-bottom: 0.3em;
  }
  .button.buttonWide {
    margin: 35px 0px;
  }
  + .modal .warning {
    padding: 4px 8px;
    margin: 8px;
    border: 1px solid brown;
    border-radius: 4px;
    background-color: lightgoldenrodyellow;
    .fas {
      float: left;
      font-size: 2em;
      padding-top: 0.25em;
      padding-right: 0.25em;
    }
  }
}
.buylistList {
  .vertialVariantPricing {
    display: grid;
    grid-template-columns: repeat(5, auto);
    align-items: center;
    margin-top: 0.2em;
    margin-bottom: 0.2em;
  }
}
.buylist-item-input {
  color: $color-grey-dark;
  input {
    color: $color-grey-dark;
  }
}

.buylist-metadata {
  box-shadow: 0px 2px 6px #00000059;
  .card-header {
    background-color: $color-primary-darker;
    display: flex;
    column-gap: 1rem;
  }

  .customer-name,
  .customer-email {
    color: $color-text-light;
    display: block;
  }
  .customer-name {
    font-weight: bold;
    font-size: 0.9em;
  }
  .customer-email {
    font-size: 0.8em;
  }
  .card-body {
    display: grid;
    grid-template-columns: max-content max-content;
    grid-column-gap: 1em;
    grid-row-gap: 0.25em;
  }
  .label,
  .value {
    font-size: 0.9em;
    color: $color-grey-dark;
  }
}

@media only screen and (max-width: 1199px) {
  .viewChange {
    padding-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .buylistList {
    table td {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}
