@import "../../../styles/global";

.link-component {
  background: none;
  border: 0;
  color: $color-text-dark;
  font-family: $font-family-secondary;
  font-size: $font-size-standard;
  padding: 0;
  text-decoration: underline;
}
