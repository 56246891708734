@import "../../../styles/global";

.Buylists {
  &__table-controls {
    display: flex;
    row-gap: gap-size();
    column-gap: gap-size(2);
    flex-direction: column;
    margin-bottom: gap-size();

    @include breakpoint-xs-only() {
      width: 10rem;
    }

    .Paging {
      justify-content: flex-start;
    }

    @include breakpoint-sm-up() {
      flex-direction: row;
      align-items: end;

      .Paging {
        justify-content: flex-end;
      }
    }
  }

  &__filters-wrapper {
    display: flex;
    flex-wrap: wrap;
    column-gap: gap-size(1.5);
    row-gap: gap-size();
    flex-grow: 1;
  }
}
